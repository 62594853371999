import {View, Text, Box as NativeBox, Flex, Center, Button, useTheme, Image, ScrollView, HStack} from 'native-base';
import {DefaultTFuncReturn} from 'i18next';
import React, {useState} from 'react';
import {useDrag, useDrop, DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {TouchBackend} from 'react-dnd-touch-backend';
import {TouchTransition, MouseTransition, MultiBackend} from 'react-dnd-multi-backend';
import {useTranslation} from 'react-i18next';
import {WINDOW_WIDTH} from '../../utils/mixins';
import {Images} from '../../utils/imagesUtils';
import {NavigationInfo} from '../../utils/types';

export type Word = {
  id: number;
  text: string;
  box: string;
};

type DropResult = {
  box: string;
};

type DragAndDropProps = {
  translatedChallenge?: DefaultTFuncReturn;
  translatedQuestion?: DefaultTFuncReturn;
  translatedOptions?: Word[];
  translatedButtonText?: DefaultTFuncReturn;
  buttonAction?: (solution: NavigationInfo) => void;
  isButtonLoading?: boolean;
  buttonSubmitAnswer: (answ: {solution: string | string[]; type: string}) => void;
  colors: string[];
  rightAnswer?: string;
  buttonReadyNavigation: boolean;
  navigationInfo: NavigationInfo;
};

const HTML5toTouch = {
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      id: 'touch',
      backend: TouchBackend,
      options: {enableMouseEvents: true},
      preview: true,
      transition: TouchTransition,
    },
  ],
};

const WordComponent: React.FC<{word: Word; color: string; moveWord: (id: number, box: string) => void}> = ({word, moveWord, color}) => {
  const [{isDragging}, drag] = useDrag({
    type: 'WORD',
    item: {id: word.id, text: word.text, type: 'WORD'},
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult() as DropResult;
      if (item && dropResult) {
        moveWord(item.id, dropResult.box);
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;

  return (
    <Flex ref={drag} direction="row" alignItems="center" style={{opacity}}>
      <NativeBox bg={color} shadow={3} p={2} borderRadius={8} margin={2} flex={1}>
        <Text textAlign="center">{word.text}</Text>
      </NativeBox>
    </Flex>
  );
};

const Box: React.FC<{id: string; words: Word[]; colors: string[]; moveWord: (id: number, box: string) => void}> = ({id, words, moveWord, colors}) => {
  const [, drop] = useDrop({
    accept: 'WORD',
    drop: () => ({box: id}),
    collect: monitor => ({}),
  });

  let backgroundImage;
  switch (id) {
    case 'left':
      backgroundImage = Images.cuboGris;
      break;
    case 'center':
      backgroundImage = Images.cuboAmarillo;
      break;
    case 'right':
      backgroundImage = Images.cuboMarron;
      break;
    default:
      backgroundImage = '';
      break;
  }
  return (
    <View ref={drop} flex={1} w={WINDOW_WIDTH / 3} minH={150} style={{/* width: 110, height: 150, */ position: 'relative'}}>
      <View rounded="2xl" style={{borderWidth: 2, borderColor: '#CCC'}}>
        <Image source={backgroundImage} height={150} alt="Background Image" resizeMode="contain" />
      </View>
      <View justifyContent="center" alignItems="center">
        {words.map(word => (
          <WordComponent key={word.id} word={word} moveWord={moveWord} color={colors[word.id]} />
        ))}
      </View>
    </View>
  );
};

const DragAndDrop: React.FC<DragAndDropProps> = ({
  translatedChallenge,
  translatedQuestion,
  translatedOptions = [],
  translatedButtonText,
  buttonAction,
  isButtonLoading,
  buttonReadyNavigation,
  buttonSubmitAnswer,
  colors,
  navigationInfo,
  rightAnswer,
}) => {
  const [words, setWords] = useState<Word[]>(translatedOptions.map((option, index) => ({id: index, text: option, box: ''})) as any);

  const theme = useTheme();
  const {t} = useTranslation();

  const moveWord = (id: number, box: string) => {
    setWords(prevWords => {
      const newWords = prevWords.map(word => {
        if (word.id === id) {
          return {...word, box};
        }
        return word;
      });
      return newWords;
    });
  };

  /* const generatePreview = ({item, style}: any) => {    
    const id = item?.id;

    if (!id) return null;

    const word = words.filter(word => word.id === id)[0];

    if (!word) return null;

    return (<div style={{ ...style, zIndex: 1000 }}>{word.text}</div>);
  }; */

  const leftBoxWords = words.filter(word => word.box === 'left');
  const rightBoxWords = words.filter(word => word.box === 'right');
  const centerBoxWords = words.filter(word => word.box === 'center');
  const remainingWords = words.filter(word => !word.box); // Filter out words already assigned to a box

  return (
    <>
      <View />
      <ScrollView showsHorizontalScrollIndicator={false}>
        <View>
          <DndProvider backend={MultiBackend} options={HTML5toTouch}>
            {/* <Preview generator={generatePreview as any} /> */}

            <div style={{marginBottom: 20}}>
              <View paddingBottom={5}>
                <Image source={Images.aenor} alt="badge" style={{width: 150, height: 100}} />
              </View>
              <View>
                <Text color={theme.colors.lightText[700]} fontSize="2xl">
                  {translatedChallenge}
                </Text>
              </View>

              <View marginBottom={5}>
                <Text color={theme.colors.lightText[500]} fontSize="md">
                  {translatedQuestion}
                </Text>
              </View>

              <Flex direction="row" flexWrap="wrap">
                {remainingWords.map(word => (
                  <WordComponent key={word.id} word={word} moveWord={moveWord} color="" />
                ))}
              </Flex>
            </div>

            <HStack justifyContent="space-around" flex={1} space={1}>
              <Box id="left" words={leftBoxWords} moveWord={moveWord} colors={colors} />
              <Box id="center" words={centerBoxWords} moveWord={moveWord} colors={colors} />
              <Box id="right" words={rightBoxWords} moveWord={moveWord} colors={colors} />
            </HStack>

            {buttonAction && !buttonReadyNavigation && (
              <Center>
                <View paddingTop={12} />
                <Button
                  isLoading={isButtonLoading}
                  onPress={() => {
                    const leftBoxWordsIndex = leftBoxWords.map(word => word.id.toString()).join(',');
                    const centerBoxWordsIndex = centerBoxWords.map(word => word.id.toString()).join(',');
                    const rightBoxWordsIndex = rightBoxWords.map(word => word.id.toString()).join(',');
                    buttonSubmitAnswer({solution: [leftBoxWordsIndex, centerBoxWordsIndex, rightBoxWordsIndex], type: 'dragAndDrop'});
                  }}
                  bgColor="primary.500"
                  mt={4}
                  w={WINDOW_WIDTH * 0.8}>
                  {translatedButtonText}
                </Button>
                <View marginBottom={12} />
              </Center>
            )}

            {buttonAction && buttonReadyNavigation && (
              <Center>
                <Button
                  isLoading={isButtonLoading}
                  onPress={() => {
                    buttonAction(navigationInfo);
                  }}
                  bgColor="primary.500"
                  mt={4}
                  w={WINDOW_WIDTH * 0.8}>
                  {t('Continuar')}
                </Button>
              </Center>
            )}
          </DndProvider>
        </View>
      </ScrollView>
    </>
  );
};

export default DragAndDrop;
