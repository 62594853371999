import {View, Text, useTheme, Button, Image, Box, Stack} from 'native-base';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigation} from '@react-navigation/native';
import {Images, getResource} from '../utils/imagesUtils';

type Props = {};

const ErrorLoginScreen = (props: Props) => {
  const {t} = useTranslation();
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<any>();

  return (
    <View flex={1}>
      <Box flex={1} mb={-6}>
        <Image source={getResource(Images.peopleWalkingSesame2x)} resizeMode="cover" h="100%" w="100%" />
      </Box>

      <Stack direction="column" backgroundColor="white" borderTopRadius="2xl" overflow="hidden" bottom={0} p={6} space={6}>
        <View>
          <Text fontWeight={700} fontSize="xl" color={theme.colors.darkText[500]}>
            {`🔴 ${t('Error al validar vuestro código')}`}
          </Text>
        </View>

        <View>
          <Text fontWeight={300} fontSize="16" color={theme.colors.lightText[500]}>
            {t('Parece ser que este código no es correcto, comprueba que coincide con el que te ha facilitado tu responsable.')}
          </Text>
        </View>

        <View pt={6}>
          <Button colorScheme="primary" onPress={() => navigation.goBack()}>
            {t('Volver a escribir código')}
          </Button>
        </View>
      </Stack>
    </View>
  );
};

export default ErrorLoginScreen;
