import React from 'react';
import {View, useTheme} from 'native-base';

type CompletionBarProps = {
  progress: number;
};

const CompletionBar: React.FC<CompletionBarProps> = ({progress}) => {
  const theme = useTheme();

  return (
    <View>
      <View style={{width: '100%', height: 20, backgroundColor: '#DDD', borderRadius: 10, marginTop: 5}}>
        <View style={{width: `${progress}%`, height: '100%', backgroundColor: theme.colors.primary[500], borderRadius: 10}} />
      </View>
    </View>
  );
};

export default CompletionBar;
