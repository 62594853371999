import {theme, Text, Stack, View} from 'native-base';
import {useBottomModal} from './BottomModalContext';
import {WINDOW_HEIGHT, WINDOW_WIDTH} from '../../utils/mixins';

export type BottomModalProps = {
  title?: string;
  description?: string;
  // eslint-disable-next-line no-undef
  children?: JSX.Element;
};

const BottomModal = () => {
  const {isVisible, bottomModalProps} = useBottomModal();

  return (
    isVisible && (
      <>
        <View backgroundColor="black" opacity={40} width={WINDOW_WIDTH} height={WINDOW_HEIGHT} position="absolute" />
        <Stack direction="column" backgroundColor="white" borderTopRadius="2xl" overflow="hidden" p={6} space={6} position="absolute" bottom={0} width="100%">
          {bottomModalProps.title && (
            <Text fontWeight={700} fontSize="xl" color={theme.colors.darkText[500]}>
              {bottomModalProps.title}
            </Text>
          )}

          {bottomModalProps.description && <Text>{bottomModalProps.description}</Text>}
          {bottomModalProps.children}
        </Stack>
      </>
    )
  );
};

export default BottomModal;
