import {CompositeNavigationProp, useNavigation} from '@react-navigation/native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {Button, View, Text, useTheme, VStack} from 'native-base';
import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ScreensParams} from '../navigators/screens';
import PuntosYods from '../components/PuntosYods';
import ImageAndBadgeLayout from '../layouts/ImageAndBadgeLayout';
import i18n from '../../i18n';

type Props = NativeStackScreenProps<ScreensParams, 'ChallengeCompleted'> & {
  obtainedPoints?: number;
  numOds?: number;
};

const ChallengeComplete: FC<Props> = props => {
  const obtainedPoints = Number(props.route.params.obtainedPoints) ?? 0;
  const numOds = Number(props.route.params.numOds) ?? 0;
  const challengeId = Number(props.route.params.id) ?? 1;
  const numChallenges = props.route.params.numChallenges ?? 1;

  let launchRoulette = props.route.params.launchRoulette ? props.route.params.launchRoulette : false;
  launchRoulette = typeof launchRoulette === 'string' ? launchRoulette === 'true' : launchRoulette;

  let challengeSuccesFull = props.route.params.challengeSuccesFull ? props.route.params.challengeSuccesFull : false;
  challengeSuccesFull = typeof challengeSuccesFull === 'string' ? challengeSuccesFull === 'true' : challengeSuccesFull;

  const [lang, setLang] = useState<string>('es');

  useEffect(() => {
    const {language} = i18n;
    setLang(language);
  }, [i18n.language]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<CompositeNavigationProp<any, any>>();
  const theme = useTheme();
  const {t} = useTranslation();

  const ods_description = (odId: number) => {
    switch (odId) {
      case 1:
      case 2:
      case 3:
        return ['6', t('Agua limpia')];
      case 4:
      case 5:
      case 6:
        return ['7', t('Energía renovable')];
      case 7:
      case 8:
      case 9:
        return ['8', t('Crecimiento económico')];
      case 10:
      case 11:
      case 12:
        return ['12', t('Producción y consumo responsable')];
      case 13:
      case 14:
      case 15:
      default:
        return ['13', t('Acción por el clima')];
    }
  };

  const [odId, od_description] = ods_description(challengeId);

  return (
    <ImageAndBadgeLayout challengeId={challengeId.toString()} language={lang}>
      <View flex={1} justifyContent="center">
        <VStack space={6}>
          <VStack space={2}>
            {challengeSuccesFull && (
              <Text fontWeight={700} fontSize="xl" color={theme.colors.darkText[500]}>
                😊 {t('¡Felicidades, prueba superada con éxito!')}
              </Text>
            )}

            {!challengeSuccesFull && (
              <Text fontWeight={700} fontSize="xl" color={theme.colors.darkText[500]}>
                😊 {t('¡Felicidades, prueba completada!')}
              </Text>
            )}

            <Text fontWeight={700} fontSize="l" color={theme.colors.darkText[500]}>
              {`${t('Habéis sumado ')} ${obtainedPoints} ${t(' puntos.')}`}
            </Text>

            {numOds === 2 && (
              <Text fontWeight={300} color={theme.colors.darkText[500]}>
                {`${t('Ahora ya podéis realizar la última prueba de la ODS ')} ${odId}: ${t(od_description)}`}
              </Text>
            )}
          </VStack>

          <PuntosYods points={obtainedPoints} numOds={numOds} />

          <View>
            {!launchRoulette ? (
              <Button
                onPress={() => {
                  navigation.navigate('Map');
                }}>
                {t('Volver al mapa')}
              </Button>
            ) : (
              <Button
                onPress={() => {
                  /* navigation.reset({
                    index: 0,
                    routes: [{ name: 'RoulettePlay', params: {numChallenges} }],
                    
                  }) */
                  navigation.navigate('RoulettePlay', {numChallenges});
                }}>
                {t('Girar ruleta')}
              </Button>
            )}
          </View>
        </VStack>
      </View>
    </ImageAndBadgeLayout>
  );
};

export default ChallengeComplete;
