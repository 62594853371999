import {Button, Center, ScrollView, useTheme, Text, Checkbox, Box, HStack, Stack} from 'native-base';
import {DefaultTFuncReturn} from 'i18next';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {WINDOW_WIDTH} from '../../utils/mixins';
import {NavigationInfo} from '../../utils/types';

type QuestionareProps = {
  translatedChallenge?: DefaultTFuncReturn;
  translatedQuestion?: DefaultTFuncReturn;
  translatedQuestions: DefaultTFuncReturn[];
  translatedButtonText?: DefaultTFuncReturn;
  buttonAction?: (solution: NavigationInfo) => void;
  buttonSubmitAnswer: (answ: {solution: string | string[]; type: string}) => void;
  isButtonLoading?: boolean;
  isSingleChoice: boolean;
  colors: string[];
  rightAnswer: string;
  buttonReadyNavigation: boolean;
  navigationInfo: NavigationInfo;
  translatedSolutionText?: string;
};

const Questionare: React.FC<QuestionareProps> = ({
  translatedChallenge,
  translatedQuestion,
  translatedQuestions,
  translatedButtonText,
  buttonAction,
  buttonSubmitAnswer,
  isButtonLoading,
  isSingleChoice,
  colors,
  rightAnswer,
  buttonReadyNavigation,
  navigationInfo,
  translatedSolutionText,
}) => {
  const theme = useTheme();
  const {t} = useTranslation();

  const [selectedQuestions, setSelectedQuestions] = useState<number[]>([]);

  const answeredQuestionare = (answ: {solution: string | string[]; type: string}) => {
    buttonSubmitAnswer(answ);
  };

  return (
    <ScrollView showsHorizontalScrollIndicator={false}>
      <Stack space={4}>
        <Text color={theme.colors.lightText[500]} fontSize="md">
          {translatedQuestion}
        </Text>

        <Text fontWeight={700} fontSize="xl" color={theme.colors.darkText[500]}>
          {translatedChallenge}
        </Text>

        <Text color={theme.colors.lightText[500]} fontSize="md">
          {t('Selecciona la respuesta correcta')}
        </Text>

        {translatedQuestions.map((question, index) => (
          <HStack marginTop={1} justifyContent="center" alignItems="center">
            <Box flex={1} bg="white" style={{backgroundColor: colors[index]}} p={4}
borderRadius={8}>
              <Checkbox
                variant="form"
                key={index}
                //  style={{ backgroundColor: answeredQuestions[index] }}
                value={question as string}
                isChecked={selectedQuestions.includes(index)}
                size="md"
                rounded={isSingleChoice ? 30 : undefined}
                onChange={isChecked => {
                  if (isSingleChoice) {
                    setSelectedQuestions([index]);
                  } else if (isChecked && !selectedQuestions.includes(index)) {
                    setSelectedQuestions([...selectedQuestions, index]);
                  } else if (!isChecked && selectedQuestions.includes(index)) {
                    const newSelectedQuestions = selectedQuestions.filter(item => item !== index);
                    setSelectedQuestions(newSelectedQuestions);
                  }
                }}>
                {question}
              </Checkbox>
            </Box>
          </HStack>
        ))}

        {rightAnswer && (
          <Text color={theme.colors.lightText[500]} fontSize="md">
            {t('La respuesta correcta es:')} {translatedSolutionText}
          </Text>
        )}

        {buttonAction && !buttonReadyNavigation && (
          <Center>
            <Button
              isLoading={isButtonLoading}
              onPress={() => {
                if (selectedQuestions.length > 0) {
                  const sol = isSingleChoice ? selectedQuestions[0].toString() : selectedQuestions.map(question => question.toString());

                  if (isSingleChoice) {
                    const answeredObject = {solution: sol, type: 'questionare'};
                    answeredQuestionare(answeredObject);
                  } else {
                    const answeredObject = {solution: sol, type: 'multiQuestionare'};
                    answeredQuestionare(answeredObject);
                  }

                  // buttonSubmitAnswer(isSingleChoice ? selectedQuestions[0].toString() : selectedQuestions.map(question => question.toString()));
                }
              }}
              bgColor="primary.500"
              mt={4}
              w={WINDOW_WIDTH * 0.8}>
              {translatedButtonText}
            </Button>
          </Center>
        )}

        {buttonAction && buttonReadyNavigation && (
          <Center>
            <Button
              isLoading={isButtonLoading}
              onPress={() => {
                if (selectedQuestions.length > 0) {
                  buttonAction(navigationInfo);
                }
              }}
              bgColor="primary.500"
              mt={4}
              w={WINDOW_WIDTH * 0.8}>
              {t('Continuar')}
            </Button>
          </Center>
        )}
      </Stack>
    </ScrollView>
  );
};

export default Questionare;
