import {View, HStack, Icon, theme, Text} from 'native-base';
import {MaterialCommunityIcons} from '@native-base/icons';

type Props = {
  points?: number;
  digitsToShow?: number;
  textColor?: string;
  iconColor?: string;
  backgroundColor?: string;
  flex?: boolean;
  height?: string;
};

const formatNumberWithLeadingZeros = (number: number, digitsToShow: number): string => {
  const numberStr: string = number.toString();
  const leadingZerosCount: number = Math.max(digitsToShow - numberStr.length, 0);
  const leadingZeros: string = '0'.repeat(leadingZerosCount);
  return `${leadingZeros}${numberStr}`;
};

const PointScore = (props: Props) => (
  <View bg={props.backgroundColor || 'lightGrey.500'} h={props.height || 10} rounded="12px" p={2} alignItems={props.flex || false ? 'center' : undefined}>
    <HStack flexDirection="row" space="7px" alignItems="center" flex={props.flex || false ? 1 : undefined}>
      <Icon as={MaterialCommunityIcons} name="star-four-points" color={props.iconColor || theme.colors.lightText[500]} size="22px" />
      <Text color={props.textColor || 'darkText'} fontWeight="bold">
        {formatNumberWithLeadingZeros(props.points || 0, props.digitsToShow || 0)}
      </Text>
    </HStack>
  </View>
);

export default PointScore;
