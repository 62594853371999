import React, {useEffect, useState} from 'react';
import {Text, View, ScrollView, useTheme} from 'native-base';
import {useTranslation} from 'react-i18next';
import {retRanking} from '@bri/portaventura-core';
import {useGroupsService} from '../services/GroupsService';
import RankingItem from '../components/ranking/RankingItem';
import {getUserType} from '../utils/user';

type RankProgress = retRanking & {
  myPoints: number;
  myPosition: number;
  myName: string;
};

const RankScreen = () => {
  const {t} = useTranslation();
  const groupsService = useGroupsService();
  const [ranking, setRanking] = useState<RankProgress>({topTeams: [], myPoints: 0, myPosition: 0, myName: ''});
  const theme = useTheme();

  // TODO: CHANGE GREEN IMAGE TO MATCH WHITE IMAGE DIMENSIONS
  // TODO: Posar el nom real del grup enlloc ded tu escuela
  useEffect(() => {
    const loadRanking = async () => {
      const userType = await getUserType();

      groupsService
        .ranking({type: userType!})
        .response(res => setRanking(res))
        .error(e => console.log('error Use effect Ranking Screen', e));
    };

    loadRanking();
  }, []);

  return (
    <ScrollView showsVerticalScrollIndicator={false} flex={1}>
      <View paddingTop={10} paddingBottom={5} paddingLeft={5}>
        <Text
          fontWeight={700}
          fontSize="xl"
          flex={1}
          color={theme.colors.darkText[500]}
          style={{
            marginRight: 10,
            color: theme.colors.darkText[900],
          }}>
          {t('Ranking Global')}
        </Text>
        <Text fontWeight={700} color={theme.colors.primary[500]}>
          {t('¡Buen trabajo! Tu posición en el ranking es la #{{rankingPosition}}.', {rankingPosition: ranking.myPosition})}
        </Text>
      </View>

      {/* Your position */}
      <View
        style={{
          marginLeft: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <RankingItem rank={ranking.myPosition} name={ranking.myName} points={ranking.myPoints} isCurrentPlayer />
      </View>

      {/* Top ranking */}
      <View
        style={{
          marginLeft: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {ranking?.topTeams.slice(0, 5).map((item, index) => (
          <RankingItem key={item.groupName} rank={index + 1} name={item.groupName} points={item.points} />
        ))}
      </View>
    </ScrollView>
  );
};

export default RankScreen;
