import React, {useEffect, useState} from 'react';
import {Button, Center, ScrollView, View, useTheme, Text} from 'native-base';
import {huntProgress as HuntProgress} from '@bri/portaventura-core';
import {DefaultTFuncReturn} from 'i18next';
import {WINDOW_WIDTH} from '../../../src/utils/mixins';
import {useGroupsService} from '../../services/GroupsService';

type DescriptionProps = {
  translatedTitle?: DefaultTFuncReturn;
  translatedDescription?: DefaultTFuncReturn;
  translatedButtonText?: DefaultTFuncReturn;
  buttonAction?: (solution?: string | string[]) => void;
  buttonDescription?: (solution?: string | string[]) => void;
  isButtonLoading?: boolean;
  odsId: string;
};

const isFirstChallOds = (ods_Id: string, currentChallenge: HuntProgress) => {
  const first = currentChallenge.progress.challenges.some(chall => chall.od_id === ods_Id);
  return !first;
};

const Description: React.FC<DescriptionProps> = ({translatedButtonText, translatedDescription, translatedTitle, buttonAction, isButtonLoading, odsId, buttonDescription}) => {
  const theme = useTheme();
  const groupsService = useGroupsService();
  const [huntProgress, setHuntProgress] = useState<HuntProgress>({
    points: 0,
    progress: {challenges: [], badges: [], points: 0, start: new Date(), end: new Date(), playedRouletteIds: []},
  });

  useEffect(() => {
    groupsService
      .huntProgress()
      .response(res => {
        if (res && res.progress !== undefined) {
          setHuntProgress(res);
        }
      })
      .error(() => {
        console.log('error Use effect Progress Screen ');
        // Handle error if needed
      });
  }, []);

  const isFirstChallengeOds = isFirstChallOds(odsId, huntProgress);

  if (!isFirstChallengeOds && buttonDescription) {
    buttonDescription('');
  }

  return (
    <>
      <View />

      <ScrollView showsHorizontalScrollIndicator={false}>
        <View>
          <Text color={theme.colors.lightText[700]} fontSize="2xl">
            {translatedTitle}
          </Text>

          {isFirstChallengeOds && (
            <Text color={theme.colors.lightText[500]} fontSize="md">
              {translatedDescription}
            </Text>
          )}

          {buttonDescription && (
            <Center>
              <Button
                isLoading={isButtonLoading}
                onPress={() => {
                  buttonDescription('');
                }}
                bgColor="primary.500"
                mt={4}
                w={WINDOW_WIDTH * 0.8}>
                {translatedButtonText}
              </Button>
            </Center>
          )}
        </View>
      </ScrollView>
    </>
  );
};

export default Description;
