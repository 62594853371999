import {ScrollView, View, HStack, Text, useTheme} from 'native-base';
import React from 'react';
import {useTranslation} from 'react-i18next';
import ScrollODSItem, {ScrollODSItemProps} from './ScrollODSItem';

export type ScrollODSProps = {
  titleIcon: string;
  titleText: string;
  items: ScrollODSItemProps[];
};

const ScrollODS: React.FC<ScrollODSProps> = props => {
  const {t} = useTranslation();
  const theme = useTheme();
  return (
    <>
      <Text fontWeight={700} fontSize="xl" flex={1} color={theme.colors.darkText[500]} pb={6}>
        {`${props.titleIcon} ${t(props.titleText)}`}
      </Text>

      <ScrollView overflow="visible" horizontal showsHorizontalScrollIndicator style={{marginHorizontal: -24}}
contentContainerStyle={{marginHorizontal: 24}}>
        <View bgColor="white" padding={4} rounded="2xl">
          <HStack space={6}>
            {props.items.map(item => (
              <ScrollODSItem {...item} />
            ))}
          </HStack>
        </View>
      </ScrollView>
    </>
  );
};

export default ScrollODS;
