import {ScrollView} from 'native-base';
import React from 'react';

type Props = {
  // eslint-disable-next-line no-undef
  children: JSX.Element;
};

const CommonLayout = ({children}: Props) => (
  <ScrollView contentContainerStyle={{paddingHorizontal: 20, paddingVertical: 10}} showsVerticalScrollIndicator={false}>
    {children}
  </ScrollView>
);

export default CommonLayout;
