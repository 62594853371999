import {Box, Icon, Select} from 'native-base';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {AntDesign} from '@native-base/icons';
import {AVAILABLE_LANGUAGES} from '@bri/portaventura-core';
import {changeLanguage} from '../utils/languageUtils';

type Props = {
  shadow?: boolean;
};

const LanguageSelector: FC<Props> = ({shadow = false}) => {
  const {i18n} = useTranslation();

  return (
    <Box shadow={shadow ? 4 : undefined} borderRadius="xl">
      <Select
        selectedValue={i18n.language}
        accessibilityLabel="Select language"
        onValueChange={itemValue => changeLanguage(itemValue)}
        dropdownIcon={<Icon as={AntDesign} name="down" size={4} marginRight="5px" />}
        rounded="xl"
        width={65}
        height={10}
        borderColor="transparent"
        backgroundColor="white">
        {AVAILABLE_LANGUAGES.map(lang => (
          <Select.Item key={lang.toUpperCase()} label={lang.toUpperCase()} value={lang} />
        ))}
      </Select>
    </Box>
  );
};

export default LanguageSelector;
