import React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {ScreensParams} from '../screens';
import MapScreen from '../../screens/MapScreen';
import InfoScreen from '../../screens/InfoScreen';
import QrScreen from '../../screens/QrScreen';
import ChallengeComplete from '../../screens/ChallengeComplete';
import {useHeaderStyle} from '../../hooks/HeaderHook';
// import RoulettePlay from '../../screens/RoulettePlay';
import OdsCompleted from '../../screens/OdsCompleted';
import CameraScreen from '../../screens/CameraScreen';
import PremioRuleta from '../../screens/PremioRuleta';
import GameCompletedScreen from '../../screens/GameCompletedScreen';

const Stack = createNativeStackNavigator<ScreensParams>();

const MapStackNavigator = () => {
  const {header} = useHeaderStyle();

  return (
    <Stack.Navigator screenOptions={header}>
      <Stack.Screen name="Map" component={MapScreen} />
      <Stack.Screen name="Info" component={InfoScreen} />
      <Stack.Screen name="Scan" component={QrScreen} />
      <Stack.Screen name="Camera" component={CameraScreen} />
      <Stack.Screen name="ChallengeCompleted" component={ChallengeComplete} />
      <Stack.Screen name="OdsCompleted" component={OdsCompleted} />

      <Stack.Screen name="PremioRuleta" component={PremioRuleta} options={{headerLeft: () => null}} />
      <Stack.Screen name="GameCompleted" component={GameCompletedScreen} options={{headerShown: false}} />
      {/* <Stack.Screen name="Trial" component={TrialScreen} /> */}
    </Stack.Navigator>
  );
};

export default MapStackNavigator;
