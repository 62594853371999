/* eslint-disable @typescript-eslint/no-explicit-any */
import {CommonActions, DarkTheme, DefaultTheme, NavigationContainer, useNavigationContainerRef} from '@react-navigation/native';
import React, {useEffect, useRef, useState} from 'react';
import {useColorMode} from 'native-base';
import {useReduxDevToolsExtension} from '@react-navigation/devtools';
import * as Linking from 'expo-linking';
import {Platform, View} from 'react-native';
import {COOKIES_TYPES, CookiesService, StorageService} from '@bri/shared-components';

import {useRecoilState} from 'recoil';
import {findScreenToRedirect} from './Utils';
import {CookiesBanner} from '../components/CookiesBanner';
import StackNavigator from './StackNavigator';
import {userTokenRecoil} from '../recoil/userToken.recoil';
import BottomModal from '../hooks/BottomModal/BottomModal';
import {getLinkingSchema} from './linking';
import {useGroupsService} from '../services/GroupsService';
import {progressRecoil} from '../recoil/progress.recoil';
import {challengesRecoil} from '../recoil/challenges.recoil';
import {useChallengesService} from '../services/ChallengesService';
import {parseChallengesMultilanguageFields} from '../utils/challengesUtils';

const PERSISTENCE_KEY = 'NAVIGATION_STATE_V1';

const Navigator = () => {
  const [isReady, setIsReady] = React.useState(false);
  const [redirect, setRedirect] = React.useState<{redirect: string; params: {}}>();
  const [initialState, setInitialState] = React.useState();
  const navigatorRef = useNavigationContainerRef<any>();
  const routeNameRef = useRef<string>();
  const {colorMode} = useColorMode();
  const [userToken, setUserToken] = useRecoilState(userTokenRecoil);
  const [, setProgress] = useRecoilState(progressRecoil);
  const [, setChallenges] = useRecoilState(challengesRecoil);
  const groupsService = useGroupsService();
  const challengesService = useChallengesService();
  const [cookiesKey, setCookiesKey] = useState(0);

  const isAuthenticated = !!userToken;

  useReduxDevToolsExtension(navigatorRef);

  const linkingConfig = {
    screens: {
      NotFound: '*', // To catch urls not exists
    },
  };

  const redirectMatches = JSON.parse(JSON.stringify(linkingConfig));

  /*
  const linkingConfigAux: any = {...linkingConfig};
  if (isSignedIn) {
    // Remove auth from linking config
    // delete linkingConfigAux.screens[SCREENS.Auth];
  } else {
    // Remove app from linking config
    // delete linkingConfigAux.screens[SCREENS.App];
  }
  */

  useEffect(() => {
    const refreshChallenges = async () => {
      challengesService
        .getAll()
        .response(res => {
          setChallenges(parseChallengesMultilanguageFields(res));
        })
        .error(err => {
          console.log(err);
        });
    };

    const refreshUserTokenAndProgress = async () => {
      const jwt = await CookiesService.getType(COOKIES_TYPES.TECHNICAL, 'access_token');
      setUserToken(jwt ? {teamName: ''} : null);

      if (jwt) {
        groupsService
          .huntProgress()
          .response(res => {
            setProgress(res);
          })
          .error(err => {
            console.log(err);
            // TODO elimina token si invalid
          });
      }
    };

    refreshChallenges();
    refreshUserTokenAndProgress();
  }, []);

  React.useEffect(() => {
    const restoreState = async () => {
      try {
        const initialUrl = await Linking.getInitialURL();

        if (Platform.OS !== 'web' && initialUrl == null) {
          // Only restore state if there's no deep link and we're not on web
          const savedStateString = await StorageService.getItem(PERSISTENCE_KEY);
          const state = savedStateString ? JSON.parse(savedStateString) : undefined;

          if (state !== undefined) {
            setInitialState(state);
          }
        }
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady]);

  const redirectBehavior = (state: any) => {
    if (state.routes[0]) {
      if (state.routes[0].name === 'NotFound') {
        // Cuando no se encuentra la URL, buscamos la screen y la ponemos en el state y en la URL como redirect
        console.debug('Route not found', state.routes[0].path);
        let path = state.routes[0].path!.replace('/', '');
        if (path.indexOf('?') !== -1) {
          path = path.substring(0, path.indexOf('?'));
        }

        const screenName = findScreenToRedirect(redirectMatches.screens, path);
        console.debug('screenName', screenName);
        // Ponemos en el state la página a redirigir y los parámetros
        setRedirect({redirect: screenName || path, params: state.routes[0].params || {}});

        // Ponemos el redirect y todos los parámetros a la URL
        const newParam: any = {};
        newParam.redirect = screenName || path;
        navigatorRef.setParams({redirect});
        const {params} = state.routes[0] as any;
        if (params) {
          for (const param in params) {
            if (params[param]) {
              // NavigationService.setParam(param, params[param]);
            }
          }
        }
      } else {
        // Cuando en la URL ya tenemos el campo redirect, lo seteamos en el state
        const {params} = state.routes[0];
        if (params && (params as any).redirect) {
          console.debug('Redirect in URL: ', state.routes[0].params);
          const pathRedirect = (params as any).redirect;
          const paramsRedirect = {...params};
          delete (paramsRedirect as any).redirect;
          setRedirect({redirect: pathRedirect, params: paramsRedirect || {}});
        }
      }
    }
  };

  if (!isReady) {
    // We haven't finished checking for the token yet
    // return <SplashScreen />;
    return null;
  }

  const linkingSchema = getLinkingSchema(isAuthenticated);

  const updateCookiesBanner = async () => {
    if (!(await CookiesService.hasKey(COOKIES_TYPES.TECHNICAL, COOKIES_TYPES.TECHNICAL)) && !(navigatorRef.getCurrentRoute()?.name == 'Cookies')) {
      setCookiesKey(cookiesKey + 1);
    }
  };

  return (
    <NavigationContainer
      ref={navigatorRef}
      theme={colorMode === 'dark' ? DarkTheme : DefaultTheme}
      initialState={initialState}
      onReady={() => {
        routeNameRef.current = navigatorRef.getCurrentRoute()!.name;

        // REDIRECT BEHAVIOUR
        if (navigatorRef && navigatorRef.getState()) {
          redirectBehavior(navigatorRef.getState());
        }
      }}
      linking={{
        /*                                                        */
        /* *** Remember to change SCHEME attribute on app.json *** */
        /*                                                        */
        prefixes: ['##URL_PROJECTE##', Linking.createURL('')],
        config: linkingSchema,
        // getStateFromPath: (path, options) => {
        //   const originalPath = getStateFromPath(path, options);
        //   if (originalPath && originalPath.routes[0].name === 'NotFound') {
        //     redirectBehavior(originalPath);
        //   }
        //   return originalPath;
        // },
      }}
      onUnhandledAction={action => {
        console.error('ERROR! Ruta no definida: ', action);
      }}
      onStateChange={state => {
        Platform.OS !== 'web' && StorageService.setItem(PERSISTENCE_KEY, JSON.stringify(state));

        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigatorRef.getCurrentRoute()!.name;
        const trackScreenView = (routeName: string) => {
          // Your implementation of analytics goes here!
          console.log('Current route name: ', routeName);
        };

        if (isAuthenticated && redirect) {
          // && currentRouteName !== SCREENS.App) {
          console.debug('Trying to go... ', redirect.redirect);
          navigatorRef.dispatch(CommonActions.navigate(redirect.redirect, redirect.params));
          setRedirect(undefined);
        }

        if (previousRouteName !== currentRouteName) {
          // Save the current route name for later comparison
          routeNameRef.current = currentRouteName;

          console.log(routeNameRef.current);

          // Replace the line below to add the tracker from a mobile analytics SDK
          trackScreenView(currentRouteName);

          // Check if it should display cookies banner
          updateCookiesBanner();
        }
      }}>
      <StackNavigator />
      {routeNameRef.current === 'RoulettePlay' && <View style={{position: 'absolute', bottom: 0, height: 70, backgroundColor: '#f4f4f4', width: '100%', zIndex: 9}} />}
      <CookiesBanner key={cookiesKey} onAcceptPres={() => navigatorRef.navigate('CookiesConsent')} />

      <BottomModal />
    </NavigationContainer>
  );
};

export default Navigator;
