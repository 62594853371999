import {CompositeNavigationProp, useNavigation} from '@react-navigation/native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {ScrollView, Button, View, Center, Text, useTheme} from 'native-base';
import React, {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Price} from '@bri/portaventura-core';
import {ScreensParams} from '../navigators/screens';

import {PlayerLottie} from '../components/ruleta/PlayerLottie';
import {useActionPlayer} from '../components/ruleta/useActionPlayer';
import {useBottomModal} from '../hooks/BottomModal/BottomModalContext';
import {usePricesService} from '../services/PricesService';
import {parsePriceMultilanguageFields, parsePricesMultilanguageFields} from '../utils/pricesUtils';
import {getMultilanguageTranslation} from '../utils/languageUtils';

type Props = NativeStackScreenProps<ScreensParams, 'RoulettePlay'> & {};

const RoulettePlay: FC<Props> = props => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<CompositeNavigationProp<any, any>>();
  const {t} = useTranslation();
  const theme = useTheme();

  // Component initializer
  const tiradaExtraValue = props.route.params.tiradaExtra ?? false;
  const numChallengesValue = props.route.params.numChallenges ?? 6;

  const {showBottomModal, hideBottomModal} = useBottomModal();
  const [buttonClicked, setButtonClicked] = useState(false);
  const timerIdRef = useRef<any>(null);
  const [rollNumber, setRollNumber] = useState(-1);
  const [isPollingEnabled, setIsPollingEnabled] = useState(false);
  const [prices, setPrices] = useState<Price[]>([]);
  const [blockchainFinished, setBlockchainFinished] = useState(false);
  const [modalInfo, setModalInfo] = useState<{title: string; description: string; response: any}>();

  const {handlePause, handlePlay, handleStop, handleChange, speed, ...rest} = useActionPlayer();

  const pricesService = usePricesService();

  const onError = (error: any) => {
    handlePause();
    console.log(error);
    const title = t('¡Error inesperado!');
    const description = t('Ha ocurrido un error jugando a la ruleta');
    showBottomModal({
      title,
      description,
      children: (
        <>
          <Button
            colorScheme="primary"
            onPress={() => {
              hideBottomModal();
              navigation.navigate('Map');
            }}>
            {t('Volver al mapa')}
          </Button>
        </>
      ),
    });
  };

  const onRouletteStopCallback = () => {
    showBottomModal({
      title: modalInfo?.title,
      description: modalInfo?.description,
      children: (
        <>
          <Button
            colorScheme="primary"
            onPress={() => {
              hideBottomModal();
              if (modalInfo?.response.hasPrice) {
                navigation.navigate('PremioRuleta', {
                  image: modalInfo?.response.price?.image,
                  title: JSON.stringify(modalInfo?.response.price?.title),
                  priceCode: modalInfo?.response.code,
                  isMerchan: modalInfo?.response.price?.isMerchan,
                  location: JSON.stringify(modalInfo?.response.price?.location),
                  closingHour: modalInfo?.response.price?.closingHour,
                });
              } else {
                navigation.navigate('Map', {screen: 'Map'});
              }
            }}>
            {modalInfo?.response.hasPrice && t('Ver Premio')}
            {!modalInfo?.response.hasPrice && t('Volver al inicio')}
          </Button>
        </>
      ),
    });
  };

  useEffect(() => {
    const pollRouletteState = () => {
      pricesService
        .checkRoulette({rollNumber})
        .response(response => {
          if (response.blockchainPublished) {
            setBlockchainFinished(true);
            setIsPollingEnabled(false);

            let title = t('¡Sigue jugando!');
            let description = t('¡Lastima! hoy no es tu día de suerte, sigue jugando a la gymkana, desbloquea y supera pruebas para ganar.');

            if (response.hasPrice) {
              const price = parsePriceMultilanguageFields(response.price as Price);
              title = t('¡Enhorabuena!');
              description = `${t('Has ganado')} ${getMultilanguageTranslation(price.description)}. ${t(
                'Haz click en “Ver Premio” para conseguir tu código y descubrir como recoger o cambiar tu premio.'
              )}`;
            }

            setModalInfo({title, description, response});
          }
        })
        .error(err => {
          onError(err);
        });
    };

    const startPolling = () => {
      timerIdRef.current = setInterval(pollRouletteState, 1000);
    };

    const stopPolling = () => {
      if (timerIdRef.current) {
        clearInterval(timerIdRef.current);
      }
    };

    if (isPollingEnabled) {
      startPolling();
    } else {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [isPollingEnabled]);

  useEffect(() => {
    pricesService
      .getAvailablePrices({})
      .response(response => {
        setPrices(parsePricesMultilanguageFields(response));
      })
      .error(err => {
        console.log(err);
      });
    setButtonClicked(false);
  }, [numChallengesValue]);

  const startGame = () => {
    handlePlay();
    setButtonClicked(true);
    pricesService
      .playRoulette({})
      .response(response => {
        setRollNumber(response);
        setIsPollingEnabled(true);
      })
      .error(err => {
        onError(err);
      });
  };

  return (
    <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{flexGrow: 1, justifyContent: 'space-between'}}>
      <View paddingTop={4} paddingLeft={7} paddingRight={7} style={{flex: 1}}>
        <Center>
          <Text fontWeight={700} fontSize="xl" color={theme.colors.darkText[500]}>
            {!tiradaExtraValue && `🤪${t('¡Girar la Ruleta!')}`}
            {tiradaExtraValue && `🤪${t(' ¡Tirada Extra!')}`}
          </Text>
        </Center>

        <Center>
          <Text paddingTop={5} fontWeight={300} color={theme.colors.darkText[500]} textAlign="center">
            {!tiradaExtraValue && `${t('Habéis superado')} ${numChallengesValue} ${t('de las 15 pruebas, aquí vuestra recompensa ¡Girad la ruleta y ganad premios!')}`}
            {tiradaExtraValue && `${t('Gracias a vuestras acciones, habéis conseguido una tirada extra ¡Girad y ganad increíbles descuentos y pases para PortAventura! ')}`}
          </Text>
        </Center>

        <Text paddingTop={5} fontWeight={700} color={theme.colors.primary[500]} textAlign="center">
          {`${t('Premios disponibles:')} ${prices.map(p => getMultilanguageTranslation(p.title)).join(', ')}`}
        </Text>
        {!buttonClicked && (
          <View paddingBottom={5} paddingTop={5}>
            <Button marginLeft={12} marginRight={12} onPress={startGame}>
              {t('Girar')}
            </Button>
          </View>
        )}
      </View>

      <PlayerLottie {...rest} hasFinishedLoadingBlockchain={blockchainFinished} rouletteStopCallback={onRouletteStopCallback} />
    </ScrollView>
  );
};

export default RoulettePlay;
