import React from 'react';
import {Button, ScrollView, useTheme, Text, FormControl, Input, VStack, View} from 'native-base';
import {Controller, useForm} from 'react-hook-form';
import {DefaultTFuncReturn} from 'i18next';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import {NavigationInfo} from '../../utils/types';

type QuestionareProps = {
  translatedChallenge?: DefaultTFuncReturn;
  translatedQuestion?: DefaultTFuncReturn;
  translatedButtonText?: DefaultTFuncReturn;
  isButtonLoading?: boolean;
  buttonAction?: (solution: NavigationInfo) => void;
  buttonSubmitAnswer: (answ: {solution: string | string[]; type: string}) => void;
  colors: string[];
  buttonReadyNavigation: boolean;
  navigationInfo: NavigationInfo;
  rightAnswer: string;
  translatedSolutionText?: string;
};

const Simple: React.FC<QuestionareProps> = ({
  translatedChallenge,
  translatedQuestion,
  translatedButtonText,
  buttonAction,
  isButtonLoading,
  buttonSubmitAnswer,
  colors,
  buttonReadyNavigation,
  navigationInfo,
  rightAnswer,
  translatedSolutionText,
}) => {
  const theme = useTheme();
  const {t} = useTranslation();

  type FormFields = {
    simpleAnswer: number;
  };

  const FormSchema = Yup.object().shape({
    simpleAnswer: Yup.number().required(t('Required')!).typeError(t('Must be a number')!),
    //   .transform((_o, v) => parseInt(v.replace(',', '.'), 1000)),
  });

  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<FormFields>({resolver: yupResolver(FormSchema)});

  return (
    <>
      <View />

      <ScrollView showsHorizontalScrollIndicator={false}>
        <View>
          <Text color={theme.colors.lightText[500]} fontSize="md">
            {translatedQuestion}
          </Text>

          <Text color={theme.colors.lightText[500]} fontSize="md" style={{paddingTop: 20}}>
            {translatedChallenge}
          </Text>

          <VStack space="sm" paddingTop={10}>
            <FormControl isInvalid={'enigmaAnswer' in errors}>
              <Controller
                name="simpleAnswer"
                control={control}
                render={({field}) => (
                  <Input
                    keyboardType="decimal-pad"
                    placeholder={t('Respuesta')!}
                    style={{backgroundColor: colors[0]}}
                    variant={'simpleAnswer' in errors ? 'error' : 'form'}
                    {...field}
                    value={field.value?.toString()}
                  />
                )}
              />
              <View>{errors.simpleAnswer && <FormControl.ErrorMessage>{errors.simpleAnswer?.message}</FormControl.ErrorMessage>}</View>
            </FormControl>

            {rightAnswer && (
              <Text color={theme.colors.lightText[500]} fontSize="md">
                {t('La respuesta correcta es:')} {translatedSolutionText}
              </Text>
            )}

            {buttonAction && !buttonReadyNavigation && (
              <Button
                isLoading={isButtonLoading}
                onPress={handleSubmit(data => buttonSubmitAnswer({solution: data.simpleAnswer.toString(), type: 'simple'}))}
                bgColor="primary.500">
                {translatedButtonText}
              </Button>
            )}
            {buttonAction && buttonReadyNavigation && (
              <Button isLoading={isButtonLoading} onPress={() => buttonAction(navigationInfo)} bgColor="primary.500">
                {t('Continuar')}
              </Button>
            )}
          </VStack>
        </View>
      </ScrollView>
    </>
  );
};

export default Simple;
