import {Image} from 'react-native';

export type ImageResource = {
  uri: string;
};

const getResource = (required: number | string): ImageResource => {
  if (typeof required === 'number') {
    return Image.resolveAssetSource(required);
  }
  return {uri: required};
};

const getResourceUri = (required: number | string): string => {
  if (typeof required === 'number') {
    return Image.resolveAssetSource(required).uri;
  }
  return required;
};

const Images = {
  logo: require('../assets/newLogo.png'),
  homebackground: require('../assets/background.jpg'),
  oldLogo: require('../assets/home_logo.png'),
  cookieMonster: require('../assets/cookieMonster2x.png'),
  peopleWalkingSesame1x: require('../assets/peopleWalkingSesame1x.jpg'),
  peopleWalkingSesame2x: require('../assets/peopleWalkingSesame2x.png'),
  peopleWithWoody1x: require('../assets/peopleWithWoody1x.png'),
  peopleWithWoody2x: require('../assets/peopleWithWoody2x.png'),
  badgeOd1: require('../assets/images/badge1.png'),
  badgeOd2: require('../assets/images/badge2.png'),
  badgeOd3: require('../assets/images/badge3.png'),
  badgeOd4: require('../assets/images/badge4.png'),
  badgeOd6: require('../assets/images/badge6.png'),
  badgeOd7: require('../assets/images/badge7.png'),
  badgeOd9: require('../assets/images/badge9.png'),
  badgeOd8: require('../assets/images/badge8.png'),
  badgeOd12: require('../assets/images/badge12.png'),
  badgeOd13: require('../assets/images/badge13.png'),

  badgeOd6_es: require('../assets/images/badge6.png'),
  badgeOd7_es: require('../assets/images/badge7.png'),
  badgeOd8_es: require('../assets/images/badge8.png'),
  badgeOd12_es: require('../assets/images/badge12.png'),
  badgeOd13_es: require('../assets/images/badge13.png'),

  badgeOd6_ca: require('../assets/images/badges/badgeOds6_ca.png'),
  badgeOd7_ca: require('../assets/images/badges/badgeOds7_ca.png'),
  badgeOd8_ca: require('../assets/images/badges/badgeOds8_ca.png'),
  badgeOd12_ca: require('../assets/images/badges/badgeOds12_ca.png'),
  badgeOd13_ca: require('../assets/images/badges/badgeOds13_ca.png'),

  badgeOd6_en: require('../assets/images/badges/badgeOds6_en.png'),
  badgeOd7_en: require('../assets/images/badges/badgeOds7_en.png'),
  badgeOd8_en: require('../assets/images/badges/badgeOds8_en.png'),
  badgeOd12_en: require('../assets/images/badges/badgeOds12_en.png'),
  badgeOd13_en: require('../assets/images/badges/badgeOds13_en.png'),

  badgeOd6_fr: require('../assets/images/badges/badgeOds6_fr.png'),
  badgeOd7_fr: require('../assets/images/badges/badgeOds7_fr.png'),
  badgeOd8_fr: require('../assets/images/badges/badgeOds8_fr.png'),
  badgeOd12_fr: require('../assets/images/badges/badgeOds12_fr.png'),
  badgeOd13_fr: require('../assets/images/badges/badgeOds13_fr.png'),

  imageBackOd1: require('../assets/images/challengeOd1.jpg'),
  imageBackOd2: require('../assets/images/challengeOd2.jpg'),
  imageBackOd3: require('../assets/images/challengeOd3.jpg'),
  imageBackOd4: require('../assets/images/challengeOd4.jpg'),
  imageBackOd5: require('../assets/images/challengeOd5.jpg'),
  imageBackOd6: require('../assets/images/challengeOd6.jpg'),
  imageBackOd7: require('../assets/images/challengeOd7.jpg'),
  imageBackOd8: require('../assets/images/challengeOd8.jpg'),
  imageBackOd9: require('../assets/images/challengeOd9.jpg'),
  imageBackOd10: require('../assets/images/challengeOd10.jpg'),
  imageBackOd11: require('../assets/images/challengeOd11.jpg'),
  imageBackOd12: require('../assets/images/challengeOd12.jpg'),
  imageBackOd13: require('../assets/images/challengeOd13.jpg'),
  imageBackOd14: require('../assets/images/challengeOd14.jpg'),
  imageBackOd15: require('../assets/images/challengeOd15.jpg'),
  premioThumb: require('../assets/premios/pap_full.jpg'),
  vasoWinnie: require('../assets/premios/vasoWinnie.png'),
  gorraPA: require('../assets/premios/gorraPA.jpg'),
  boliWoody: require('../assets/premios/boliWoody.jpg'),
  estucheWinnie: require('../assets/premios/estucheWinnie.jpg'),
  handsClapping: require('../assets/premios/HandsClapping.svg'),
  mapOverlay: require('../assets/Map/portAventuraOverlay.webp'),
  cuboAmarillo: require('../assets/images/cuboAmarillo.png'),
  cuboAzul: require('../assets/images/cuboAzul.png'),
  cuboMarron: require('../assets/images/cuboMarron.png'),
  cuboGris: require('../assets/images/cuboGris.png'),
  aenor: require('../assets/images/aenor.jpg'),
  frameShape: require('../assets/ranking/frameShape.svg'),
  frameShapeGreen: require('../assets/ranking/frameShapeGreen.svg'),
  ruletaGif: require('../assets/ruleta/rouletteWheel.gif'),
  ruletaLot: require('../assets/ruleta/rouletteWheelLot.lottie'),
  waterElemental: require('../assets/odsCompleted/waterElemental.png'),
  cofre: require('../assets/odsCompleted/cofre.png'),
  gameCompleted: require('../assets/completed_game.png'),
  moodboard: require('../assets/Moodboard.png'),
  MapIcons: {
    starWhite: require('../assets/Map/starWhite.png'),
    starGreen: require('../assets/Map/starGreen.png'),
    starRed: require('../assets/Map/starRed.png'),
    qrWhite: require('../assets/Map/qrWhite.png'),
    qrGreen: require('../assets/Map/qrGreen.png'),
    qrRed: require('../assets/Map/qrRed.png'),
    userPosition: require('../assets/Map/userPosition.png'),
  },
};

export {Images, getResource, getResourceUri};
