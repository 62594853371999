import React, {useEffect, useState} from 'react';
import {ChallengeType} from '@bri/portaventura-core';
import Description from './challanges/Description';
import Enigma from './challanges/Enigma/Challenge';
import Simple from './challanges/Simple';
import DragAndDrop, {Word} from './challanges/DragAndDrop';
import Picture from './challanges/Picture';
import Questionare from './challanges/Questionare';
import Arrange from './challanges/Arrange/Challenge';
import YesNo from './challanges/YesNo';
import ImageAndBadgeLayout from '../layouts/ImageAndBadgeLayout';
import {NavigationInfo} from '../utils/types';
import i18n from '../../i18n';

type InfoChallengeProps = {
  odId: string;
  type: ChallengeType | 'description' | 'simple';
  translatedTitle?: string;
  translatedDescription?: string;
  translatedButtonText?: string;
  translatedChallenge?: string;
  translatedQuestion?: string;
  translatedQuestions?: string[];
  translatedFormula?: string;
  translatedOptions?: Word[];
  translatedDescriptionBold?: string;
  buttonAction?: (solution: NavigationInfo) => void;
  buttonDescription?: (solution?: string | string[]) => void;
  buttonSubmitAnswer: (answ: {solution: string | string[]; type: string}) => void;
  buttonSubmitPicture?: (solution: string | string[]) => void;
  isButtonLoading?: boolean;
  challengeId: string;
  colors: string[];
  rightAnswer: string;
  buttonReadyNavigation: boolean;
  navigationInfo: NavigationInfo;
  translatedSolutionText?: string;
};

const InfoChallenge: React.FC<InfoChallengeProps> = ({
  odId,
  type,
  translatedTitle,
  translatedDescription,
  translatedButtonText,
  translatedDescriptionBold,
  buttonAction,
  buttonSubmitAnswer,
  buttonSubmitPicture,
  buttonDescription,
  isButtonLoading,
  translatedFormula,
  translatedChallenge,
  translatedQuestion,
  translatedQuestions,
  translatedOptions,
  challengeId,
  colors,
  rightAnswer,
  buttonReadyNavigation,
  navigationInfo,
  translatedSolutionText,
}) => {
  const [lang, setLang] = useState<string>('');

  useEffect(() => {
    const {language} = i18n;
    setLang(language);
  }, [i18n.language]);

  return (
    <ImageAndBadgeLayout challengeId={challengeId} language={lang}>
      <>
        {type == 'dragAndDrop' && (
          <DragAndDrop
            translatedChallenge={translatedChallenge}
            translatedQuestion={translatedQuestion}
            translatedOptions={(translatedQuestions || []) as any}
            translatedButtonText={translatedButtonText}
            buttonAction={buttonAction}
            isButtonLoading={isButtonLoading}
            buttonSubmitAnswer={buttonSubmitAnswer}
            colors={colors}
            rightAnswer={rightAnswer}
            buttonReadyNavigation={buttonReadyNavigation}
            navigationInfo={navigationInfo}
          />
        )}

        {type == 'dragAndDrop2' && (
          <YesNo
            translatedChallenge={translatedChallenge}
            translatedQuestion={translatedQuestion}
            translatedOptions={(translatedQuestions || []) as any}
            translatedButtonText={translatedButtonText}
            buttonAction={buttonAction}
            isButtonLoading={isButtonLoading}
            buttonSubmitAnswer={buttonSubmitAnswer}
            colors={colors}
            rightAnswer={rightAnswer}
            buttonReadyNavigation={buttonReadyNavigation}
            navigationInfo={navigationInfo}
          />
        )}

        {type == 'arrange' && (
          <Arrange
            translatedChallenge={translatedChallenge}
            translatedQuestion={translatedQuestion}
            translatedQuestions={translatedQuestions || []}
            translatedButtonText={translatedButtonText}
            buttonAction={buttonAction}
            buttonSubmitAnswer={buttonSubmitAnswer}
            isButtonLoading={isButtonLoading}
            colors={colors}
            rightAnswer={rightAnswer}
            buttonReadyNavigation={buttonReadyNavigation}
            navigationInfo={navigationInfo}
            translatedSolutionText={translatedSolutionText}
          />
        )}

        {type == 'questionare' && (
          <Questionare
            translatedChallenge={translatedChallenge}
            translatedQuestion={translatedQuestion}
            translatedQuestions={translatedQuestions || []}
            translatedButtonText={translatedButtonText}
            buttonAction={buttonAction}
            buttonSubmitAnswer={buttonSubmitAnswer}
            isButtonLoading={isButtonLoading}
            isSingleChoice
            colors={colors}
            rightAnswer={rightAnswer}
            buttonReadyNavigation={buttonReadyNavigation}
            navigationInfo={navigationInfo}
            translatedSolutionText={translatedSolutionText}
          />
        )}

        {type == 'multiQuestionare' && (
          <Questionare
            translatedChallenge={translatedChallenge}
            translatedQuestion={translatedQuestion}
            translatedQuestions={translatedQuestions || []}
            translatedButtonText={translatedButtonText}
            buttonAction={buttonAction}
            buttonSubmitAnswer={buttonSubmitAnswer}
            isButtonLoading={isButtonLoading}
            isSingleChoice={false}
            colors={colors}
            rightAnswer={rightAnswer}
            buttonReadyNavigation={buttonReadyNavigation}
            navigationInfo={navigationInfo}
            translatedSolutionText={translatedSolutionText}
          />
        )}

        {type == 'description' && (
          <Description
            translatedButtonText={translatedButtonText}
            translatedDescription={translatedDescription}
            translatedTitle={translatedTitle}
            buttonDescription={buttonDescription}
            isButtonLoading={isButtonLoading}
            odsId={odId}
          />
        )}

        {type == 'enigma' && (
          <Enigma
            translatedButtonText={translatedButtonText}
            translatedDescription={translatedDescription}
            translatedTitle={translatedTitle}
            translatedFormula={translatedFormula}
            buttonAction={buttonAction}
            buttonSubmitAnswer={buttonSubmitAnswer}
            isButtonLoading={isButtonLoading}
            colors={colors}
            rightAnswer={rightAnswer}
            buttonReadyNavigation={buttonReadyNavigation}
            navigationInfo={navigationInfo}
            translatedSolutionText={translatedSolutionText}
          />
        )}

        {type == 'photo' && (
          <Picture
            translatedTitle={translatedTitle}
            translatedDescription={translatedDescription}
            translatedDescriptionBold={translatedDescriptionBold}
            buttonAction={buttonSubmitPicture}
            isButtonLoading={isButtonLoading}
          />
        )}

        {type == 'simple' && (
          <Simple
            translatedChallenge={translatedChallenge}
            translatedQuestion={translatedQuestion}
            translatedButtonText={translatedButtonText}
            buttonAction={buttonAction}
            buttonSubmitAnswer={buttonSubmitAnswer}
            isButtonLoading={isButtonLoading}
            colors={colors}
            rightAnswer={rightAnswer}
            buttonReadyNavigation={buttonReadyNavigation}
            navigationInfo={navigationInfo}
            translatedSolutionText={translatedSolutionText}
          />
        )}
      </>
    </ImageAndBadgeLayout>
  );
};

export default InfoChallenge;
