import {Price} from '@bri/portaventura-core';

export const parsePriceMultilanguageFields = (price: Price) => {
  const parsedPrice = price;
  parsedPrice.title = JSON.parse(parsedPrice.title as string);
  parsedPrice.description = JSON.parse(parsedPrice.description as string);
  parsedPrice.location = parsedPrice.location ? JSON.parse(parsedPrice.location as string) : undefined;

  return parsedPrice;
};

export const parsePricesMultilanguageFields = (prices: Price[]) => {
  const parsedPrices = prices;
  for (let index = 0; index < prices.length; index++) {
    parsedPrices[index] = parsePriceMultilanguageFields(prices[index]);
  }
  return parsedPrices;
};
