import {useEffect, useState} from 'react';
import CommonLayout from '../../layouts/CommonLayout';
import Pdf from '../../components/Pdf';
import i18n from '../../../i18n';

import PrivacyPolicyES from '../../assets/pdf/politicaDePrivacidad/POLITICA_DE_PRIVACIDAD_es.pdf';
import PrivacyPolicyCAT from '../../assets/pdf/politicaDePrivacidad/POLITICA_DE_PRIVACIDAD_cat.pdf';
import PrivacyPolicyEN from '../../assets/pdf/politicaDePrivacidad/POLITICA_DE_PRIVACIDAD_en.pdf';
import PrivacyPolicyFR from '../../assets/pdf/politicaDePrivacidad/POLITICA_DE_PRIVACIDAD_fr.pdf';

const selectPdf = (lang: string) => {
  switch (lang) {
    case 'es':
      return PrivacyPolicyES;
    case 'en':
      return PrivacyPolicyEN;
    case 'ca':
      return PrivacyPolicyCAT;
    case 'fr':
      return PrivacyPolicyFR;
    default:
      return PrivacyPolicyES;
  }
};

const PrivacyPolicyScreen = () => {
  const [lang, setLang] = useState<string>('');

  useEffect(() => {
    const {language} = i18n;
    setLang(language);
  }, [i18n.language]);

  const translatedPdf = selectPdf(lang);

  return (
    <CommonLayout>
      <Pdf url={translatedPdf} />
    </CommonLayout>
  );
};

export default PrivacyPolicyScreen;
