import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ScreensParams} from '../navigators/screens';
import Camera from '../components/Camera';

type Props = NativeStackScreenProps<ScreensParams, 'Camera'>;

const CameraScreen: FC<Props> = ({route}) => {
  const {t} = useTranslation();
  const [isCameraEnabled, setIsCameraEnabled] = useState<boolean>(true);

  const pictureTaken = () => {
    setIsCameraEnabled(false);
    route.params.pictureTaken();
  };

  return (
    isCameraEnabled && <Camera permissionDescription={t('Es necesario que permitas el acceso a tu cámara para poder completar la prueba.')} onPictureTaken={pictureTaken} />
  );
};

export default CameraScreen;
