import {FC, useEffect, useState} from 'react';
import {View, Text, ScrollView} from 'native-base';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {useTranslation} from 'react-i18next';
import {Price} from '@bri/portaventura-core';
import {useNavigation} from '@react-navigation/native';
import {ScreensParams} from '../navigators/screens';
// import { useNavigation } from '@react-navigation/native';
import PremiosBanner from '../components/premios/PremiosBanner';
import {usePricesService} from '../services/PricesService';
import {parsePricesMultilanguageFields} from '../utils/pricesUtils';
import {getMultilanguageTranslation} from '../utils/languageUtils';

type Props = NativeStackScreenProps<ScreensParams, 'Premios'> & {
  /*    image?: string ;
    title?: string
    description?: string
    codigo?: string */
};

const PremiosScreen: FC<Props> = props => {
  const {t} = useTranslation();
  const navigation = useNavigation<any>();

  const pricesService = usePricesService();
  const [prices, setPrices] = useState<Price[]>([]);
  const [wonPrices, setWonPrices] = useState<(Price & {redention_code: string})[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    pricesService
      .getGroupPrices({})
      .response(groupPrices => {
        setWonPrices(parsePricesMultilanguageFields(groupPrices) as any);
        setLoading(false);
      })
      .error(error => {
        console.log(error);
      });

    pricesService
      .getAvailablePrices({isAllPrices: true, filterWonPrices: true})
      .response(availablePrices => {
        setPrices(parsePricesMultilanguageFields(availablePrices));
      })
      .error(error => {
        console.log(error);
      });
  }, []);

  const navigateToPrice = (price: Price & {redention_code: string}) => {
    navigation.navigate('PremioRuleta', {
      image: price.image,
      title: JSON.stringify(price.title),
      priceCode: price.redention_code,
      isMerchan: price.isMerchan,
      location: JSON.stringify(price.location),
      closingHour: price.closingHour,
    });
  };

  return (
    <>
      <View style={{flex: 1}}>
        <ScrollView showsHorizontalScrollIndicator={false}>
          <View paddingTop={10} paddingLeft={4} paddingRight={4}>
            <Text fontWeight={700} fontSize="xl">
              🎉 {t('Mis Premios')}
            </Text>

            {!loading &&
              wonPrices.map((premio, index) => (
                <View paddingTop={10}>
                  <PremiosBanner
                    image={premio.image}
                    translatedTitle={getMultilanguageTranslation(premio.title)}
                    translatedButtonText={t('Ver')}
                    buttonAction={() => {
                      navigateToPrice(premio);
                    }}
                    available
                  />
                </View>
              ))}

            {!loading &&
              prices.map((premio, index) => (
                <View paddingTop={10}>
                  <PremiosBanner image={premio.image} translatedTitle={getMultilanguageTranslation(premio.title)} translatedButtonText={t('Ver')} />
                </View>
              ))}
          </View>
        </ScrollView>
      </View>
    </>
  );
};

export default PremiosScreen;
