import {Images} from './imagesUtils';

export type PortAventuraArea = 'Far West' | 'China' | 'Medierrània' | 'México' | 'Polynesia';

/* export const picPath = (odId: string) => {
  switch (odId) {
    case '1':
      return [Images.badgeOd6, Images.imageBackOd1, 6];
    case '2':
      return [Images.badgeOd6, Images.imageBackOd2, 6];
    case '3':
      return [Images.badgeOd6, Images.imageBackOd3, 6];
    case '4':
      return [Images.badgeOd7, Images.imageBackOd4, 7];
    case '5':
      return [Images.badgeOd7, Images.imageBackOd5, 7];
    case '6':
      return [Images.badgeOd7, Images.imageBackOd6, 7];
    case '7':
      return [Images.badgeOd8, Images.imageBackOd7, 8];
    case '8':
      return [Images.badgeOd8, Images.imageBackOd8, 8];
    case '9':
      return [Images.badgeOd8, Images.imageBackOd9, 8];
    case '10':
      return [Images.badgeOd12, Images.imageBackOd10, 12];
    case '11':
      return [Images.badgeOd12, Images.imageBackOd11, 12];
    case '12':
      return [Images.badgeOd12, Images.imageBackOd12, 12];
    case '13':
      return [Images.badgeOd13, Images.imageBackOd13, 13];
    case '14':
      return [Images.badgeOd13, Images.imageBackOd14, 13];
    case '15':
      return [Images.badgeOd13, Images.imageBackOd15, 13];
    default:
      return [Images.badgeOd1, Images.imageBackOd1, 13];
  } */
export const picPath = (challengeId: string, language: string) => {
  console.log('language', language);
  switch (challengeId) {
    case '1':
      if (language === 'es') {
        return [Images.badgeOd6, Images.imageBackOd1, 6];
      }
      if (language === 'ca') {
        return [Images.badgeOd6_ca, Images.imageBackOd1, 6];
      }
      if (language === 'en') {
        return [Images.badgeOd6_en, Images.imageBackOd1, 6];
      }
      if (language === 'fr') {
        return [Images.badgeOd6_fr, Images.imageBackOd1, 6];
      }

    case '2':
      console.log('insinde de info2');
      if (language === 'es') {
        console.log('espanish');
        return [Images.badgeOd6, Images.imageBackOd2, 6];
      }
      if (language === 'ca') {
        console.log('catalan');
        return [Images.badgeOd6_ca, Images.imageBackOd2, 6];
      }
      if (language === 'en') {
        console.log('english');
        return [Images.badgeOd6_en, Images.imageBackOd2, 6];
      }
      if (language === 'fr') {
        console.log('french');
        return [Images.badgeOd6_fr, Images.imageBackOd2, 6];
      }
    case '3':
      if (language === 'es') {
        return [Images.badgeOd6, Images.imageBackOd3, 6];
      }
      if (language === 'ca') {
        return [Images.badgeOd6_ca, Images.imageBackOd3, 6];
      }
      if (language === 'en') {
        return [Images.badgeOd6_en, Images.imageBackOd3, 6];
      }
      if (language === 'fr') {
        return [Images.badgeOd6_fr, Images.imageBackOd3, 6];
      }
    case '4':
      if (language === 'es') {
        return [Images.badgeOd7, Images.imageBackOd4, 7];
      }
      if (language === 'ca') {
        return [Images.badgeOd7_ca, Images.imageBackOd4, 7];
      }
      if (language === 'en') {
        return [Images.badgeOd7_en, Images.imageBackOd4, 7];
      }
      if (language === 'fr') {
        return [Images.badgeOd7_fr, Images.imageBackOd4, 7];
      }
    case '5':
      if (language === 'es') {
        return [Images.badgeOd7, Images.imageBackOd5, 7];
      }
      if (language === 'ca') {
        return [Images.badgeOd7_ca, Images.imageBackOd5, 7];
      }
      if (language === 'en') {
        return [Images.badgeOd7_en, Images.imageBackOd5, 7];
      }
      if (language === 'fr') {
        return [Images.badgeOd7_fr, Images.imageBackOd5, 7];
      }
    case '6':
      if (language === 'es') {
        return [Images.badgeOd7, Images.imageBackOd6, 7];
      }
      if (language === 'ca') {
        return [Images.badgeOd7_ca, Images.imageBackOd5, 7];
      }
      if (language === 'en') {
        return [Images.badgeOd7_en, Images.imageBackOd6, 7];
      }
      if (language === 'fr') {
        return [Images.badgeOd7_fr, Images.imageBackOd6, 7];
      }
    case '7':
      if (language === 'es') {
        return [Images.badgeOd8, Images.imageBackOd7, 8];
      }
      if (language === 'ca') {
        return [Images.badgeOd8_ca, Images.imageBackOd7, 8];
      }
      if (language === 'en') {
        return [Images.badgeOd8_en, Images.imageBackOd7, 8];
      }
      if (language === 'fr') {
        return [Images.badgeOd8_fr, Images.imageBackOd7, 8];
      }
    case '8':
      if (language === 'es') {
        return [Images.badgeOd8, Images.imageBackOd8, 8];
      }
      if (language === 'ca') {
        return [Images.badgeOd8_ca, Images.imageBackOd8, 8];
      }
      if (language === 'en') {
        return [Images.badgeOd8_en, Images.imageBackOd8, 8];
      }
      if (language === 'fr') {
        return [Images.badgeOd8_fr, Images.imageBackOd8, 8];
      }
    case '9':
      if (language === 'es') {
        return [Images.badgeOd8, Images.imageBackOd9, 8];
      }
      if (language === 'ca') {
        return [Images.badgeOd8_ca, Images.imageBackOd9, 8];
      }
      if (language === 'en') {
        return [Images.badgeOd8_en, Images.imageBackOd9, 8];
      }
      if (language === 'fr') {
        return [Images.badgeOd8_fr, Images.imageBackOd9, 8];
      }
    case '10':
      if (language === 'es') {
        return [Images.badgeOd12, Images.imageBackOd10, 12];
      }
      if (language === 'ca') {
        return [Images.badgeOd12_ca, Images.imageBackOd10, 12];
      }
      if (language === 'en') {
        return [Images.badgeOd12_en, Images.imageBackOd10, 12];
      }
      if (language === 'fr') {
        return [Images.badgeOd12_fr, Images.imageBackOd10, 12];
      }
    case '11':
      if (language === 'es') {
        return [Images.badgeOd12, Images.imageBackOd11, 12];
      }
      if (language === 'ca') {
        return [Images.badgeOd12_ca, Images.imageBackOd11, 12];
      }
      if (language === 'en') {
        return [Images.badgeOd12_en, Images.imageBackOd11, 12];
      }
      if (language === 'fr') {
        return [Images.badgeOd12_fr, Images.imageBackOd11, 12];
      }
    case '12':
      if (language === 'es') {
        return [Images.badgeOd12, Images.imageBackOd12, 12];
      }
      if (language === 'ca') {
        return [Images.badgeOd12_ca, Images.imageBackOd12, 12];
      }
      if (language === 'en') {
        return [Images.badgeOd12_en, Images.imageBackOd12, 12];
      }
      if (language === 'fr') {
        return [Images.badgeOd12_fr, Images.imageBackOd12, 12];
      }
    case '13':
      if (language === 'es') {
        return [Images.badgeOd13, Images.imageBackOd13, 13];
      }
      if (language === 'ca') {
        return [Images.badgeOd13_ca, Images.imageBackOd13, 13];
      }
      if (language === 'en') {
        return [Images.badgeOd13_en, Images.imageBackOd13, 13];
      }
      if (language === 'fr') {
        return [Images.badgeOd13_fr, Images.imageBackOd13, 13];
      }
    case '14':
      if (language === 'es') {
        return [Images.badgeOd13, Images.imageBackOd14, 13];
      }
      if (language === 'ca') {
        return [Images.badgeOd13_ca, Images.imageBackOd13, 13];
      }
      if (language === 'en') {
        return [Images.badgeOd13_en, Images.imageBackOd14, 13];
      }
      if (language === 'fr') {
        return [Images.badgeOd13_fr, Images.imageBackOd14, 13];
      }
    case '15':
      if (language === 'es') {
        return [Images.badgeOd13, Images.imageBackOd15, 13];
      }
      if (language === 'ca') {
        return [Images.badgeOd13_ca, Images.imageBackOd13, 13];
      }
      if (language === 'en') {
        return [Images.badgeOd13_en, Images.imageBackOd15, 13];
      }
      if (language === 'fr') {
        return [Images.badgeOd13_fr, Images.imageBackOd15, 13];
      }

    default:
      return [Images.badgeOd1, Images.imageBackOd1];
  }
};
