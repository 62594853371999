import {PricesServiceTypes as PricesServiceTypesPC} from '@bri/portaventura-core';
import {ServiceFunctionsType} from '@bri/shared-components';
import {useBaseService} from './BaseService';

export type PricesServiceTypes = Pick<PricesServiceTypesPC, 'get' | 'playRoulette' | 'checkRoulette' | 'getAvailablePrices' | 'getGroupPrices'>;

export function usePricesService() {
  const newBaseService = useBaseService();

  const pricesServiceFunctions: ServiceFunctionsType<PricesServiceTypes> = {
    get: params => newBaseService().get('v1/prices/get', params),
    getAvailablePrices: params => newBaseService().get('v1/prices/getAvailablePrices', params),
    getGroupPrices: params => newBaseService().get('v1/prices/getGroupPrices', params),
    playRoulette: params => newBaseService().get('v1/prices/playRoulette', params),
    checkRoulette: params => newBaseService().get('v1/prices/checkRoulette', params),
  };
  return pricesServiceFunctions;
}
