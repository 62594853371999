import React from 'react';
import {Button, ScrollView, View, Text, Image} from 'native-base';

import {DefaultTFuncReturn} from 'i18next';
import {ImageStyle} from 'react-native';
import {WINDOW_HEIGHT} from '../../utils/mixins';

import {Images} from '../../utils/imagesUtils';

type DescriptionProps = {
  image?: string;
  translatedTitle?: DefaultTFuncReturn;
  translatedButtonText?: DefaultTFuncReturn;
  buttonAction?: () => void;
  available?: boolean;
  isButtonLoading?: boolean;
};

const PremiosBanner: React.FC<DescriptionProps> = ({image, translatedTitle, buttonAction, isButtonLoading, translatedButtonText, available = false}) => (
  // const theme = useTheme();

  <>
    <ScrollView>
      <View>
        <Image
          source={Images[image as keyof typeof Images] || Images.premioThumb}
          style={{width: '100%', height: WINDOW_HEIGHT * 0.25, borderRadius: 20, filter: available ? 'none' : 'grayscale(100%)'} as ImageStyle}
        />

        <View paddingTop={4} paddingLeft={3} paddingRight={4}>
          <Text fontWeight={600} fontSize="xl">
            {translatedTitle}
          </Text>

          <View paddingLeft={10} paddingRight={10} paddingTop={4} paddingBottom={10}>
            {available && <Button onPress={buttonAction}>{translatedButtonText}</Button>}
          </View>
        </View>
      </View>
    </ScrollView>
  </>
);
export default PremiosBanner;
