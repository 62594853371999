import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {ScrollView} from 'native-base';
import React, {FC, useEffect, useState} from 'react';
import {useRecoilState} from 'recoil';
import {useTranslation} from 'react-i18next';
import {Challenge, ChallengeQuestion} from '@bri/portaventura-core';
import {useNavigation} from '@react-navigation/native';
import {ScreensParams} from '../navigators/screens';
import InfoChallenge from '../components/InfoChallenge';
import {userTokenRecoil} from '../recoil/userToken.recoil';
import {useGroupsService} from '../services/GroupsService';
import {progressRecoil} from '../recoil/progress.recoil';
import {challengesRecoil} from '../recoil/challenges.recoil';
import {getMultilanguageTranslation} from '../utils/languageUtils';

type Props = NativeStackScreenProps<ScreensParams, 'Info'>;

const InfoScreen: FC<Props> = props => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<any>();
  const [userToken] = useRecoilState(userTokenRecoil);
  const [progress, setProgress] = useRecoilState(progressRecoil);
  const [challenges] = useRecoilState(challengesRecoil);
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const groupsService = useGroupsService();
  const challengeId = props.route.params.id;
  const [challenge, setChallenge] = useState<Challenge>();
  const [challengeStarted, setChallengeStarted] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [colors, setColors] = useState<string[]>(['white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white', 'white']);
  const [rightAnswer, setRightAnswer] = useState<string>('');
  const [buttonReadyNavigation, setButtonReadyNavigation] = useState<boolean>(false);
  const [navigationInfo, setNavigationInfo] = useState<any>({});

  type NavigationInfo = {
    completedOdsCount: number;
    correctAnswer: boolean;
    isGameCompleted: boolean;
    isOdsCompleted: boolean;
    launchRoulette: boolean;
    numChallenges: number;
    points: number;
  };

  const startChallenge = () => {
    setLoading(true);

    groupsService
      .challangeStart({challangeId: challengeId})
      .response(() => {
        setChallengeStarted(true);
        // navigation.navigate('Trial', {id: challengeId});
      })
      .error(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const submitAnswer = (answ: {solution: string | string[]; type: string}) => {
    setLoading(true);

    groupsService
      .challangerEnd({
        challangeId: challengeId,
        solution: answ.solution,
      })
      .response(response => {
        // Update group progress. We call this here to update current header points and map
        groupsService // TODO maybe we can skip this call if challangerEnd response gives us the group progress
          .huntProgress()
          .response(resHunt => setProgress(resHunt))
          .error(() => {});

        const isOdsCompleted = response.isOdsCompleted as boolean;
        const correctAnswer = response.challange.isCorrectAnswer as boolean;
        const launchRoulette = response.launchRoulette as boolean;
        const isGameCompleted = response.isHuntCompleted;

        let resultArray: string[] = [];

        const resColors = (type: string) => {
          const colorRight = '#B6D5B3';
          const colorWrong = '#F2C2C2';

          switch (type) {
            case 'questionare':
              const correctColor = response.challange.isCorrectAnswer ? colorRight : colorWrong;
              for (let i = 0; i < 4; i++) {
                resultArray.push(i.toString() === response.challange.answer ? correctColor : '#ffffff');
              }
              return resultArray;
            case 'dragAndDrop':
              if (response.challange && response.challange.answer && response.challange.solution) {
                for (let i = 0; i < response.challange.answer.length; i++) {
                  const answerGroup = response.challange.answer[i].split(',');
                  const solutionGroup = response.challange.solution[i].split(',');

                  for (let j = 0; j < answerGroup.length; j++) {
                    if (solutionGroup.includes(answerGroup[j])) {
                      resultArray[Number(answerGroup[j])] = colorRight;
                    } else {
                      resultArray[Number(answerGroup[j])] = colorWrong;
                    }
                  }
                }
              }
              return resultArray;

            case 'arrange':
              if (response.challange && response.challange.answer && response.challange.solution) {
                for (let i = 0; i < response.challange.answer.length; i++) {
                  if (response.challange.answer[i] === response.challange.solution[i]) {
                    resultArray.push(colorRight);
                  } else {
                    resultArray.push(colorWrong);
                  }
                }
              }
              return resultArray;

            case 'multiQuestionare':
              for (let i = 0; i < 10; i++) {
                const numStr = i.toString();
                if (response.challange && response.challange.answer && response.challange.answer.includes(numStr)) {
                  if (response.challange && response.challange.solution && response.challange.solution.includes(numStr)) {
                    // eslint-enable no-return-assign
                    resultArray.push(colorRight);
                  } else {
                    // eslint-enable no-return-assign
                    resultArray.push(colorWrong);
                  }
                } else {
                  // eslint-enable no-return-assign
                  resultArray.push('white');
                }
              }
              return resultArray;

            case 'enigma':
              // eslint-disable-next-line no-return-assign
              return response.challange.isCorrectAnswer ? (resultArray = [colorRight]) : (resultArray = [colorWrong]);

            case 'simple':
              // eslint-disable-next-line no-return-assign
              return response.challange.isCorrectAnswer ? (resultArray = [colorRight]) : (resultArray = [colorWrong]);

            default:
              return '';
          }
        };

        const colorArray = resColors(answ.type);
        console.log({colorArray});

        setColors(resultArray);
        setRightAnswer(`${response.challange.answer}`);
        setButtonReadyNavigation(true);
        setNavigationInfo({
          isOdsCompleted,
          correctAnswer,
          launchRoulette,
          isGameCompleted,
          points: response.points,
          numChallenges: response.challange.numChallenges,
          completedOdsCount: response.completedOdsCount,
        });
      })

      .error(error => {
        console.error({error});
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submitNavigation = (solution: NavigationInfo) => {
    setLoading(true);

    const isOdsCompleted = solution.isOdsCompleted as boolean;
    const correctAnswer = solution.correctAnswer as boolean;
    const launchRoulette = solution.launchRoulette as boolean;
    const {isGameCompleted} = solution;
    const {points} = solution;
    const {completedOdsCount} = solution;
    const {numChallenges} = solution;

    // Update group progress. We call this here to update current header points and map

    if (isGameCompleted) {
      navigation.navigate('GameCompleted', {points, numChallenges});
    }
    if (!isGameCompleted && isOdsCompleted) {
      navigation.navigate('OdsCompleted', {
        id: challengeId,
        obtainedPoints: points,
        numOds: completedOdsCount,
        launchRoulette,
        numChallenges,
      });
    }
    if (!isGameCompleted && !isOdsCompleted && correctAnswer) {
      navigation.navigate('ChallengeCompleted', {
        id: challengeId,
        obtainedPoints: points,
        numOds: completedOdsCount,
        launchRoulette,
        numChallenges,
        challengeSuccesFull: true,
      });
    }
    if (!isGameCompleted && !isOdsCompleted && !correctAnswer) {
      navigation.navigate('ChallengeCompleted', {
        id: challengeId,
        obtainedPoints: points,
        numOds: completedOdsCount,
        launchRoulette,
        numChallenges,
        challengeSuccesFull: false,
      });
    }
  };

  const submitPicture = (solution: string | string[]) => {
    setLoading(true);

    groupsService
      .challangerEnd({
        challangeId: challengeId,
        solution,
      })
      .response(response => {
        const isOdsCompleted = response.isOdsCompleted as boolean;
        const correctAnswer = response.challange.isCorrectAnswer as boolean;
        const launchRoulette = response.launchRoulette as boolean;
        const isGameCompleted = response.isHuntCompleted;

        // Update group progress. We call this here to update current header points and map
        groupsService // TODO maybe we can skip this call if challangerEnd response gives us the group progress
          .huntProgress()
          .response(resHunt => setProgress(resHunt))
          .error(() => {});

        if (isGameCompleted) {
          navigation.navigate('GameCompleted', {points: response.points, numChallenges: response.challange.numChallenges});
        } else if (isOdsCompleted) {
          navigation.navigate('OdsCompleted', {
            id: challengeId,
            obtainedPoints: response.challange.points,
            numOds: response.completedOdsCount,
            launchRoulette,
            numChallenges: response.challange.numChallenges,
          });
        } else if (correctAnswer) {
          navigation.navigate('ChallengeCompleted', {
            id: challengeId,
            obtainedPoints: response.challange.points,
            numOds: response.completedOdsCount,
            launchRoulette,
            numChallenges: response.challange.numChallenges,
          });
        }
        if (!isGameCompleted && !isOdsCompleted && !correctAnswer) {
          navigation.navigate('ChallengeCompleted', {
            id: challengeId,
            obtainedPoints: response.challange.points,
            numOds: response.completedOdsCount,
            launchRoulette,
            numChallenges: response.challange.numChallenges,
            challengeSuccesFull: false,
          });
          /*  showBottomModal({
            title: `😿 ${t('¡Oh, que fallo!')}`,
            description: t('Que no decaiga el ánimo, aún quedan muchas pruebas por resolver. Seguir acumulando puntos, desbloqueando pruebas y mucho más.') as string,
            children: (
              <Button
                colorScheme="primary"
                onPress={() => {
                  hideBottomModal();
                  if (!isOdsCompleted && launchRoulette) {
                    navigation.navigate('RoulettePlay', {numChallenges: response.challange.numChallenges});
                  } else if (!isOdsCompleted) {
                    navigation.navigate('Map');
                  }
                }}>
                {isOdsCompleted && t('Cerrar')}
                {!isOdsCompleted && !launchRoulette && t('Volver al Inicio')}
                {!isOdsCompleted && launchRoulette && t('Girar ruleta')}
              </Button>
            ),
          });
          */
        }
      })
      .error(error => {
        console.error({error});
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // Set current challenge
    if (challenges) {
      setChallenge(challenges.find(c => c['@id'] == challengeId));
    }
  }, [challenges]);

  useEffect(() => {
    if (!!userToken && !!challengeId && !!progress) {
      const groupChallenge = progress.progress.challenges.find(c => c.challenge_id == challengeId);
      if (!groupChallenge?.end) {
        setShowButton(true);
      }
    }
  }, [challenge, progress]);

  useEffect(() => {
    console.log(showButton);
  }, [showButton]);

  useEffect(() => {
    console.log('UserToken', userToken);
    if (!userToken) {
      navigation.navigate('Home');
    }
  }, []);

  return (
    <ScrollView showsVerticalScrollIndicator={false} flex={1} backgroundColor="#F4F4F4">
      {challenge && challengeStarted ? (
        <InfoChallenge
          odId={challenge?.od_id}
          type={challenge.type}
          translatedTitle={getMultilanguageTranslation(challenge.title)}
          translatedDescription={getMultilanguageTranslation(challenge.description)}
          translatedDescriptionBold={getMultilanguageTranslation(challenge.descriptionBold)}
          translatedChallenge={getMultilanguageTranslation(challenge.title)}
          translatedQuestion={getMultilanguageTranslation(challenge.description)}
          translatedQuestions={(challenge.question as ChallengeQuestion).options?.map(option => getMultilanguageTranslation(option))}
          translatedFormula={getMultilanguageTranslation((challenge.question as ChallengeQuestion).formula)}
          translatedButtonText={t('Enviar') as string}
          buttonAction={submitNavigation}
          buttonSubmitAnswer={submitAnswer}
          buttonSubmitPicture={submitPicture}
          colors={colors}
          isButtonLoading={loading}
          challengeId={challengeId}
          rightAnswer={rightAnswer}
          buttonReadyNavigation={buttonReadyNavigation}
          navigationInfo={navigationInfo}
          translatedSolutionText={getMultilanguageTranslation(challenge.solution_text)}
        />
      ) : (
        <InfoChallenge
          odId={challenge?.od_id || '0'}
          type="description"
          translatedTitle={getMultilanguageTranslation(challenge?.ods_title)}
          translatedDescription={getMultilanguageTranslation(challenge?.ods_description)}
          translatedButtonText={t('Hacer prueba') as string}
          //      buttonAction={showButton ? startChallenge : undefined}
          buttonDescription={showButton ? startChallenge : undefined}
          buttonSubmitAnswer={submitAnswer}
          isButtonLoading={loading}
          challengeId={challengeId}
          colors={colors}
          rightAnswer={rightAnswer}
          buttonReadyNavigation={buttonReadyNavigation}
          navigationInfo={navigationInfo}
        />
      )}
    </ScrollView>
  );
};

export default InfoScreen;
