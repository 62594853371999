export const getLinkingSchema = (isSignedIn: boolean | undefined) => {
  const linking: any = {
    screens: {
      OnBoarding: {path: 'on-boarding'},
      Home: {path: 'home'},
      Login: {path: 'login'},
      LoginVisitor: {path: 'login-visitor'},
      Register: {path: 'register'},
      ErrorLoginScreen: {path: 'login-error'},
      GeneratedCode: {path: 'generated-code'},
      CookiesConsent: {path: 'cookies-consent'},
      Cookies: {path: 'cookies'},
      TermsAndConditions: {path: 'terms-and-conditions'},
      PrivacyPolicy: {path: 'privacy-policy'},
      Info: {path: 'info/:id'},

      Game: {
        path: '',
        screens: {
          Progress: {
            path: 'progress',
            screens: {
              Progress: {
                path: 'progress',
              },
              Info: {
                path: 'info/:id',
              },
              Scan: {
                path: 'scan/:id',
              },
              Camera: {
                path: 'camera',
              },
              ChallengeCompleted: {
                path: 'challenge-completed',
              },
              OdsCompleted: {
                path: 'ods-completed',
              },
              RoulettePlay: {
                path: 'roulette-play',
              },
              PremioRuleta: {
                path: 'roulette-prize',
              },
              Premios: {
                path: 'prizes',
              },
              GameCompleted: {
                path: 'game-completed',
              },
            },
          },

          Map: {
            path: 'map',
            screens: {
              Map: {
                path: 'map',
              },
              Info: {
                path: 'info/:id',
              },
              Scan: {
                path: 'scan/:id',
              },
              Camera: {
                path: 'camera',
              },
              ChallengeCompleted: {
                path: 'challenge-completed',
              },
              OdsCompleted: {
                path: 'ods-completed',
              },
              RoulettePlay: {
                path: 'roulette-play',
              },
              PremioRuleta: {
                path: 'roulette-prize',
              },
              GameCompleted: {
                path: 'game-completed',
              },
            },
          },

          Rank: {
            path: 'rank',
            screens: {
              Rank: {
                path: 'rank',
              },
            },
          },

          Help: {
            path: 'help',
            screens: {
              Help: {
                path: 'help',
              },
            },
          },
        },
      },
    },
  };

  if (isSignedIn) {
    // Remove auth from linking config
    delete linking.screens.Info;
  } else {
    // Remove game from linking config
    delete linking.screens.Game;
  }

  return linking;
};
