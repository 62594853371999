/* eslint-disable @typescript-eslint/no-explicit-any */
let screenToRedirect = '';
export function findScreenToRedirect(object: any, pathToFind: string) {
  Object.entries(object).forEach(([key, value]) => {
    if (!screenToRedirect) {
      if (typeof value === 'object') {
        const valueAny = value as any;
        if (valueAny.screens) {
          findScreenToRedirect(valueAny.screens, pathToFind);
        }
        if (valueAny.path) {
          if (valueAny.path === pathToFind) {
            screenToRedirect = key;
          }
        }
      }
      if (value === pathToFind) {
        screenToRedirect = key;
      }
    }
  });
  return screenToRedirect;
}
