import 'react-native-gesture-handler';
import React, {useEffect} from 'react';
import {NativeBaseProvider} from 'native-base';
import './i18n';
import { View } from 'react-native';
import { COOKIES_TYPES, CookiesService, INTERNAL_EVENTS, SharedComponentsProvider, StorageService, useEventBus } from '@bri/shared-components';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFonts } from 'expo-font';
import { RecoilRoot } from 'recoil';
import Navigator from './src/navigators/Navigator';
import IPhoneOverlay from './src/components/IPhoneOverlay';
import { MAX_WINDOW_HEIGHT, WINDOW_WIDTH } from './src/utils/mixins';
import Config from './src/config/config';
import {PortAventuraTheme} from './src/theme/portaventura.theme';
import {changeLanguage} from './src/utils/languageUtils';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { BottomModalProvider } from './src/hooks/BottomModal/BottomModalContext';

export default function App() {
/*
  const [showLandingPage, setShowLandingPage] = useState(true);

   const toggleLandingPage = () => {
    setShowLandingPage(!showLandingPage);
  }; */
  StorageService.setStorage(AsyncStorage);

  const app = initializeApp(Config.firebaseConfig);
 const analytics = getAnalytics(app);

  const [fontsLoaded] = useFonts({
    GreenAdventure: require(`./src/assets/fonts/GreenAdventure.ttf`),
    AntDesign: require(`./src/assets/fonts/AntDesign.ttf`),
    Entypo: require(`./src/assets/fonts/Entypo.ttf`),
    EvilIcons: require(`./src/assets/fonts/EvilIcons.ttf`),
    Feather: require(`./src/assets/fonts/Feather.ttf`),
    FontAwesome: require(`./src/assets/fonts/FontAwesome.ttf`),
    FontAwesome5_Brands: require(`./src/assets/fonts/FontAwesome5_Brands.ttf`),
    FontAwesome5_Regular: require(`./src/assets/fonts/FontAwesome5_Regular.ttf`),
    FontAwesome5_Solid: require(`./src/assets/fonts/FontAwesome5_Solid.ttf`),
    Fontisto: require(`./src/assets/fonts/Fontisto.ttf`),
    Foundation: require(`./src/assets/fonts/Foundation.ttf`),
    Ionicons: require(`./src/assets/fonts/Ionicons.ttf`),
    MaterialCommunityIcons: require(`./src/assets/fonts/MaterialCommunityIcons.ttf`),
    MaterialIcons: require(`./src/assets/fonts/MaterialIcons.ttf`),
    Octicons: require(`./src/assets/fonts/Octicons.ttf`),
    SimpleLineIcons: require(`./src/assets/fonts/SimpleLineIcons.ttf`),
    Zocial: require(`./src/assets/fonts/Zocial.ttf`),
    'Montserrat-Black': require('./src/assets/fonts/Montserrat/Montserrat-Black.ttf'),
    'Montserrat-BlackItalic': require('./src/assets/fonts/Montserrat/Montserrat-BlackItalic.ttf'),
    'Montserrat-Bold': require('./src/assets/fonts/Montserrat/Montserrat-Bold.ttf'),
    'Montserrat-BoldItalic': require('./src/assets/fonts/Montserrat/Montserrat-BoldItalic.ttf'),
    'Montserrat-Italic': require('./src/assets/fonts/Montserrat/Montserrat-Italic.ttf'),
    'Montserrat-Light': require('./src/assets/fonts/Montserrat/Montserrat-Light.ttf'),
    'Montserrat-LightItalic': require('./src/assets/fonts/Montserrat/Montserrat-LightItalic.ttf'),
    'Montserrat-Medium': require('./src/assets/fonts/Montserrat/Montserrat-Medium.ttf'),
    'Montserrat-MediumItalic': require('./src/assets/fonts/Montserrat/Montserrat-MediumItalic.ttf'),
    'Montserrat-Regular': require('./src/assets/fonts/Montserrat/Montserrat-Regular.ttf'),
    'Montserrat-Thin': require('./src/assets/fonts/Montserrat/Montserrat-Thin.ttf'),
    'Montserrat-ThinItalic': require('./src/assets/fonts/Montserrat/Montserrat-ThinItalic.ttf'),
    Poppins: require('./src/assets/fonts/Poppins/Poppins-Regular.ttf'),
  });
  

  const eventBus = useEventBus();
  useEffect(() => {
    eventBus.addListener(INTERNAL_EVENTS.REDIRECT, data => {
      console.log('Redirect event received', data);
    });
    eventBus.addListener(INTERNAL_EVENTS.LOGOUT, data => {
      console.log('Logout event received', data);
    });
  
    const loadLanguageFromCookies = async () => {
      let language;
      if ((await CookiesService.getType(COOKIES_TYPES.PERSONALISATION, COOKIES_TYPES.PERSONALISATION)) === 'true') {
        language = await CookiesService.getType(COOKIES_TYPES.PERSONALISATION, 'language');
      }

      await changeLanguage(language);
    };

    loadLanguageFromCookies();
  }, []);

  if (!fontsLoaded) {
    return null;
  }

  return (
    <RecoilRoot>
      <SharedComponentsProvider config={Config}>
        <NativeBaseProvider theme={PortAventuraTheme}>
          <BottomModalProvider>
            <IPhoneOverlay />
            <View
              style={{
                width: WINDOW_WIDTH,
                maxWidth: WINDOW_WIDTH,
                height: '100%',
                maxHeight: MAX_WINDOW_HEIGHT,
                margin: 'auto',
                backgroundColor: '#F4F4F4',
              }}>
              <Navigator />
            </View>
          </BottomModalProvider>
        </NativeBaseProvider>
      </SharedComponentsProvider>
    </RecoilRoot>
  );
}
