import {Marker, MapContainer, ImageOverlay, useMap} from 'react-leaflet';
import L, {LatLngBoundsExpression, LatLngTuple} from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, {useEffect} from 'react';
import {Location} from '@bri/shared-components';
import {Images} from '../utils/imagesUtils';

interface LeafLetMapProps {
  locations: Location[];
  userLocation?: Location['coords'];
}

export function LeafLetMap({locations, userLocation}: LeafLetMapProps) {
  // Port Aventura
  const position: LatLngTuple = [41.08704857194709, 1.158063683319092]; // center of the map
  const bounds: LatLngBoundsExpression = [
    [41.0786383527142, 1.1432033755573],
    [41.0993208604126, 1.1632582463657],
  ];

  const handleMarkerClick = (location: any) => {
    if (location.onClick) {
      location.onClick();
    }
  };

  const createIcon = (url?: string) =>
    L.icon({
      iconUrl: url || '',
      iconSize: [41, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    });
  return (
    <div className="map" style={{height: '100%'}}>
      <MapContainer
        center={position}
        zoom={16}
        maxZoom={18}
        style={{height: '100%', background: '#607258'}}
        maxBounds={bounds}
        maxBoundsViscosity={1}
        attributionControl={false}
        zoomControl={false}
        bounceAtZoomLimits={false}
        zoomAnimation={false}
        fadeAnimation={false}
        zoomSnap={0}>
        <ImageOverlay url={Images.mapOverlay} bounds={bounds as LatLngTuple[]} />
        <UpdateZoom mapBounds={bounds} mapCenter={position} />
        <BoundsEnforcer bounds={bounds} />

        {locations?.map((location, index) => (
          <>
            <Marker
              position={[location.coords.lat, location.coords.lng] as LatLngTuple}
              icon={createIcon(location.iconImage?.url)}
              eventHandlers={{
                click: e => {
                  handleMarkerClick(locations[index]);
                },
              }}
            />
          </>
        ))}
      </MapContainer>
    </div>
  );
}

type UpdateZoomProps = {
  mapBounds: LatLngBoundsExpression;
  mapCenter: LatLngTuple;
};

const UpdateZoom: React.FC<UpdateZoomProps> = ({mapBounds, mapCenter}) => {
  const map = useMap();
  useEffect(() => {
    const zoom = map.getBoundsZoom(mapBounds as any, true);
    map.setMinZoom(zoom);
  }, []);

  return null;
};

type BoundsEnforcerProps = {
  bounds: any;
};

const BoundsEnforcer: React.FC<BoundsEnforcerProps> = ({bounds}) => {
  const map = useMap();

  useEffect(() => {
    const boundsLatLng = L.latLngBounds(bounds);

    const enforceBounds = () => {
      const currentBounds = map.getBounds();
      if (!boundsLatLng.contains(currentBounds)) {
        const center = map.getCenter();
        const clampedLat = Math.max(Math.min(center.lat, bounds[1][0]), bounds[0][0]);
        const clampedLng = Math.max(Math.min(center.lng, bounds[1][1]), bounds[0][1]);
        map.setView(new L.LatLng(clampedLat, clampedLng), map.getZoom(), {animate: false});
      }
    };

    const handleDrag = () => {
      const center = map.getCenter();
      const clampedLat = Math.max(Math.min(center.lat, bounds[1][0]), bounds[0][0]);
      const clampedLng = Math.max(Math.min(center.lng, bounds[1][1]), bounds[0][1]);

      if (center.lat !== clampedLat || center.lng !== clampedLng) {
        map.panTo(new L.LatLng(clampedLat, clampedLng), {animate: false});
      }
    };

    const handleZoom = () => {
      enforceBounds();
    };

    map.on('drag', handleDrag);
    map.on('zoom', handleZoom);

    return () => {
      map.off('drag', handleDrag);
      map.off('zoom', handleZoom);
    };
  }, [map, bounds]);

  return null;
};
