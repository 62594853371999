import {View, Text, Input, Checkbox, Button, FormControl, Stack, useTheme, Box, Image} from 'native-base';
import React, {useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {COOKIES_TYPES, CookiesService} from '@bri/shared-components';
import {useNavigation} from '@react-navigation/native';
import {useRecoilState} from 'recoil';
import {useGroupsService} from '../services/GroupsService';
import {getResource, Images} from '../utils/imagesUtils';
import {userTokenRecoil} from '../recoil/userToken.recoil';

type FormFields = {
  registrationCode: string;
  groupName: string;
  acceptTerms?: boolean;
};

const LoginScreen = () => {
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const groupsService = useGroupsService();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<any>();
  const [, setUserToken] = useRecoilState(userTokenRecoil);

  const FormSchema = Yup.object().shape({
    registrationCode: Yup.string().required(t('Debes introducir el código de acceso') as string),
    groupName: Yup.string()
      .required(t('Debes introducir el nombre del equipo') as string)
      .max(20, t('Máximo 20 carácteres') as string),
    acceptTerms: Yup.boolean().oneOf([true], t('Debes aceptar los Términos y Condiciones') as string),
  });

  const {
    control,
    formState: {errors},
    handleSubmit,
  } = useForm<FormFields>({
    defaultValues: {acceptTerms: false},
    resolver: yupResolver(FormSchema),
  });

  const submitForm: SubmitHandler<FormFields> = data => {
    setIsLoading(true);

    groupsService
      .login({registrationCode: data.registrationCode, groupName: data.groupName})
      .apiResponse(async res => {
        if (res.response) {
          const jwt = res.response;
          await CookiesService.setType(COOKIES_TYPES.TECHNICAL, 'access_token', jwt);
          setUserToken({teamName: ''});
        } else {
          navigation.navigate('ErrorLoginScreen');
        }
      })
      .response(() => {})
      .error(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <View flex={1}>
      <Box flex={1} mb={-6}>
        <Image source={getResource(Images.peopleWalkingSesame2x)} resizeMode="cover" h="100%" w="100%" />
      </Box>

      <Stack direction="column" backgroundColor="white" borderTopRadius="2xl" p={6} space={6}>
        <Text fontWeight={700} fontSize="xl" flex={1} color={theme.colors.darkText[500]}>
          {`✏️ ${t('Introducid el código de vuestro equipo')}`}
        </Text>

        <Text color={theme.colors.lightText[500]} fontSize="md">
          {t('Para poder guardar todo el progreso vuestro tutor o tutora os facilitara un código de acceso, también deberéis introducir un nombre para vuestro equipo.')}
        </Text>

        <Stack direction="column" space={6}>
          <FormControl isRequired isInvalid={'registrationCode' in errors} key="registrationCode" flex={1}>
            <Controller
              name="registrationCode"
              control={control}
              render={({field}) => <Input variant="form" placeholder={t('Código de acceso: 0A0B') as string} {...field} />}
            />
            {errors.registrationCode && (
              <FormControl.ErrorMessage>
                <Text color="red.500">{errors.registrationCode.message}</Text>
              </FormControl.ErrorMessage>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={'groupName' in errors} key="groupName" flex={1}>
            <Controller name="groupName" control={control} render={({field}) => <Input variant="form" placeholder={t('Nombre de vuestro Equipo') as string} {...field} />} />
            {errors.groupName && (
              <FormControl.ErrorMessage>
                <Text color="red.500">{errors.groupName.message}</Text>
              </FormControl.ErrorMessage>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={'acceptTerms' in errors} key="acceptTerms" flex={1}>
            <Controller
              name="acceptTerms"
              control={control}
              render={({field}) => (
                <Stack direction="row" space={2}>
                  <Checkbox {...field} value={field.value ? 'true' : ''} ref={undefined} variant="form" size="md" />
                  <Text>
                    {`${t('Acepto los')} `}
                    <Text onPress={() => navigation.navigate('TermsAndConditions')} bold>
                      {t('Términos y Condiciones de uso')}
                    </Text>
                    {` ${t('de The Green Adventure.')}`}
                  </Text>
                </Stack>
              )}
            />
            {errors.acceptTerms && (
              <FormControl.ErrorMessage>
                <Text color="red.500">{errors.acceptTerms.message}</Text>
              </FormControl.ErrorMessage>
            )}
          </FormControl>
        </Stack>

        <Button isLoading={isLoading} isLoadingText={t('¡Empezar!') as string} onPress={handleSubmit(submitForm)}>
          {t('¡Empezar!')}
        </Button>
      </Stack>
    </View>
  );
};

export default LoginScreen;
