import {Text, Input, Checkbox, Button, FormControl, Stack, useTheme, ScrollView} from 'native-base';
import React, {useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useNavigation} from '@react-navigation/native';
import {COOKIES_TYPES, CookiesService, TOAST_STATUS, TOAST_VARIANT, useSharedToast} from '@bri/shared-components';
import {BaseClientError} from '@bri/shared-core';
import {useAccessCodeService} from '../services/AccessCodeService';

type FormFields = {
  reservationCode: string;
  schoolName: string;
  name: string;
  email: string;
  acceptTerms?: boolean;
  acceptPolicy?: boolean;
};

const RegisterScreen = () => {
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<any>();
  const sharedToast = useSharedToast();
  const accessCodeService = useAccessCodeService();

  // Expresión regular para verificar si el localizador es un OrderId de 6 dígitos
  const orderIdRegex = /^\d{6,7}$/;
  // Expresión regular para verificar si el localizador es un External Id con el formato especificado
  const externalIdRegex = /^\d{8}-\d{7}$/;
  const hotelRegex = /HPAW-\d{7}$/;

  const ReservationCodeSchema = () =>
    Yup.string()
      .test('is-valid-reservation-code', t('Localizador no válido') as string, value => {
        if (!!value && (value.match(orderIdRegex) || value.match(externalIdRegex) || value.match(hotelRegex))) {
          return true;
        }
        return false;
      })
      .required(t('Debes introducir el código de reserva') as string);

  const FormSchema = Yup.object().shape({
    reservationCode: ReservationCodeSchema(),
    schoolName: Yup.string().required(t('Debes introducir el nombre de la escuela') as string),
    name: Yup.string().required(t('Debes introducir tu nombre') as string),
    email: Yup.string()
      .email(t('Email no válido') as string)
      .required(t('Debes introducir el email') as string),
    acceptTerms: Yup.boolean().oneOf([true], t('Debes aceptar los Términos y Condiciones') as string),
    acceptPolicy: Yup.boolean().oneOf([true], t('Debes aceptar la Política de Privacidad') as string),
  });

  const {
    control,
    formState: {errors},
    handleSubmit,
  } = useForm<FormFields>({
    defaultValues: {acceptTerms: false, acceptPolicy: false},
    resolver: yupResolver(FormSchema),
  });

  const submitForm: SubmitHandler<FormFields> = data => {
    setIsLoading(true);

    accessCodeService
      .classRegistration({
        reservationCode: data.reservationCode,
        schoolName: data.schoolName,
        name: data.name,
        email: data.email,
        className: '',
      })
      .apiResponse(async res => {
        if (res.response) {
          const code = res.response;
          if (await CookiesService.hasKey(COOKIES_TYPES.TECHNICAL, COOKIES_TYPES.TECHNICAL)) {
            await CookiesService.setType(COOKIES_TYPES.TECHNICAL, 'code', code);
          }
          navigation.navigate('GeneratedCode', {code});
        } else {
          let errorMessage = t('Ha ocurrido un error');

          const error = res.error as BaseClientError;
          if (error?.message) {
            errorMessage = t(error?.message);
          }

          sharedToast({
            title: errorMessage,
            description: '',
            status: TOAST_STATUS.ERROR,
            variant: TOAST_VARIANT.SUBTLE,
          });
        }
      })
      .response(() => {})
      .error(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  console.log('errors', errors);
  return (
    <ScrollView showsVerticalScrollIndicator={false} flex={1} backgroundColor="white">
      <Stack direction="column" p={6} space={6}>
        <Text fontWeight={700} fontSize="xl" color={theme.colors.darkText[500]}>
          {`✏️ ${t('Introduce el localizador ')}`}
        </Text>

        <Text color={theme.colors.lightText[500]} fontSize="md">
          {t('Para poder generar el códico de acceso de tu grupo debes introducir el localizador de tu reserva y completar el formulario con los datos solicitados.')}
        </Text>

        <Stack direction="column" space={6}>
          <FormControl isRequired isInvalid={'reservationCode' in errors} key="reservationCode" flex={1}>
            <Controller
              name="reservationCode"
              control={control}
              render={({field}) => <Input variant="form" placeholder={t('Localizador: 000 000 000') as string} {...field} />}
            />

            {errors.reservationCode && (
              <FormControl.ErrorMessage>
                <Text color="red.500">{errors.reservationCode.message}</Text>
              </FormControl.ErrorMessage>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={'schoolName' in errors} key="schoolName" flex={1}>
            <Controller name="schoolName" control={control} render={({field}) => <Input variant="form" placeholder={t('Nombre de tu escuela') as string} {...field} />} />

            {errors.schoolName && (
              <FormControl.ErrorMessage>
                <Text color="red.500">{errors.schoolName.message}</Text>
              </FormControl.ErrorMessage>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={'name' in errors} key="name" flex={1}>
            <Controller name="name" control={control} render={({field}) => <Input variant="form" placeholder={t('Tu Nombre') as string} {...field} />} />
            {errors.name && (
              <FormControl.ErrorMessage>
                <Text color="red.500">{errors.name.message}</Text>
              </FormControl.ErrorMessage>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={'email' in errors} key="email" flex={1}>
            <Controller name="email" control={control} render={({field}) => <Input variant="form" placeholder={t('Email') as string} {...field} />} />
            {errors.email && (
              <FormControl.ErrorMessage>
                <Text color="red.500">{errors.email.message}</Text>
              </FormControl.ErrorMessage>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={'acceptTerms' in errors} key="acceptTerms" flex={1}>
            <Controller
              name="acceptTerms"
              control={control}
              render={({field}) => (
                <Stack direction="row" space={2}>
                  <Checkbox {...field} value={field.value ? 'true' : ''} ref={undefined} variant="form" size="md" />
                  <Text>
                    {`${t('Acepto los')} `}
                    <Text onPress={() => navigation.navigate('TermsAndConditions')} bold>
                      {t('Términos y Condiciones de uso')}
                    </Text>
                    {` ${t('de The Green Adventure.')}`}
                  </Text>
                </Stack>
              )}
            />
            {errors.acceptTerms && (
              <FormControl.ErrorMessage>
                <Text color="red.500">{errors.acceptTerms.message}</Text>
              </FormControl.ErrorMessage>
            )}
          </FormControl>

          <FormControl isRequired isInvalid={'acceptPolicy' in errors} key="acceptPolicy" flex={1}>
            <Controller
              name="acceptPolicy"
              control={control}
              render={({field}) => (
                <Stack direction="row" space={2}>
                  <Checkbox {...field} value={field.value ? 'true' : ''} ref={undefined} variant="form" size="md" />
                  <Text>
                    {`${t('Acepto la')} `}
                    <Text onPress={() => navigation.navigate('PrivacyPolicy')} bold>
                      {t('Política de Privacidad')}
                    </Text>
                  </Text>
                </Stack>
              )}
            />
            {errors.acceptPolicy && (
              <FormControl.ErrorMessage>
                <Text color="red.500">{errors.acceptPolicy.message}</Text>
              </FormControl.ErrorMessage>
            )}
          </FormControl>
        </Stack>

        <Button isLoading={isLoading} isLoadingText={t('¡Empezar!') as string} onPress={handleSubmit(submitForm)}>
          {t('¡Empezar!')}
        </Button>
      </Stack>
    </ScrollView>
  );
};

export default RegisterScreen;
