import React from 'react';
import {Cookies} from '@bri/shared-components';
import CommonLayout from '../../layouts/CommonLayout';
// TODO: FINISH FILLING INFORMATION (ID, APPORURL)
const CookiesScreen = () => (
  <CommonLayout>
    <Cookies
      company={{
        name: 'PORT AVENTURA ENTERTAINMENT, S.A.U.',
        id: '------------------',
        address: 'Avda. Alcalde Pere Molas, Km 2; C.P. 43480, Vila-seca (Tarragona).',
        contactEmail: 'lopd@portaventura.es',
      }}
      projectName="PORT AVENTURA WORLD"
      appOrUrl="localhost:19006"
      titleFontSize="2xl"
    />
  </CommonLayout>
);

export default CookiesScreen;
