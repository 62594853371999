import React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {ScreensParams} from '../screens';
import ProgressScreen from '../../screens/ProgressScreen';
import {useHeaderStyle} from '../../hooks/HeaderHook';
import PremiosScreen from '../../screens/PremiosScreen';
import PremioRuleta from '../../screens/PremioRuleta';
import QrScreen from '../../screens/QrScreen';
import CameraScreen from '../../screens/CameraScreen';
import InfoScreen from '../../screens/InfoScreen';
import ChallengeComplete from '../../screens/ChallengeComplete';
import OdsCompleted from '../../screens/OdsCompleted';
import RoulettePlay from '../../screens/RoulettePlay';
import GameCompletedScreen from '../../screens/GameCompletedScreen';

const Stack = createNativeStackNavigator<ScreensParams>();

const ProgressStackNavigator = () => {
  const {header} = useHeaderStyle();

  return (
    <Stack.Navigator screenOptions={header}>
      <Stack.Screen name="Progress" component={ProgressScreen} />
      <Stack.Screen name="Info" component={InfoScreen} />
      <Stack.Screen name="Scan" component={QrScreen} />
      <Stack.Screen name="Camera" component={CameraScreen} />
      <Stack.Screen name="ChallengeCompleted" component={ChallengeComplete} />
      <Stack.Screen name="OdsCompleted" component={OdsCompleted} />
      <Stack.Screen name="RoulettePlay" component={RoulettePlay} />
      <Stack.Screen name="PremioRuleta" component={PremioRuleta} />
      <Stack.Screen name="Premios" component={PremiosScreen} />
      <Stack.Screen name="GameCompleted" component={GameCompletedScreen} options={{headerShown: false}} />
      {/* <Stack.Screen name="Trial" component={TrialScreen} /> */}
    </Stack.Navigator>
  );
};

export default ProgressStackNavigator;
