import {AccessCodeServiceTypes as AccessCodeServiceTypesPC} from '@bri/portaventura-core';
import {ServiceFunctionsType, useBaseService} from '@bri/shared-components';

export type AccessCodeServiceTypes = Pick<AccessCodeServiceTypesPC, 'classRegistration'>;

export function useAccessCodeService() {
  const newBaseService = useBaseService();

  const accessCodeServiceFunctions: ServiceFunctionsType<AccessCodeServiceTypes> = {
    classRegistration: params => newBaseService().post('v1/accessCode/classRegistration', params),
  };
  return accessCodeServiceFunctions;
}
