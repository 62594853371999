import {Button, Stack, Text} from 'native-base';
import {FC, useEffect, useMemo, useRef} from 'react';
import {Camera as ExpoCamera, CameraType, CameraProps, PermissionStatus} from 'expo-camera';
import {useTranslation} from 'react-i18next';
import {useBottomModal} from '../hooks/BottomModal/BottomModalContext';

const BARCODE_QR = 'qr';

type Props = {
  permissionDescription: string;
  cameraType?: CameraType;
  onPermissionDenied?: () => void;
  onPictureTaken?: (picture: string) => void;
  scanQR?: true;
  onScannedQR?: (data: string) => void;
};

const Camera: FC<Props> = ({permissionDescription, cameraType = CameraType.back, onPermissionDenied, onPictureTaken, scanQR, onScannedQR}) => {
  const {t} = useTranslation();

  const camera = useRef<ExpoCamera>(null);
  const isCameraReady = useRef<boolean>(false);
  const [permission, requestPermission] = ExpoCamera.useCameraPermissions();
  const isCameraPermissionGranted = useMemo(() => permission?.status === PermissionStatus.GRANTED, [permission]);

  const {showBottomModal, hideBottomModal} = useBottomModal();

  const permissionDescriptionTranslated = t(permissionDescription)!;

  const handleCameraPermission = async (status: PermissionStatus | null) => {
    hideBottomModal();

    if (status === PermissionStatus.UNDETERMINED) {
      showBottomModal({
        title: `🎞️ ${t('PortAventura quiere usar tu cámara')!}`,
        description: permissionDescriptionTranslated,
        children: (
          <Button colorScheme="primary" onPress={() => hideBottomModal()}>
            {t('Cerrar')}
          </Button>
        ),
      });

      requestPermission();
    } else if (status === PermissionStatus.DENIED) {
      showBottomModal({
        title: `😢 ${t('PortAventura no puede usar tu cámara')!}`,
        description: permissionDescriptionTranslated,
        children: (
          <Button colorScheme="primary" onPress={() => hideBottomModal()}>
            {t('Cerrar')}
          </Button>
        ),
      });

      if (onPermissionDenied) onPermissionDenied();
    }
  };

  useEffect(() => {
    handleCameraPermission(permission?.status || null);
  }, [permission]);

  const stopCamera = async () => camera.current?.pausePreview();

  useEffect(
    () => () => {
      if (camera.current) stopCamera();
    },
    []
  );

  const settings = {} as CameraProps;

  if (scanQR) {
    settings.barCodeScannerSettings = {barCodeTypes: [BARCODE_QR]};

    if (onScannedQR) {
      settings.onBarCodeScanned = async result => {
        if (result.type !== BARCODE_QR) return;
        await camera.current?.pausePreview();
        onScannedQR(result.data);
      };
    }
  }

  const takePicture = async () => {
    if (!isCameraReady.current) return;

    const photo = await camera.current?.takePictureAsync();
    if (photo?.base64 && onPictureTaken) onPictureTaken(photo.base64);
    await camera.current?.pausePreview();
  };

  return (
    isCameraPermissionGranted && (
      <ExpoCamera
        {...settings}
        type={cameraType}
        style={{flex: 1, flexDirection: 'row', alignItems: 'flex-end'}}
        ref={camera}
        onCameraReady={() => {
          isCameraReady.current = true;
        }}>
        {!scanQR && (
          <Stack direction="column" backgroundColor="white" borderTopRadius="2xl" overflow="hidden"
p={6} space={6} position="absolute" bottom={0}
width="100%">
            <Text>{t('Esta foto solo se guarda en tu dispositivo')}</Text>
            <Button onPress={takePicture}>{t('Hacer foto')}</Button>
          </Stack>
        )}
      </ExpoCamera>
    )
  );
};

export default Camera;
