import {ChallengesServiceTypes as ChallengesServiceTypesPC} from '@bri/portaventura-core';
import {ServiceFunctionsType} from '@bri/shared-components';
import {useBaseService} from './BaseService';

export type ChallengesServiceTypes = Pick<ChallengesServiceTypesPC, 'getAll'> & Pick<ChallengesServiceTypesPC, 'get'>;

export function useChallengesService() {
  const newBaseService = useBaseService();

  const challengesServiceFunctions: ServiceFunctionsType<ChallengesServiceTypes> = {
    getAll: params => newBaseService().get('v1/challenges/getAll', params),
    get: params => newBaseService().get('v1/challenges/get', params),
  };
  return challengesServiceFunctions;
}
