import {View, Text, useTheme, Button, Image, Stack, Box, Icon} from 'native-base';
import {Share, TouchableOpacity} from 'react-native';
import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {COOKIES_TYPES, CookiesService, TOAST_STATUS, TOAST_VARIANT, useSharedToast} from '@bri/shared-components';
import {MaterialIcons} from '@native-base/icons';
import {Images, getResource} from '../utils/imagesUtils';
import {ScreensParams} from '../navigators/screens';
import {useClipboard} from '../hooks/ClipboardHook';

type Props = NativeStackScreenProps<ScreensParams, 'GeneratedCode'>;

const GeneratedCodeScreen: FC<Props> = props => {
  const {t} = useTranslation();
  const theme = useTheme();
  const [code, setCode] = useState<string>();
  const sharedToast = useSharedToast();
  const {copyToClipboard} = useClipboard();

  useEffect(() => {
    const setCodeFromPropsOrCookies = async () => {
      if (props.route.params && props.route.params.code) {
        setCode(props.route.params.code);
        return;
      }

      if (await CookiesService.hasKey(COOKIES_TYPES.TECHNICAL, COOKIES_TYPES.TECHNICAL)) {
        const cookiesCode = await CookiesService.getType(COOKIES_TYPES.TECHNICAL, 'code');
        if (cookiesCode) {
          setCode(cookiesCode);
          return;
        }
      }

      // Couldn't get code, display error
      sharedToast({
        title: t('Ha ocurrido un error'),
        description: '',
        status: TOAST_STATUS.ERROR,
        variant: TOAST_VARIANT.SUBTLE,
      });
    };

    setCodeFromPropsOrCookies();
  }, []);

  const OnShareButtonPressed = async () => {
    if (code) {
      Share.share({message: code});
    }
  };

  const OnCopyButtonPressed = () => {
    if (code) {
      copyToClipboard(code)
        .then(() => {
          sharedToast({
            title: t('Código copiado al portapapeles'),
            description: '',
            status: TOAST_STATUS.SUCCESS,
            variant: TOAST_VARIANT.SUBTLE,
          });
        })
        .catch(() => {
          sharedToast({
            title: t('Error al copiar el código'),
            description: '',
            status: TOAST_STATUS.ERROR,
            variant: TOAST_VARIANT.SUBTLE,
          });
        });
    }
  };

  return (
    <View flex={1}>
      <Box flex={1} mb={-6}>
        <Image source={getResource(Images.peopleWithWoody2x)} resizeMode="cover" h="100%" w="100%" />
      </Box>

      <Stack direction="column" backgroundColor="white" borderTopRadius="2xl" overflow="hidden" p={6} space={6}>
        <Text fontWeight={700} fontSize="xl" color={theme.colors.darkText[500]}>
          {`🔐 ${t('Código Generado con éxito')}`}
        </Text>

        <Text>{t('Comparte el código con tu grupo para que puedan empezar la gymkana.')}</Text>

        <TouchableOpacity onPress={OnCopyButtonPressed}>
          <Box bgColor="lightGrey.500" padding={4} rounded="xl" flexDirection="row"
justifyContent="center" alignItems="center">
            <Text textAlign="center" fontWeight={600} color={theme.colors.lightText[600]}>
              {code}
            </Text>
            <Icon as={MaterialIcons} name="content-copy" size={30} color={theme.colors.lightText[600]}
position="absolute" right={3} />
          </Box>
        </TouchableOpacity>

        <Button colorScheme="primary" onPress={OnShareButtonPressed}>
          {t('Compartir')}
        </Button>
      </Stack>
    </View>
  );
};

export default GeneratedCodeScreen;
