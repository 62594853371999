import React from 'react';
import {useNavigation} from '@react-navigation/native';
import {useTranslation} from 'react-i18next';
import {ImageBackground} from 'react-native';
import {Button, Image, Text, VStack, View} from 'native-base';
import LanguageSelector from '../components/LanguageSelector';
import background from '../assets/background.jpg';
import {Images, getResource} from '../utils/imagesUtils';

const HomeScreen = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<any>();
  const {t} = useTranslation();

  return (
    <ImageBackground source={background} style={{flex: 1}}>
      <View backgroundColor="black" opacity={0} style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}} />

      <VStack style={{flex: 1, justifyContent: 'space-between', padding: 6}}>
        <View style={{flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 12, paddingVertical: 12}}>
          <View style={{flex: 1}} /> {/* Empty View to push LanguageSelector to the right */}
          <View style={{alignItems: 'center', paddingTop: 30}}>
            <LanguageSelector />
          </View>
        </View>

        <View style={{alignItems: 'center', paddingTop: 12}}>
          <Image source={getResource(Images.logo)} style={{width: 341, height: 192}} />
          <View style={{marginTop: 16}}>
            <Text style={{fontFamily: 'Montserrat-Bold', fontSize: 22, fontWeight: '700', color: 'white', textAlign: 'center'}}>
              {t('Diviértete jugando a nuestra yincana más sostenible y ecológica mientras obtienes recompensas y premios')}
            </Text>
          </View>
        </View>

        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center', paddingBottom: 32}}>
          <View style={{width: '85%'}}>
            <Button colorScheme="primary" onPress={() => navigation.navigate('OnBoarding', {userType: 'visitor'})} style={{marginBottom: 16}}>
              {t('Soy visitante')}
            </Button>

            <Button colorScheme="primary" onPress={() => navigation.navigate('OnBoarding', {userType: 'scholar'})} style={{marginBottom: 16}}>
              {t('Soy grupo escolar')}
            </Button>
          </View>
        </View>
      </VStack>
    </ImageBackground>
  );
};

export default HomeScreen;

/*

 return (
    <ImageBackground source={background} style={{flex: 1}}>
      <View backgroundColor="black" opacity={0.4} position="absolute" top={0} left={0} right={0} bottom={0} />
      <VStack flex={1} justifyContent="space-between" paddingX={6} paddingY={6}>
        <View position="absolute" top={10} right={6} zIndex={1}>
          <LanguageSelector shadow />
        </View>
        <VStack flex={1} alignItems="center" space={16} paddingTop={6}>
          <Image source={getResource(Images.logo)} width="124px" height="135px" />
          <VStack space="lg">
            <Text fontFamily="Montserrat-Bold" textAlign="center" fontSize="22px" fontWeight={700} color="white">
              {t('Bienvenidos/as a la emocionante gymkana sobre sostenibilidad de PortAventura World: The Green Adventure!')} 🌍
            </Text>
            <Text fontFamily="Montserrat-Regular" textAlign="center" fontWeight={600} color="white">
              {t('¡Diviértete, aprende y gana fantásticos premios!')}
            </Text>
          </VStack>
        </VStack>
        <View flex={1} justifyContent="flex-end" paddingX={8}>
          <Button colorScheme="primary" onPress={() => navigation.navigate('OnBoarding')}>
            {t('Empezar')}
          </Button>
        </View>
      </VStack>
    </ImageBackground>
  );


*/
