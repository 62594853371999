import React from 'react';
import {createBottomTabNavigator, BottomTabNavigationOptions} from '@react-navigation/bottom-tabs';
import {Icon, Text, useTheme} from 'native-base';
import {useTranslation} from 'react-i18next';
import {ScreensParams} from '../screens';
import ProgressStackNavigator from './ProgressStackNavigator';
import MapStackNavigator from './MapStackNavigator';
import RankStackNavigator from './RankStackNavigator';
import HelpStackNavigator from './HelpStackNavigator';
import GreenAdventure from '../../config/greenadventure-font';

const Tab = createBottomTabNavigator<ScreensParams>();

const TabNavigator = () => {
  const {t} = useTranslation();
  const theme = useTheme();

  const tabBarStyle = (label: string, icon?: string) =>
    ({
      headerShown: false,
      tabBarLabelPosition: 'below-icon',
      tabBarIcon: ({focused}) => (
        <Icon as={GreenAdventure} name={icon} size={icon == 'Bars' ? 8 : 7} color={focused ? theme.colors.primary[500] : theme.colors.lightText[500]} />
      ),
      tabBarLabel: ({focused}) => (
        <Text bold={focused} color={focused ? theme.colors.darkText[500] : theme.colors.lightText[500]}>
          {label}
        </Text>
      ),
      tabBarStyle: {height: 68, paddingBottom: 5},
    } as BottomTabNavigationOptions);

  return (
    <Tab.Navigator initialRouteName="Map" screenOptions={{unmountOnBlur: true}}>
      <Tab.Screen name="Progress" component={ProgressStackNavigator} options={{...tabBarStyle(t('Progreso'), 'Bars')}} />
      <Tab.Screen name="Map" component={MapStackNavigator} options={{...tabBarStyle(t('Mapa'), 'Map')}} />
      <Tab.Screen name="Rank" component={RankStackNavigator} options={{...tabBarStyle(t('Ranking'), 'Trophy')}} />
      <Tab.Screen name="Help" component={HelpStackNavigator} options={{...tabBarStyle(t('Ayuda'), 'Question')}} />
    </Tab.Navigator>
  );
};

export default TabNavigator;
