import {extendTheme} from 'native-base';

export const PortAventuraTheme = extendTheme({
  fontConfig: {
    Montserrat: {
      200: {
        normal: 'Montserrat-Light',
        italic: 'Montserrat-LightItalic',
      },
      300: {
        normal: 'Montserrat-Regular',
        italic: 'Montserrat-Italic',
      },
      400: {
        normal: 'Montserrat-Medium',
        italic: 'Montserrat-MediumItalic',
      },
      500: {
        normal: 'Montserrat-Medium',
        italic: 'Montserrat-MediumItalic',
      },
      600: {
        normal: 'Montserrat-Bold',
        italic: 'Montserrat-BoldItalic',
      },
      700: {
        normal: 'Montserrat-Bold',
        italic: 'Montserrat-BoldItalic',
      },
      800: {
        normal: 'Montserrat-Black',
        italic: 'Montserrat-BlackItalic',
      },
    },
  },
  colors: {
    // TODO improve this
    primary: {
      100: '#15e260',
      200: '#17c757',
      300: '#18ad4f',
      400: '#189446',
      500: '#15803d',
      600: '#157037',
      700: '#156231',
      800: '#14542c',
      900: '#134626',
    },
    secondary: {
      100: '#141414',
      200: '#141414',
      300: '#141414',
      400: '#141414',
      500: '#141414',
      600: '#141414',
      700: '#141414',
      800: '#141414',
      900: '#141414',
    },
    error: {
      600: '#f87171',
    },
    lightGrey: {
      500: '#F4F4F4',
      600: '#e4e8e8',
      700: '#e4e8e8',
    },
    darkText: {
      500: '#141414',
    },
    lightText: {
      500: '#767675',
      600: '#616160',
    },
    darkGreen: {
      600: '#22C55E',
      700: '#16A34A',
      800: '#15803D',
      900: '#1665334',
      1000: '#14532D',
    },
    lightGreenButton: {
      600: '#DDE9DC',
      700: '#BBD8BA',
    },
  },
  components: {
    Button: {
      baseStyle: {
        rounded: 'full',
        _text: {bold: true},
      },
    },
    Input: {
      variants: {
        form: {
          backgroundColor: 'white',
          color: '#767675',
          px: '24px',
          py: '16px',
          rounded: '16px',
          fontWeight: 600,
          _web: {
            style: {
              fontSize: '16px',
            },
          },
        },
        success: {
          backgroundColor: '#B6D5B3',
          color: '#15803d',
          px: '24px',
          py: '16px',
          rounded: '16px',
          fontWeight: 600,
          _web: {
            style: {
              fontSize: '16px',
            },
          },
        },
        error: {
          backgroundColor: '#F2C2C2',
          color: '#EF4D4D',
          px: '24px',
          py: '16px',
          rounded: '16px',
          fontWeight: 600,
          _web: {
            style: {
              fontSize: '16px',
            },
          },
        },
      },
    },
    Checkbox: {
      variants: {
        form: () => ({
          rounded: '8px',
          borderWidth: '1px',
        }),
      },
    },
    Text: {
      baseStyle: {
        fontSize: 'md',
      },
    },
  },

  fonts: {
    heading: 'Montserrat',
    body: 'Montserrat',
    mono: 'Montserrat',
  },
});
