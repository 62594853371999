import update from 'immutability-helper';
import type {FC} from 'react';
import {useCallback} from 'react';

import {Card} from './Card';

export interface Item {
  id: number;
  text: string;
}

export interface ContainerState {
  cards: Item[];
}

export const Container: FC<{items: any; setItems: any; colors: string[]}> = (props: any) => {
  const {items: cards, setItems: setCards, colors} = props;

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards: Item[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as Item],
        ],
      })
    );
  }, []);

  const renderCard = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (card: {id: number; text: string}, index: number, colors: string[]) => (
      <Card colorBackground={colors[index]} key={card.id} index={index} id={card.id}
text={card.text} moveCard={moveCard} />
    ),
    []
  );

  return (
    <>
      <div> {cards.map((card: any, i: number) => renderCard(card, i, colors))}</div>
    </>
  );
};
