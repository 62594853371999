import {View, Text, Button, Stack, useTheme, Box, Image} from 'native-base';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {COOKIES_TYPES, CookiesService} from '@bri/shared-components';
import {useNavigation} from '@react-navigation/native';
import {useRecoilState} from 'recoil';
import {useGroupsService} from '../services/GroupsService';
import {getResource, Images} from '../utils/imagesUtils';
import {userTokenRecoil} from '../recoil/userToken.recoil';

const LoginVisitorScreen = () => {
  const {t} = useTranslation();
  const [, setIsLoading] = useState(false);

  const theme = useTheme();
  const groupsService = useGroupsService();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<any>();
  const [, setUserToken] = useRecoilState(userTokenRecoil);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    groupsService
      .getUsername()
      .response(res => {
        setUserName(res.username);
      })
      .error(() => {});
  }, []);

  const OnStartGincama = () => {
    setIsLoading(true);
    groupsService
      .loginVisitor({username: userName})
      .apiResponse(async res => {
        if (res.response) {
          const jwt = res.response;
          await CookiesService.setType(COOKIES_TYPES.TECHNICAL, 'access_token', jwt);
          setUserToken({teamName: ''});
        } else {
          navigation.navigate('ErrorLoginScreen');
        }
      })
      .response(() => {})
      .error(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <View flex={1}>
      <Box flex={1} mb={-6}>
        <Image source={getResource(Images.peopleWithWoody2x)} resizeMode="cover" h="100%" w="100%" />
      </Box>

      <Stack direction="column" backgroundColor="white" borderTopRadius="2xl" overflow="hidden"
p={6} space={6}>
        <Text fontWeight={700} fontSize="xl" color={theme.colors.darkText[500]}>
          {`🔐 ${t('Tu usuario para jugar')}`}
        </Text>

        <Text>{t('Te hemos asignado este nombre para la yincana. Recuerda que podrás ver como avanzas en el ranking.')}</Text>

        <Box bgColor="lightGrey.500" padding={4} rounded="xl" flexDirection="row"
justifyContent="center" alignItems="center">
          <Text textAlign="center" fontWeight={600} color={theme.colors.lightText[600]}>
            {userName}
          </Text>
        </Box>

        <Button colorScheme="primary" onPress={OnStartGincama}>
          {t('Comenzar')}
        </Button>
      </Stack>
    </View>
  );
};

export default LoginVisitorScreen;
