import React from 'react';
import * as Update from 'expo-updates';
import {CookiesConsent} from '../components/CookiesConsent';
import CommonLayout from '../layouts/CommonLayout';

const CookiesConsentScreen = () => (
  <CommonLayout>
    <CookiesConsent
      showRemoveAllCookiesButton={false}
      onRemoveAllCookies={async () => {
        await Update.reloadAsync();
      }}
    />
  </CommonLayout>
);

export default CookiesConsentScreen;
