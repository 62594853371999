import {theme, Text, VStack, HStack, Divider} from 'native-base';
import {useTranslation} from 'react-i18next';
import PointScore from './PointScore';
import PercentBar from './PercentBar';

type Props = {
  points?: number;
  numOds: number;
};

const PuntosYods = (props: Props) => {
  const {t} = useTranslation();
  const totalOds = 3;
  const percentProgress = props.numOds / totalOds;

  return (
    <VStack p={5} bg="white" space="lg" borderRadius="xl">
      <HStack alignItems="center" justifyContent="space-between">
        <Text color={theme.colors.darkText[500]}>{`${t('Puntos Conseguidos')}`}</Text>
        <PointScore points={props.points} backgroundColor="#DDD" digitsToShow={4} />
      </HStack>
      <Divider color="#DFDFDF" height={0.25} />
      <VStack space="sm">
        <HStack alignItems="center" justifyContent="space-between">
          <Text color={theme.colors.darkText[500]}>{t('Pruebas ODS')}</Text>
          <Text color={theme.colors.darkText[500]}>{props.numOds}</Text>
        </HStack>
        <PercentBar percent={percentProgress * 100} />
      </VStack>
    </VStack>
  );
};

export default PuntosYods;
