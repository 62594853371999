import {useRecoilState} from 'recoil';
import {useNavigation} from '@react-navigation/native';
import {TOAST_STATUS, TOAST_VARIANT, useSharedToast} from '@bri/shared-components';
import {useTranslation} from 'react-i18next';
import {progressRecoil} from '../recoil/progress.recoil';
import {challengesRecoil} from '../recoil/challenges.recoil';

export const useChallengeNavigation = () => {
  const [huntProgress] = useRecoilState(progressRecoil);
  const [challenges] = useRecoilState(challengesRecoil);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<any>();
  const sharedToast = useSharedToast();
  const {t} = useTranslation();

  /**
   * Navigates to the corresponding screen given the corresponding parameters
   */
  const challengeNavigation = (id: string, scanned: boolean = false) => {
    const groupChallenge = huntProgress?.progress.challenges.find(gc => gc.challenge_id === id);
    const challengeData = challenges?.find(c => c['@id'] === id);

    if (!huntProgress || !challengeData) {
      console.log('hola');
      return;
    }

    const odsCompleted = huntProgress?.progress.challenges.filter(c => c.od_id === challengeData?.od_id && !!c.end).length || 0;

    if (!groupChallenge || !groupChallenge.end) {
      if (challengeData?.scan && !scanned) {
        navigation.navigate('Scan', {id});
      } else if (challengeData.hidden && odsCompleted < 2) {
        sharedToast({
          title: t('Prueba bloqueada'),
          description: t(``),
          status: TOAST_STATUS.ERROR,
          variant: TOAST_VARIANT.SUBTLE,
        });
      } else {
        navigation.navigate('Info', {id});
      }
    } else if (groupChallenge.isCorrectAnswer !== undefined) {
      navigation.navigate('ChallengeCompleted', {
        id,
        obtainedPoints: groupChallenge.points,
        numOds: huntProgress?.progress.challenges.filter(c => c.od_id === groupChallenge?.od_id && !!c.end).length,
        launchRoulette: false,
        challengeSuccesFull: groupChallenge?.isCorrectAnswer,
      });
    }
  };

  return {challengeNavigation};
};
