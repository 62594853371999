import {Progress} from 'native-base';
import {ColorType} from 'native-base/lib/typescript/components/types';
import {FC} from 'react';

type Props = {
  percent: number;
  color?: ColorType;
  bgColor?: ColorType;
};

const DEFAULT_COLOR = 'primary.500';
const DEFAULT_BG_COLOR = '#DFDFDF';

const fixPercentNumber = (percent: number) => Math.round(Math.max(0, Math.min(100, percent)));

const PercentBar: FC<Props> = ({percent, color = DEFAULT_COLOR, bgColor = DEFAULT_BG_COLOR}) => (
  <Progress value={fixPercentNumber(percent)} bgColor={bgColor} _filledTrack={{bg: color}} />
);

export default PercentBar;
