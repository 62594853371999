import {ScrollView, View, Stack, Text, useTheme, Button, HStack, Icon} from 'native-base';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {huntProgress as HuntProgress} from '@bri/portaventura-core';
import {useNavigation} from '@react-navigation/native';
import PointScore from '../components/PointScore';
import CompletionBar from '../components/CompletionBar';
import {useGroupsService} from '../services/GroupsService';
import GreenAdventure from '../config/greenadventure-font';
import ScrollBadges from '../components/progress/ScrollBadges';
import ScrollODS, {ScrollODSProps} from '../components/progress/scrollODS';

const ProgressScreen = () => {
  const theme = useTheme();
  const navigation = useNavigation<any>();
  const {t} = useTranslation();
  const groupsService = useGroupsService();
  const [progressPoints, setProgressPoints] = useState(0);
  const [huntProgress, setHuntProgress] = useState<HuntProgress>({
    points: 0,
    progress: {challenges: [], badges: [], points: 0, start: new Date(), end: new Date(), playedRouletteIds: []},
  });

  useEffect(() => {
    groupsService
      .huntProgress()
      .response(res => {
        if (res && res.points !== undefined) {
          setProgressPoints(res.points);
          setHuntProgress(res);
        }
      })
      .error(() => {
        console.log('error Use effect Progress Screen ');
        // Handle error if needed
      });
  }, []);

  const goToPremios = () => {
    navigation.navigate('Premios');
  };

  const totalFinished = huntProgress.progress.challenges.filter(challenge => challenge.end).length;
  const percentProgress = (totalFinished / 15) * 100;

  const scrollODSInfo: ScrollODSProps[] = [
    {
      titleIcon: '💧',
      titleText: t('ODS 6: Agua Limpia'),
      items: [
        {challengeArea: t('Far West'), challengeId: '1', challengeType: t('CheckBox')},
        {challengeArea: t('Far West'), challengeId: '2', challengeType: t('Arrange')},
        {challengeArea: t('Far West'), challengeId: '3', challengeType: t('Photo')},
      ],
    },
    {
      titleIcon: '☀️',
      titleText: t('ODS 7: Energía Asequible'),
      items: [
        {challengeArea: t('China'), challengeId: '4', challengeType: t('CheckBox')},
        {challengeArea: t('China'), challengeId: '5', challengeType: t('Question')},
        {challengeArea: t('China'), challengeId: '6', challengeType: t('Enigma')},
      ],
    },
    {
      titleIcon: '📊',
      titleText: t('ODS 8: Crecimiento Económico'),
      items: [
        {challengeArea: t('Medierrània'), challengeId: '7', challengeType: t('CheckBox')},
        {challengeArea: t('Medierrània'), challengeId: '8', challengeType: t('Enigma')},
        {challengeArea: t('Medierrània'), challengeId: '9', challengeType: t('Drag&Drop')},
      ],
    },
    {
      titleIcon: '♾️',
      titleText: t('ODS 12: Producción y Consumo Responsable'),
      items: [
        {challengeArea: t('México'), challengeId: '10', challengeType: t('CheckBox')},
        {challengeArea: t('México'), challengeId: '11', challengeType: t('Drag&Drop')},
        {challengeArea: t('México'), challengeId: '12', challengeType: t('Photo')},
      ],
    },
    {
      titleIcon: '🌍',
      titleText: t('ODS 13: Acción por el Clima'),
      items: [
        {challengeArea: t('Polynesia'), challengeId: '13', challengeType: t('CheckBox')},
        {challengeArea: t('Polynesia'), challengeId: '14', challengeType: t('CheckBox')},
        {challengeArea: t('Polynesia'), challengeId: '15', challengeType: t('Enigma')},
      ],
    },
  ];

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <Stack direction="column" p={6} space={6} flex={1} overflow="visible">
        <Button colorScheme="lightGreenButton" rounded="2xl" justifyContent="start" onPress={goToPremios} p={4}>
          <HStack alignItems="center" space={2}>
            <Icon as={GreenAdventure} name="HandsClapping" size={30} color="primary.500" />
            <Text fontWeight={700} fontSize="xl" color="primary.500">
              {t('Premios Conseguidos')}
            </Text>
          </HStack>
        </Button>

        <Text fontWeight={700} fontSize="xl" color={theme.colors.darkText[500]}>
          {t('Puntos Obtenidos')}
        </Text>

        <HStack bgColor="white" rounded="2xl" alignItems="center" p={6} space={6}>
          <View>
            <PointScore points={progressPoints} digitsToShow={5} />
          </View>
          <View flex={1}>
            <Text>
              {t('Habeis completado')} {totalFinished} {t('de las 15 pruebas')}
            </Text>
            <CompletionBar progress={percentProgress} />
          </View>
        </HStack>

        <Text fontWeight={700} fontSize="xl" flex={1} color={theme.colors.darkText[500]}>
          {`🎖️${t(' Colección de Insignias')}`}
        </Text>
        <ScrollBadges badges={['6', '7', '8', '12', '13']} badgesObtained={huntProgress.progress.badges} />

        {scrollODSInfo.map(info => (
          <ScrollODS titleIcon={info.titleIcon} titleText={info.titleText} items={info.items} />
        ))}
      </Stack>
    </ScrollView>
  );
};

export default ProgressScreen;
