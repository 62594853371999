import {Dimensions} from 'react-native';
import {CSSProperties} from 'react';
import {MAX_WINDOW_WIDTH, WINDOW_HEIGHT, WINDOW_WIDTH} from '../utils/mixins';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Props = {};

const IPhoneOverlayStyle: CSSProperties = {
  position: 'absolute',
  margin: 'auto',
  maxHeight: WINDOW_HEIGHT,
  maxWidth: WINDOW_WIDTH,
  height: '100%',
  width: WINDOW_WIDTH,
  borderRadius: 40,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  boxShadow: '0px 0px 0px 16px #1f1f1f, 0px 0px 0px 1px #191919, 0px 0px 0px 1px #111',
  pointerEvents: 'none',
};

const IPhoneOverlay = () => {
  const {width} = Dimensions.get('window');
  if (width <= MAX_WINDOW_WIDTH) return null;
  return <div style={IPhoneOverlayStyle} />;
};

export default IPhoneOverlay;
