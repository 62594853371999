import React, {useEffect, useState} from 'react';

import CommonLayout from '../../layouts/CommonLayout';
import i18n from '../../../i18n';
import Pdf from '../../components/Pdf';

import TermsAndConditionsES from '../../assets/pdf/termsAndConditions/TERMINOS_Y_CONDICIONES_DE_USO_es.pdf';
import TermsAndConditionsCAT from '../../assets/pdf/termsAndConditions/TERMINOS_Y_CONDICIONES_DE_USO_cat.pdf';
import TermsAndConditionsEN from '../../assets/pdf/termsAndConditions/TERMINOS_Y_CONDICIONES_DE_USO_en.pdf';
import TermsAndConditionsFR from '../../assets/pdf/termsAndConditions/TERMINOS_Y_CONDICIONES_DE_USO_fr.pdf';

const selectPdf = (lang: string) => {
  switch (lang) {
    case 'es':
      return TermsAndConditionsES;
    case 'en':
      return TermsAndConditionsEN;
    case 'ca':
      return TermsAndConditionsCAT;
    case 'fr':
      return TermsAndConditionsFR;
    default:
      return TermsAndConditionsES;
  }
};

const TermsAndConditionsScreen = () => {
  const [lang, setLang] = useState<string>('');

  useEffect(() => {
    const {language} = i18n;
    setLang(language);
  }, [i18n.language]);

  const translatedPdf = selectPdf(lang);

  return (
    <CommonLayout>
      <Pdf url={translatedPdf} />
    </CommonLayout>
  );
};

export default TermsAndConditionsScreen;
