import {FC} from 'react';
import {Button, ScrollView, useTheme, Text, FormControl, Input, VStack, View} from 'native-base';
import {Controller, useForm} from 'react-hook-form';
import {DefaultTFuncReturn} from 'i18next';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import Equation from './Equation';
import {NavigationInfo} from '../../../utils/types';

type EnigmaProps = {
  translatedTitle?: DefaultTFuncReturn;
  translatedDescription?: DefaultTFuncReturn;
  translatedFormula?: string;
  translatedButtonText?: DefaultTFuncReturn;
  isButtonLoading?: boolean;
  buttonAction?: (solution: NavigationInfo) => void;
  buttonSubmitAnswer: (answ: {solution: string | string[]; type: string}) => void;
  colors: string[];
  buttonReadyNavigation: boolean;
  navigationInfo: NavigationInfo;
  rightAnswer: string;
  translatedSolutionText?: string;
};

type FormFields = {
  enigmaAnswer: number;
};

const Challenge: FC<EnigmaProps> = ({
  translatedTitle,
  translatedDescription,
  translatedButtonText,
  translatedFormula,
  isButtonLoading,
  buttonAction,
  buttonSubmitAnswer,
  colors,
  buttonReadyNavigation,
  rightAnswer,
  navigationInfo,
  translatedSolutionText,
}) => {
  const theme = useTheme();
  const {t} = useTranslation();

  const FormSchema = Yup.object().shape({
    enigmaAnswer: Yup.number()
      .required(t('Required')!)
      .typeError(t('Must be a number')!)
      .transform((_o, v) => parseInt(v.replace(',', '.'), 10)),
  });

  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm<FormFields>({resolver: yupResolver(FormSchema)});

  return (
    <ScrollView showsHorizontalScrollIndicator={false}>
      <VStack space="lg">
        <VStack space="lg">
          <VStack space="md">
            <Text color={theme.colors.lightText[700]} fontSize="2xl" fontWeight="bolder">
              {translatedTitle}
            </Text>
            <Text color={theme.colors.lightText[500]} fontSize="md" fontWeight={500} lineHeight={24}>
              {translatedDescription}
            </Text>
          </VStack>
          <Equation formula={translatedFormula || ''} />
        </VStack>

        <VStack space="sm">
          <FormControl isInvalid={'enigmaAnswer' in errors}>
            <Controller
              name="enigmaAnswer"
              control={control}
              render={({field}) => (
                <Input
                  margin="1px"
                  keyboardType="decimal-pad"
                  placeholder={t('Respuesta')!}
                  style={{backgroundColor: colors[0].toString()}}
                  variant={'enigmaAnswer' in errors ? 'error' : 'form'}
                  {...field}
                  value={field.value?.toString()}
                />
              )}
            />
            <View>{errors.enigmaAnswer && <FormControl.ErrorMessage>{errors.enigmaAnswer?.message}</FormControl.ErrorMessage>}</View>
          </FormControl>

          {rightAnswer && (
            <Text color={theme.colors.lightText[500]} fontSize="md">
              {t('La respuesta correcta es:')} {translatedSolutionText}
            </Text>
          )}

          {buttonAction && !buttonReadyNavigation && (
            <Button
              isLoading={isButtonLoading}
              onPress={handleSubmit(data => buttonSubmitAnswer({solution: data.enigmaAnswer.toString(), type: 'enigma'}))}
              bgColor="primary.500">
              {translatedButtonText}
            </Button>
          )}
          {buttonAction && buttonReadyNavigation && (
            <Button isLoading={isButtonLoading} onPress={() => buttonAction(navigationInfo)} bgColor="primary.500">
              {t('Continuar')}
            </Button>
          )}
        </VStack>
      </VStack>
    </ScrollView>
  );
};

export default Challenge;
