import React, {useEffect, useState} from 'react';
import {Player, Controls, PlayerEvent} from '@lottiefiles/react-lottie-player';
import {AnimationItem} from 'lottie-web';

interface Props {
  playerRef: React.RefObject<Player>;
  showControls: boolean;
  buttonsControl: never[];
  controlsDark: boolean;
  hasFinishedLoadingBlockchain: boolean;
  rouletteStopCallback: () => void;
}

export const PlayerLottie = ({buttonsControl, controlsDark, playerRef, showControls, hasFinishedLoadingBlockchain, rouletteStopCallback}: Props) => {
  const [lottie, setLottie] = useState<AnimationItem>();
  const [loaded, setLoaded] = useState(false);
  const [looping, setLooping] = useState(true);

  const rouletteStartLoopFrame = 43;
  const rouletteEndLoopFrame = 50;
  const rouletteFinalFrame = 213;

  const handleLottieEvent = (event: PlayerEvent) => {
    if (event === PlayerEvent.Load) setLoaded(true);

    if (event === PlayerEvent.Play) {
      if (lottie && loaded) {
        // Play first part of animation then loop part of it
        lottie.playSegments(
          [
            [0, rouletteStartLoopFrame],
            [rouletteStartLoopFrame, rouletteEndLoopFrame],
          ],
          true
        );
      }
    }

    if (event === PlayerEvent.Complete) {
      rouletteStopCallback();
    }
  };

  useEffect(() => {
    if (lottie && loaded && hasFinishedLoadingBlockchain) {
      setLooping(false);
      lottie.playSegments([rouletteEndLoopFrame, rouletteFinalFrame], false);
      lottie.setLoop(false);
    }
  }, [hasFinishedLoadingBlockchain]);

  return (
    <div className="container-player">
      <Player src={require('../../assets/ruleta/rouletteWheelJson.json')} className="player" ref={playerRef} lottieRef={setLottie}
loop={looping} onEvent={handleLottieEvent}>
        <Controls visible={showControls} buttons={buttonsControl} darkTheme={controlsDark} />
      </Player>
    </div>
  );
};
