import {NativeStackNavigationOptions} from '@react-navigation/native-stack';
import {HStack} from 'native-base';
import {useRecoilState} from 'recoil';
import LanguageSelector from '../components/LanguageSelector';
import PointScore from '../components/PointScore';
import {userTokenRecoil} from '../recoil/userToken.recoil';
import {progressRecoil} from '../recoil/progress.recoil';

export const useHeaderStyle = () => {
  const [userToken] = useRecoilState(userTokenRecoil);
  const [progress] = useRecoilState(progressRecoil);
  const isAuthenticated = !!userToken;

  const getHeader = (hidePointScore: boolean = false) =>
    ({
      headerShown: true,
      headerTitle: '',
      headerRight: () => (
        <HStack pr={3} space="6px" alignItems="center">
          <LanguageSelector />
          {isAuthenticated && !hidePointScore && <PointScore points={progress?.points || 0} digitsToShow={5} />}
        </HStack>
      ),
    } as NativeStackNavigationOptions);

  const header = getHeader();
  const headerWithoutScore = getHeader(true);

  return {header, headerWithoutScore};
};
