import React, {FC, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {getWallet} from '@bri/shared-components';
import {useNavigation} from '@react-navigation/native';
import {ScreensParams} from 'src/navigators/screens';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {USER_TYPE} from '@bri/portaventura-core';
import InfoPannel from '../components/InfoPannel';
import {setUserType} from '../utils/user';

type Props = NativeStackScreenProps<ScreensParams, 'OnBoarding'>;

const OnboardingScreen: FC<Props> = props => {
  const {t} = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<any>();
  const {userType} = props.route.params;

  useEffect(() => {
    setUserType(userType);
    getWallet().then(console.log);
  }, []);

  const loginByUserType = () => (userType === USER_TYPE.VISITOR ? 'LoginVisitor' : 'Login');

  return (
    <>
      <InfoPannel
        imageHeight={250}
        infoTotal={[
          {
            title: `🧭 ${t('¡Te damos la bienvenida a The Green Adventure!')}`,
            points: [
              t(
                'Recorre el parque, supera divertidos desafíos y descubre cómo cuidar nuestro planeta de manera interactiva. Aprende sobre reciclaje, energía renovable, biodiversidad y mucho más, mientras te diviertes.'
              ),
              t('Resuelve 15 pruebas, 3 por cada uno de los 5 Objetivos de Desarrollo Sostenible.'),
              t('¡Es para todos los públicos y totalmente gratuita! ¡Únete!'),
            ],
            image: 'onBoarding1.jpg',
          },
          {
            title: `🗺️ ${t('¡A explorar!')}`,
            points: [
              t('Usa el mapa para encontrar los códigos QR y resuelve las pruebas. Si ves un QR en alguna parte del parque, ¡corre hacia él como si fueras un cazatesoros!'),
              t('Al escanearlo, desbloquearás una nueva prueba para superar.'),
              t('Recuerda, al principio tendrás 5 pruebas ya desbloqueadas de cada ODS.'),
            ],
            image: 'onBoarding2.jpg',
          },
          {
            title: `🎁 ${t('¡Premios para todos los aventureros!')}`,
            points: [
              t('Esta aventura tiene premios.'),
              t('Al completar cada bloque de un Objetivo de Desarrollo Sostenible, podrás girar la ruleta o abrir un cofre y descubrir qué premios increíbles os esperan!'),
              t('¡Que comience la diversión!'),
            ],
            image: 'onBoarding3.jpg',
          },
          {
            title: `🏆 ${t('¡Consigue la mejor puntuación!')}`,
            points: [
              t('Los puntos acumulados se mostrarán en un ranking global. Cuanto menos tiempo tardes en superar las pruebas, mayor puntuación obtendrás.'),
              t('¿Dónde estarás en la tabla de clasificación? Al final de la temporada, la mejor puntuación conseguirá un premio de primera categoría. ¡A por todas!'),
              t('¡Preparados, listos, ya! ¡Que empiece la yincana más épica y sostenible de todas! 🌍🍃'),
            ],
            image: 'onBoarding4.jpg',
            buttonText: t('Empezar').toString(),
            buttonAction: () => {
              navigation.navigate(loginByUserType());
            },
          },
        ]}
      />
    </>
  );
};

export default OnboardingScreen;
