import React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {ScreensParams} from '../screens';
import {useHeaderStyle} from '../../hooks/HeaderHook';
import HelpScreen from '../../screens/HelpScreen';

const Stack = createNativeStackNavigator<ScreensParams>();

const HelpStackNavigator = () => {
  const {header} = useHeaderStyle();

  return (
    <Stack.Navigator screenOptions={header}>
      <Stack.Screen name="Help" component={HelpScreen} />
    </Stack.Navigator>
  );
};

export default HelpStackNavigator;
