import {VStack, Image, Box, View, Text} from 'native-base';
import {t} from 'i18next';
import {ImageStyle, TouchableOpacity} from 'react-native';
import {useRecoilState} from 'recoil';
import React, {useEffect, useState} from 'react';
import {PortAventuraArea} from '../../utils/constants';
import {progressRecoil} from '../../recoil/progress.recoil';
import {Images} from '../../utils/imagesUtils';
import {useChallengeNavigation} from '../../hooks/ChallengeNavigationHook';

export type ScrollODSItemProps = {
  challengeId: string;
  challengeArea: PortAventuraArea;
  challengeType: string;
};

const ScrollODSItem: React.FC<ScrollODSItemProps> = props => {
  const [progress] = useRecoilState(progressRecoil);
  const [points, setPoints] = useState<number | undefined>(undefined);
  const {challengeNavigation} = useChallengeNavigation();

  useEffect(() => {
    const challenge = progress?.progress.challenges.filter(c => c.end && c.challenge_id === props.challengeId);
    if (!!challenge && challenge.length === 1) {
      setPoints(challenge[0].points);
    }
  }, [progress]);

  const getPortAventuraAreaColor = (area: PortAventuraArea): string => {
    let color: string = 'coral';

    if (area === t('Far West')) {
      color = '#854E0E';
    } else if (area === t('China')) {
      color = '#ED4542';
    } else if (area === t('Medierrània')) {
      color = '#11A5E7';
    } else if (area === t('México')) {
      color = '#F49E0B';
    } else if (area === t('Polynesia')) {
      color = '#12B8AA';
    }

    return color;
  };

  const getPortAventuraAreaTranslation = (area: PortAventuraArea): string => t(area);

  return (
    <TouchableOpacity
      onPress={() => {
        challengeNavigation(props.challengeId);
      }}>
      <VStack space={4}>
        <View borderRadius={16} width="224px" height="152px" overflow="hidden">
          <Image
            source={(Images as any)[`imageBackOd${props.challengeId}`]}
            alt="Background Image"
            resizeMode="cover"
            width="224px"
            height="192px"
            marginTop="-40px"
            style={{filter: points !== undefined ? 'none' : 'grayscale(100%)'} as ImageStyle}
          />
        </View>

        <View flexDir="row">
          <Box bgColor={getPortAventuraAreaColor(props.challengeArea)} rounded={20} padding={2} px={4}>
            <Text color="white" fontWeight={700}>
              {getPortAventuraAreaTranslation(props.challengeArea)}
            </Text>
          </Box>
        </View>

        <VStack>
          <Text bold>{t(props.challengeType)}</Text>
          <Text>{points !== undefined ? `+${points} ${t('puntos')}` : ' '}</Text>
        </VStack>
      </VStack>
    </TouchableOpacity>
  );
};

export default ScrollODSItem;
