const Configuration = {
  client: {
    baseUrl: '',
  },
  baseService: {
    baseUrl: 'http://localhost:8080',
  },
  googleMaps: {
    apiKey: 'AIzaSyAQXfpYTvL3NuLvRy9PqMUxUiaeui8L-NE',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyC4WSz_3pwOMZAOENoLsLE-WGDj0fqDG7E',
    authDomain: 'pa-greenadventure.firebaseapp.com',
    projectId: 'pa-greenadventure',
    storageBucket: 'pa-greenadventure.appspot.com',
    messagingSenderId: '149159561402',
    appId: '1:149159561402:web:daef95e1c744fb42b25a83',
    measurementId: 'G-SX9E9FMQE8',
  },
};

// Export configuration
export default Configuration;
