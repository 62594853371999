import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {FC} from 'react';
import {ImageBackground} from 'react-native';
import {Button, Text, VStack, View} from 'native-base';
import {useTranslation} from 'react-i18next';
import {useNavigation} from '@react-navigation/native';
import {ScreensParams} from '../navigators/screens';
import {Images, getResource} from '../utils/imagesUtils';
import PointScore from '../components/PointScore';

type Props = NativeStackScreenProps<ScreensParams, 'GameCompleted'>;

const GameCompletedScreen: FC<Props> = ({route}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<any>();

  const image = getResource(Images.gameCompleted);
  const {t} = useTranslation();

  const {points, numChallenges} = route.params;

  const seeFinalPrize = () => navigation.navigate('RoulettePlay', {tiradaExtra: true, numChallenges});

  return (
    <ImageBackground source={image} style={{flex: 1}}>
      <View position="absolute" top={0} left={0} right={0} bottom={0} backgroundColor="rgba(0, 0, 0, 0.25)" />
      <VStack flex={1} paddingX={12} paddingY={16} space="xl">
        <VStack flex={1} space="lg" alignSelf="center">
          <Text color="white" fontWeight={700} fontSize="24px" lineHeight="27px"
textAlign="center">
            🎉 {t('¡Habéis completado toda gymkana!')}
          </Text>
          <Text color="white" fontWeight={500} fontSize="20px" lineHeight="25px"
textAlign="center">
            {t('Habéis sumado 200 extras!')}
          </Text>
        </VStack>
        <VStack flex={1} alignSelf="stretch" space="lg">
          <PointScore points={points} digitsToShow={3} backgroundColor="white" flex height="60px" />
          <Button onPress={seeFinalPrize} backgroundColor="transparent" borderColor="white" borderWidth={2} size="lg" width="100%" nativeID="view-prize-btn">
            {t('Ver Premio Final')}
          </Button>
        </VStack>
      </VStack>
    </ImageBackground>
  );
};

export default GameCompletedScreen;
