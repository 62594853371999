import {ScrollView, Image} from 'native-base';
import React, {useEffect, useState} from 'react';
import {ImageStyle} from 'react-native';
import {Images} from '../../utils/imagesUtils';
import i18n from '../../../i18n';

type ScrollBadgesProps = {
  badges?: string[];
  badgesObtained?: string[];
};

const ScrollBadges: React.FC<ScrollBadgesProps> = props => {
  const [lang, setLang] = useState<string>('es');

  useEffect(() => {
    const {language} = i18n;
    setLang(language);
  }, [i18n.language]);

  return (
    <ScrollView horizontal style={{marginHorizontal: -24}} contentContainerStyle={{marginHorizontal: 24}} showsHorizontalScrollIndicator>
      {props.badges?.map(badge => {
        console.log('badge', `badgeOd${badge}_${lang}`);

        return (
          <Image
            source={Images[`badgeOd${badge}_${lang}` as keyof typeof Images]}
            alt="Background Image"
            key={`badgeOd${badge}_${lang}`}
            style={{width: 150, height: 150, borderRadius: 10, filter: props.badgesObtained?.includes(badge) ? 'none' : 'grayscale(100%)'} as ImageStyle}
          />
        );
      })}
    </ScrollView>
  );
};

export default ScrollBadges;
