import {useNavigation} from '@react-navigation/native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {Button, View, Text, useTheme, VStack} from 'native-base';
import React, {FC, useEffect, useState} from 'react';
import {Image} from 'react-native';
import {useTranslation} from 'react-i18next';
import {ScreensParams} from '../navigators/screens';
import PuntosYods from '../components/PuntosYods';
import PointScore from '../components/PointScore';
import {WINDOW_HEIGHT, WINDOW_WIDTH} from '../utils/mixins';
import {Images} from '../utils/imagesUtils';
import ImageAndBadgeLayout from '../layouts/ImageAndBadgeLayout';
import {picPath} from '../utils/constants';
import i18n from '../../i18n';

type Props = NativeStackScreenProps<ScreensParams, 'OdsCompleted'> & {
  obtainedPoints?: number;
  obtainedPointsOds?: number;
};

const OdsCompleted: FC<Props> = props => {
  const points = Number(props.route.params.obtainedPoints) ?? 0;
  const pointsOds = props.obtainedPointsOds ?? 150;
  const challengeId = props.route.params.id ?? '1';
  const numChallenges = props.route.params.numChallenges ?? 1;
  const [lang, setLang] = useState<string>('');

  useEffect(() => {
    const {language} = i18n;
    setLang(language);
  }, [i18n.language]);

  let launchRoulette = props.route.params.launchRoulette ? props.route.params.launchRoulette : false;
  launchRoulette = typeof launchRoulette === 'string' ? launchRoulette === 'true' : launchRoulette;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [badge, , odsId] = picPath(challengeId, lang);

  console.log('badge', badge);
  console.log('odsId', odsId);

  const navigation = useNavigation<any>();
  const theme = useTheme();
  const {t} = useTranslation();

  return (
    <>
      <ImageAndBadgeLayout challengeId={challengeId} hideBadge language={lang}>
        <>
          {/* the images with absolute position */}
          <View flex={1} paddingBottom={250}>
            <Image
              source={Images.cofre}
              alt="cofre"
              style={{width: WINDOW_WIDTH * 0.45, height: WINDOW_HEIGHT * 0.24, position: 'absolute', top: WINDOW_HEIGHT * 0.05, left: 50}}
            />
            <View style={{width: 100, height: 170, position: 'absolute', top: WINDOW_HEIGHT * 0.11, left: 190}}>
              <PointScore points={pointsOds} backgroundColor="#DDD" digitsToShow={4} />
            </View>
            <Image source={badge} alt="badge" style={{width: 80, height: 80, position: 'absolute', top: WINDOW_HEIGHT * 0.03, left: WINDOW_WIDTH * 0.5}} />
          </View>

          <VStack space={6}>
            <VStack space={2}>
              {/* title */}
              <Text fontWeight={700} fontSize="xl" color={theme.colors.darkText[500]}>
                {`🎉${t('¡Enhorabuena, ODS ')} ${odsId} ${t(' completada y obtienes una nueva insignia!')}`}
              </Text>
              {/* description */}
              <Text fontWeight={300} color={theme.colors.darkText[500]}>
                {`${t('Habéis sumado')} ${points} ${t('puntos por completar la prueba y ')} ${pointsOds} ${t('puntos al desbloquear la ODS.')}`}
              </Text>
            </VStack>

            {/* component */}

            <PuntosYods points={points + pointsOds} numOds={3} />

            {/* Sub text */}

            <View alignContent="center">
              <Text fontWeight={600} color="black" textAlign="center">
                {`${t('¡Aquí tienes tu recompensa por completar ODS ')} ${odsId} ${t('!')}`}
              </Text>
            </View>

            {/* Button */}
            <View>
              {!launchRoulette && (
                <Button
                  marginLeft={12}
                  marginRight={12}
                  onPress={() => {
                    navigation.navigate('Map');
                  }}>
                  {t('Volver al mapa')}
                </Button>
              )}
              {launchRoulette && (
                <Button
                  marginLeft={12}
                  marginRight={12}
                  onPress={() => {
                    navigation.navigate('RoulettePlay', {numChallenges});
                  }}>
                  {t('Girar ruleta')}
                </Button>
              )}
            </View>
          </VStack>
        </>
      </ImageAndBadgeLayout>
    </>
  );
};

export default OdsCompleted;
