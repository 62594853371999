import {atom} from 'recoil';

type UserToken = {
  teamName: string;
};

export const userTokenRecoil = atom<UserToken | null>({
  key: 'userToken',
  default: null,
});
