import React, {useEffect} from 'react';
import {Button, Text, Column, ScrollView, useTheme} from 'native-base';
import {useTranslation} from 'react-i18next';
import {COOKIES_TYPES, CookiesService} from '@bri/shared-components';
import {useNavigation} from '@react-navigation/native';
import {useBottomModal} from '../hooks/BottomModal/BottomModalContext';

// Typing

type Props = {
  technicalCookieEnabled?: () => void;
  personalizationCookieEnabled?: () => void;
  analysisCookieEnabled?: () => void;
  advertisingCookieEnabled?: () => void;
  behaviourAdvertisingCookieEnabled?: () => void;
  onAcceptPres?: () => void;
};

export function CookiesBanner(props: Props) {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef(null);
  const theme = useTheme();
  // TODO double check any
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const navigation = useNavigation<any>();
  const {showBottomModal, hideBottomModal} = useBottomModal();

  // let iOSPermissionsRequests = 0;

  React.useEffect(() => {
    const fetchData = async () => {
      setIsOpen(!(await CookiesService.hasKey(COOKIES_TYPES.TECHNICAL, COOKIES_TYPES.TECHNICAL)) && !(navigation.getCurrentRoute()?.name == 'Cookies'));
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
    // this.props.loadCookiesInfo();
    // if (this.props.show && !isExpo && isiOSApp) {
    //   _requestIOSPermissions();
    // } else if (!isExpo && isiOSApp) {
    //   this.watch('show', async (newValue, oldValue) => {
    //     if (!newValue) return;
    //     this._requestIOSPermissions();
    //   });
    // }
  });

  // const _requestIOSPermissions = async () => {
  //   if (iOSPermissionsRequests >= 5 || !isOpen) return;
  //   iOSPermissionsRequests += 1;
  //
  //   const {status} = await requestTrackingPermissionsAsync();
  //   if (status === PermissionStatus.GRANTED) {
  //     await _createCookies(true);
  //   } else if (status === PermissionStatus.DENIED) {
  //     await _createCookies(false);
  //   } else {
  //     _requestIOSPermissions();
  //   }
  // };

  async function _createCookies(acceptNonEssential: boolean) {
    const acceptedNonEssential = acceptNonEssential ? 'true' : 'false';

    // Essential cookies
    await CookiesService.setType(COOKIES_TYPES.TECHNICAL, COOKIES_TYPES.TECHNICAL, true);

    // Non essential cookies
    await CookiesService.setType(COOKIES_TYPES.PERSONALISATION, COOKIES_TYPES.PERSONALISATION, acceptedNonEssential);
    await CookiesService.setType(COOKIES_TYPES.ADVERTISING, COOKIES_TYPES.ADVERTISING, acceptedNonEssential);
    await CookiesService.setType(COOKIES_TYPES.ANALYSIS, COOKIES_TYPES.ANALYSIS, acceptedNonEssential);
    await CookiesService.setType(COOKIES_TYPES.BEHAVIOURAL_ADVERTISING, COOKIES_TYPES.BEHAVIOURAL_ADVERTISING, acceptedNonEssential);

    props.technicalCookieEnabled && props.technicalCookieEnabled();

    if (acceptNonEssential) {
      props.personalizationCookieEnabled && props.personalizationCookieEnabled();
      props.advertisingCookieEnabled && props.advertisingCookieEnabled();
      props.analysisCookieEnabled && props.analysisCookieEnabled();
      props.behaviourAdvertisingCookieEnabled && props.behaviourAdvertisingCookieEnabled();
    }
  }

  const createCookiesAndAcceptAll = async () => {
    await _createCookies(true);
    onClose();
  };

  /* const createCookiesAndAcceptEssential = async () => {
    await _createCookies(false);
    onClose();
  }; */

  const refuseCookies = async () => {
    await _createCookies(false);
    onClose();
  };

  const navigateToCookiesPolicyAndCloseCookiesPopup = async () => {
    navigation.navigate('Cookies');
    onClose();
  };

  // if (isiOSApp) {
  //   return <></>;
  // }

  useEffect(() => {
    if (isOpen) {
      showBottomModal({
        title: `🍪 ${t('¡Utilizamos Cookies!')}`,
        children: banner,
      });
    } else {
      hideBottomModal();
    }
  }, [isOpen]);

  const banner = (
    <>
      <Column>
        <ScrollView h={158} showsHorizontalScrollIndicator={false}>
          <Text color={theme.colors.lightText[500]} fontSize="md">
            {t(
              'Utilizamos cookies propias y de terceros para finalidades analíticas, técnicas, de publicidad comportamental y de elaboración de perfiles; tratamos datos necesarios para dichas finalidades basados en tus hábitos de navegación (por ejemplo, páginas visitadas). Puedes configurar o rechazar la utilización de cookies y obtener más información haciendo clic en «Configuraciones». Al pulsar «Guardar la configuración» se guardarán las cookies que tengas seleccionadas. Si no has seleccionado ninguna opción, pulsar ese botón equivaldrá a rechazar todas las cookies. Más información sobre cookies en nuestra '
            )}
            <Text onPress={navigateToCookiesPolicyAndCloseCookiesPopup} underline>
              {t('Política de Cookies')}
            </Text>
            .
          </Text>
        </ScrollView>
      </Column>
      <Column flex={1} alignItems="center">
        <Button.Group space={2} direction="column" w="100%">
          <Button colorScheme="primary" onPress={createCookiesAndAcceptAll} _text={{fontWeight: 'bold'}}>
            {t('Aceptar Cookies')}
          </Button>

          <Button
            variant="ghost"
            colorScheme="primary"
            borderWidth={2}
            borderColor="primary.500"
            _text={{fontWeight: 'bold'}}
            onPress={async () => {
              await createCookiesAndAcceptAll();
              if (props.onAcceptPres) props.onAcceptPres();
            }}
            ref={cancelRef}>
            {t('Configurar Cookies')}
          </Button>

          <Button
            variant="ghost"
            colorScheme="primary"
            borderWidth={2}
            borderColor="primary.500"
            _text={{fontWeight: 'bold'}}
            onPress={async () => {
              await refuseCookies();
            }}
            ref={cancelRef}>
            {t('Rechazar Cookies')}
          </Button>
        </Button.Group>
      </Column>
    </>
  );

  return <></>;
}
