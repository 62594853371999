import React, {createContext, useContext, useState, ReactNode} from 'react';
import {BottomModalProps} from './BottomModal';

interface BottomModalContextProps {
  isVisible: boolean;
  showBottomModal: (props: BottomModalProps) => void;
  hideBottomModal: () => void;
  bottomModalProps: BottomModalProps;
}

const BottomModalContext = createContext<BottomModalContextProps>({
  isVisible: false,
  showBottomModal: () => {},
  hideBottomModal: () => {},
  bottomModalProps: {},
});

export const BottomModalProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [bottomModalProps, setBottomModalProps] = useState({});

  const showBottomModal = (props: any) => {
    setBottomModalProps(props);
    setIsVisible(true);
  };

  const hideBottomModal = () => {
    setIsVisible(false);
  };

  return (
    <BottomModalContext.Provider
      value={{
        isVisible,
        showBottomModal,
        hideBottomModal,
        bottomModalProps,
      }}>
      {children}
    </BottomModalContext.Provider>
  );
};

export const useBottomModal = () => useContext(BottomModalContext);
