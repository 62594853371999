import React, {FC} from 'react';
import {View, StyleSheet} from 'react-native';
import * as pdfjs from 'pdfjs-dist';
import {zoomPlugin} from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {ScrollMode, ViewMode, Viewer, Worker} from '@react-pdf-viewer/core';
import {WINDOW_HEIGHT} from '../utils/mixins';

type Props = {
  url: string;
};
const PDF_JS_VERSION = pdfjs.version;
const WORKER_URL = `https://unpkg.com/pdfjs-dist@${PDF_JS_VERSION}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = WORKER_URL;
const Pdf: FC<Props> = ({url}) => {
  const zoomPluginInstance = zoomPlugin();
  let ZoomInButton;
  let ZoomOutButton;
  let ZoomPopover;
  if (zoomPluginInstance) {
    ZoomInButton = zoomPluginInstance.ZoomInButton;
    ZoomOutButton = zoomPluginInstance.ZoomOutButton;
    ZoomPopover = zoomPluginInstance.ZoomPopover;
  }
  return (
    <View style={styles.container}>
      <View style={styles.pdfViewerHeader}>
        {ZoomOutButton && ZoomPopover && ZoomInButton && (
          <>
            <ZoomOutButton />
            <ZoomPopover />
            <ZoomInButton />
          </>
        )}
      </View>
      <View style={styles.pdfScrollContainer}>
        <Worker workerUrl={WORKER_URL}>
          <Viewer fileUrl={url} viewMode={ViewMode.SinglePage} scrollMode={ScrollMode.Vertical} plugins={[zoomPluginInstance]} />
        </Worker>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: WINDOW_HEIGHT,
  },
  pdfScrollContainer: {
    flex: 1,
    height: '100%',
    flexDirection: 'column',
  },
  pdfViewerHeader: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 3,
    marginTop: 4,
    backgroundColor: '#F4F4F4',
    zIndex: 1,
  },
});
export default Pdf;
