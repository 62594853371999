import React, {useState, useRef} from 'react';
import {TouchableOpacity, ScrollView} from 'react-native';
import {HStack, Image, VStack, useTheme} from 'native-base';
import {WINDOW_WIDTH} from '../../src/utils/mixins';
import HorizontalBottomScroll from './HorizontalBottomScroll';
import onBoarding1 from '../assets/images/onBoarding1.jpg';
import onBoarding2 from '../assets/images/onBoarding2.jpg';
import onBoarding3 from '../assets/images/onBoarding3.jpg';
import onBoarding4 from '../assets/images/onBoarding4.jpg';

type InfoPannelProps = {
  infoTotal: Array<{
    title: string;
    points: Array<string>;
    image: string;
    buttonText?: string;
    buttonAction?: () => void;
  }>;
  imageHeight: number;
};

const InfoPannel: React.FC<InfoPannelProps> = ({infoTotal, imageHeight}) => {
  const [scrollIndex, setScrollIndex] = useState(0);
  const theme = useTheme();

  const handleDotPress = (index: number) => {
    scrollViewRef.current?.scrollTo({x: index * WINDOW_WIDTH, animated: true});
  };

  const scrollViewRef = useRef<ScrollView>(null);

  const getImageSource = (imageName: string) => {
    switch (imageName) {
      case 'onBoarding1.jpg':
        return onBoarding1;
      case 'onBoarding2.jpg':
        return onBoarding2;
      case 'onBoarding3.jpg':
        return onBoarding3;
      case 'onBoarding4.jpg':
        return onBoarding4;
      default:
        return onBoarding1;
    }
  };

  return (
    <VStack flex={1} alignContent="space-between" paddingBottom={8} bgColor="white">
      <ScrollView
        ref={scrollViewRef}
        horizontal
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        style={{flex: 1}}
        onScroll={event => {
          const offsetX = event.nativeEvent.contentOffset.x;
          const index = Math.round(offsetX / WINDOW_WIDTH);
          setScrollIndex(index);
        }}>
        {infoTotal.map((item, index) => (
          <VStack flex={1}>
            <Image source={{uri: getImageSource(item.image)}} key={index} width="100%" height={imageHeight} />
            <HorizontalBottomScroll title={item.title} text={item.points} buttonAction={item.buttonAction} buttonText={item.buttonText} />
          </VStack>
        ))}
      </ScrollView>
      <HStack flex={0} justifyContent="center" space="xs" alignItems="center"
nativeID="dot-container">
        {infoTotal.map((_, index) => (
          <TouchableOpacity
            key={index}
            style={{
              width: index === scrollIndex ? 10 : 8,
              height: index === scrollIndex ? 10 : 8,
              backgroundColor: index === scrollIndex ? theme.colors.primary[500] : 'gray',
              borderRadius: 10,
            }}
            onPress={() => handleDotPress(index)}
          />
        ))}
      </HStack>
    </VStack>
  );
};

export default InfoPannel;
