import React, {FC, useEffect, useState} from 'react';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {TouchBackend} from 'react-dnd-touch-backend';
import {TouchTransition, MouseTransition, MultiBackend} from 'react-dnd-multi-backend';
import {DndProvider} from 'react-dnd';
import {Stack, theme, Center, Text, Button} from 'native-base';
import {useTranslation} from 'react-i18next';
import {Container} from './Container';
import {WINDOW_WIDTH} from '../../../utils/mixins';
import {NavigationInfo} from '../../../utils/types';

export const HTML5toTouch = {
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      id: 'touch',
      backend: TouchBackend,
      options: {enableMouseEvents: true},
      preview: true,
      transition: TouchTransition,
    },
  ],
};

type ArrangeProps = {
  translatedQuestion?: string;
  translatedChallenge?: string;
  translatedButtonText?: string;
  translatedQuestions?: string[];
  isButtonLoading?: boolean;
  buttonAction?: (solution: NavigationInfo) => void;
  buttonSubmitAnswer: (answ: {solution: string | string[]; type: string}) => void;
  colors: string[];
  rightAnswer: string;
  buttonReadyNavigation: boolean;
  navigationInfo: NavigationInfo;
  translatedSolutionText?: string;
};

type Item = {
  id: number;
  text: string;
};

const Challenge: FC<ArrangeProps> = ({
  translatedQuestion,
  translatedChallenge,
  translatedButtonText,
  translatedQuestions,
  isButtonLoading,
  buttonAction,
  buttonSubmitAnswer,
  colors,
  rightAnswer,
  buttonReadyNavigation,
  navigationInfo,
  translatedSolutionText,
}) => {
  const [cards, setCards] = useState<Item[]>([]);
  const {t} = useTranslation();

  useEffect(() => {}, [cards]);

  useEffect(() => {
    setCards(
      translatedQuestions?.map((question, index) => {
        const cardItem: Item = {
          id: index + 1,
          text: question,
        };
        return cardItem;
      }) || []
    );
  }, []);

  /*
  const generatePreview = ({ item, style }: any) => {
    const id = item?.id;

    if (!id) return null;

    const card = cards.filter(x => x.id === id)[0];

    if (!card) return null;
    return (<div style={{ ...style }}>
      <Box bgColor={'white'} p={5} borderRadius={'xl'} marginTop={4} width={WINDOW_WIDTH * 0.9}>
        {card.text}
      </Box>
    </div >
    )
    //return (<div style={{ ...style }}>{card.text}</div>);
  };
  */

  return (
    <Stack space={4}>
      <Text fontWeight={700} fontSize="xl" color={theme.colors.darkText[500]}>
        {translatedChallenge}
      </Text>

      <Text color={theme.colors.lightText[500]} fontSize="md">
        {translatedQuestion}
      </Text>

      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        <Container items={cards} setItems={setCards} colors={colors} />

        {/* Preview not working, incorrect position when scrolling */}
        {/* <Preview generator={generatePreview as any} /> */}
      </DndProvider>

      {rightAnswer && (
        <Text color={theme.colors.lightText[500]} fontSize="md">
          {t('La respuesta correcta es:')} {translatedSolutionText}
        </Text>
      )}

      {buttonAction && !buttonReadyNavigation && (
        <Center>
          <Button
            isLoading={isButtonLoading}
            onPress={() => {
              buttonSubmitAnswer({solution: cards.map(card => (card.id - 1).toString()), type: 'arrange'});
            }}
            bgColor="primary.500"
            mt={4}
            w={WINDOW_WIDTH * 0.8}>
            {translatedButtonText}
          </Button>
        </Center>
      )}

      {buttonAction && buttonReadyNavigation && (
        <Center>
          <Button
            isLoading={isButtonLoading}
            onPress={() => {
              buttonAction(navigationInfo);
            }}
            bgColor="primary.500"
            mt={4}
            w={WINDOW_WIDTH * 0.8}>
            {t('Continuar')}
          </Button>
        </Center>
      )}
    </Stack>
  );
};

export default Challenge;
