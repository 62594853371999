import React, {FC} from 'react';
import {Text, View} from 'native-base';
import {ImageBackground} from 'react-native';
import {Images} from '../../utils/imagesUtils';
import {WINDOW_WIDTH} from '../../../src/utils/mixins';
import PointScore from '../../components/PointScore';

type Props = {
  rank: number;
  name: string;
  points: number;
  isCurrentPlayer?: boolean;
};

const RankingItem: FC<Props> = props => {
  const frameImage = props.isCurrentPlayer ? Images.frameShapeGreen : Images.frameShape;
  const textColor = props.isCurrentPlayer ? 'white' : 'black';
  const textBold = props.isCurrentPlayer;
  const pointScoreBackgroundColor = props.isCurrentPlayer ? 'rgba(0, 0, 0, 0)' : 'white';
  const pointScoreColor = props.isCurrentPlayer ? 'white' : undefined;
  const pointScoreIconColor = props.isCurrentPlayer ? 'white' : undefined;

  return (
    <View>
      <View style={{width: WINDOW_WIDTH * 0.9, height: 100, position: 'relative'}}>
        <ImageBackground source={frameImage} style={{width: '100%', height: '100%', position: 'absolute'}} />
        <View style={{position: 'absolute', top: '85%', left: '27%', transform: [{translateX: -50}, {translateY: -50}]}}>
          <Text color={textColor} bold={textBold}>{`# ${props.rank}`}</Text>
        </View>
        <View w={WINDOW_WIDTH * 0.43} style={{position: 'absolute', top: '75%', left: '55%', transform: [{translateX: -50}, {translateY: -50}]}}>
          <Text color={textColor} bold={textBold} numberOfLines={1}>
            {props.name}
          </Text>
          <PointScore points={props.points} textColor={pointScoreColor} iconColor={pointScoreIconColor} backgroundColor={pointScoreBackgroundColor} />
        </View>
      </View>
    </View>
  );
};

export default RankingItem;
