import {Challenge} from '@bri/portaventura-core';

export const parseChallengeMultilanguageFields = (challenge: Challenge) => {
  const parsedChallenge = challenge;
  parsedChallenge.title = JSON.parse(challenge.title as string);
  parsedChallenge.description = JSON.parse(challenge.description as string);
  parsedChallenge.ods_title = JSON.parse(challenge.ods_title as string);
  parsedChallenge.ods_description = JSON.parse(challenge.ods_description as string);
  parsedChallenge.question = JSON.parse(challenge.question as string);

  return parsedChallenge;
};

export const parseChallengesMultilanguageFields = (challenges: Challenge[]) => {
  const parsedChallenges = challenges;
  for (let index = 0; index < challenges.length; index++) {
    parsedChallenges[index] = parseChallengeMultilanguageFields(challenges[index]);
  }
  return challenges;
};
