import {useNavigation} from '@react-navigation/native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from 'native-base';
import {ScreensParams} from '../navigators/screens';
import Camera from '../components/Camera';
import Config from '../config/config';
import {useBottomModal} from '../hooks/BottomModal/BottomModalContext';
import {useChallengeNavigation} from '../hooks/ChallengeNavigationHook';
import {useGroupsService} from '../services/GroupsService';

type Props = NativeStackScreenProps<ScreensParams, 'Scan'>;

const QrScreen: FC<Props> = () => {
  const {t} = useTranslation();
  const navigation = useNavigation<any>();
  const groupsService = useGroupsService();
  const {showBottomModal, hideBottomModal} = useBottomModal();
  const [isScanEnabled, setIsScanEnabled] = useState<boolean>(true);
  const {challengeNavigation} = useChallengeNavigation();
  const [qrUrl, setQrUrl] = useState('');

  useEffect(() => {
    try {
      const parsedURL = new URL(qrUrl);
      const domain = Config.client?.baseUrl || 'http://localhost:19006';
      const normalizedDomain = domain.replace(/\/+$/, '/');
      const parsedDomain = parsedURL.origin;

      const pathRegex = /\/?info\/([0-9]+)/gim;
      const pathMatch = pathRegex.exec(parsedURL.pathname);
      const challengeId = pathMatch ? pathMatch[1] : null;

      if (parsedDomain === normalizedDomain && challengeId) {
        challengeNavigation(challengeId, true);
      } else {
        invalidQR();
      }

      setIsScanEnabled(false);
    } catch {
      console.log('Error fetching url from dinamic qr');
    }
  }, [qrUrl]);

  const invalidQR = () => {
    navigation.goBack();

    showBottomModal({
      title: `❌ ${t('QR inválido')!}`,
      description: t('El código QR escaneado no es válido.')!,
      children: (
        <Button colorScheme="primary" onPress={() => hideBottomModal()}>
          {t('Cerrar')}
        </Button>
      ),
    });
  };

  const parseQR = (url: string) => {
    setIsScanEnabled(false);

    // const parsedURL = new URL(url);
    groupsService
      .getQrUrl({url})
      .response(response => {
        setQrUrl(response);
      })
      .error(() => {});
  };

  return isScanEnabled && <Camera scanQR permissionDescription={t('Es necesario que permitas el acceso a tu cámara para poder escanear el QR.')} onScannedQR={parseQR} />;
};

export default QrScreen;
