import {Box} from 'native-base';
import {FC} from 'react';
import {Equation as ReactEquation} from 'react-equation';

type Props = {
  formula: string;
  align?: 'center' | 'left' | 'right';
};

const Equation: FC<Props> = ({formula, align = 'center'}) => (
  <Box bgColor="white" p={4} borderRadius={16} flex={1}>
    <ReactEquation value={formula} style={{textAlign: align, fontFamily: 'Poppins', fontSize: '16px', fontWeight: 'bold', fontStyle: 'normal', flex: 1}} />
  </Box>
);

export default Equation;
