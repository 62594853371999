import {GroupsServiceTypes as GroupsServiceTypesPC} from '@bri/portaventura-core';
import {ServiceFunctionsType} from '@bri/shared-components';
import {useBaseService} from './BaseService';

export type GroupsServiceTypes = Pick<
  GroupsServiceTypesPC,
  'loginVisitor' | 'getUsername' | 'getQrUrl' | 'register' | 'login' | 'huntProgress' | 'ranking' | 'challangeStart' | 'challangerEnd'
>;

export function useGroupsService() {
  const newBaseService = useBaseService();

  // const newBearedeBaseService = useBearedBaseService();

  const groupsServiceFunctions: ServiceFunctionsType<GroupsServiceTypes> = {
    register: params => newBaseService().post('v1/groups/register', params),
    login: params => newBaseService().post('v1/groups/login', params),
    loginVisitor: params => newBaseService().post('v1/groups/loginVisitor', params),
    getUsername: () => newBaseService().get('v1/groups/getUsername'),
    huntProgress: params => newBaseService().get('v1/groups/huntProgress', params),
    challangeStart: params => newBaseService().post('v1/groups/challangeStart', params),
    challangerEnd: params => newBaseService().post('v1/groups/challangerEnd', params),
    ranking: params => newBaseService().get('v1/groups/ranking', params),
    getQrUrl: params => newBaseService().get('v1/groups/getQrUrl', params),
  };
  return groupsServiceFunctions;
}
