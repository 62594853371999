import {useNavigation} from '@react-navigation/native';
import {VStack, Text, useTheme, Button} from 'native-base';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

type Props = {
  translatedTitle?: string;
  translatedDescription?: string;
  translatedDescriptionBold?: string;
  isButtonLoading?: boolean;
  buttonAction?: (solution: string | string[]) => void;
};

const Picture: FC<Props> = ({translatedTitle, translatedDescription, translatedDescriptionBold, isButtonLoading, buttonAction}) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const navigation = useNavigation<any>();

  const takePicture = () => navigation.navigate('Camera', {pictureTaken: () => buttonAction?.('')});

  return (
    <VStack space="lg" alignContent="space-between">
      <VStack space="md">
        <Text fontFamily="Montserrat-Regular" color={theme.colors.lightText[700]} fontSize="2xl" fontWeight="bolder">
          {translatedTitle}
        </Text>
        <Text fontFamily="Montserrat-Regular" color={theme.colors.lightText[500]} fontSize="md" fontWeight={500} lineHeight={24}>
          {translatedDescription}
        </Text>

        <Text fontFamily="Montserrat-Regular" color={theme.colors.lightText[500]} fontSize="md" fontWeight="bolder"
lineHeight={24}>
          {translatedDescriptionBold}
        </Text>
      </VStack>

      <Button isLoading={isButtonLoading} onPress={takePicture} bgColor="primary.500">
        {t('Hacer foto ahora')}
      </Button>
    </VStack>
  );
};

export default Picture;
