import React, {FC, Fragment} from 'react';
import {View, Text, Center, Button, Link, VStack, HStack, Divider, ScrollView, Image, useTheme} from 'native-base';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {useTranslation} from 'react-i18next';
import {useNavigation} from '@react-navigation/native';
import {WINDOW_HEIGHT, WINDOW_WIDTH} from '../../src/utils/mixins';
import {ScreensParams} from '../navigators/screens';
import {Images} from '../utils/imagesUtils';
import {getMultilanguageTranslation} from '../utils/languageUtils';

type Props = NativeStackScreenProps<ScreensParams, 'PremioRuleta'> & {};

const PremioRuleta: FC<Props> = props => {
  const {t} = useTranslation();
  const navigation = useNavigation<any>();
  const theme = useTheme();

  const goRanking = () => {
    // navigation.navigate('Map');
    navigation.navigate('Rank');
  };

  const imageString = props.route.params.image ?? 'premioThumb';
  const image = Images[imageString as keyof typeof Images];
  const titleJSON = props.route.params.title ?? '';
  const title = titleJSON ? JSON.parse(titleJSON) : '';
  const priceCode = props.route.params.priceCode ?? '00A00AA0';
  const locationJSON = props.route.params.location ?? '';
  const location = locationJSON ? JSON.parse(locationJSON) : '';
  const closingHour = props.route.params.closingHour ?? '';

  let isMerchan = props.route.params.isMerchan ? props.route.params.isMerchan : false;
  isMerchan = typeof isMerchan === 'string' ? isMerchan === 'true' : isMerchan;

  return (
    <>
      <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{flex: 1}}>
        <Image source={image} width={WINDOW_WIDTH} height={isMerchan ? WINDOW_HEIGHT * 0.4 : WINDOW_HEIGHT * 0.3} />

        <View paddingTop={10} paddingLeft={4} paddingRight={4}>
          <Text fontWeight={700} fontSize="xl">
            🎉 {t('¡Enhorabuena, has ganado')} {getMultilanguageTranslation(title)}!
          </Text>

          {!isMerchan && (
            <View paddingTop={4} paddingBottom={4}>
              <Text>{t('Para poder canjear tu premios deberas introducir el siguiente código a través de nuestra página web:')}</Text>
            </View>
          )}
          {isMerchan && (
            <Fragment>
              <View paddingTop={10}>
                <Text>
                  {`${t('Recoger vuestro premio en la tienda')} ${getMultilanguageTranslation(location)}. ${t('Puedes consultar esta información siempre desde')} `}
                  <Link
                    color={theme.colors.primary[500]}
                    onPress={() => {
                      navigation.navigate('Progress');
                    }}>
                    {t('progreso')}
                  </Link>
                  .
                </Text>
              </View>
              <View paddingTop={4} paddingBottom={4}>
                <Text>{`${t('Recuerda mostrar el siguiente código en')} ${getMultilanguageTranslation(location)} ${t('al recoger tu premio:')}`}</Text>
              </View>
            </Fragment>
          )}

          <VStack p={5} bg="white" space="lg" borderRadius="xl">
            <HStack alignItems="center" justifyContent="space-between">
              <Text color={theme.colors.darkText[500]}>{`${t('Código')}:`}</Text>
              <View>
                <View backgroundColor="#166534" padding={2} rounded={20}>
                  <Text textAlign="center" color="white">
                    {' '}
                    {priceCode}
                  </Text>
                </View>
              </View>
            </HStack>

            {isMerchan && (
              <Fragment>
                <Divider color="#DFDFDF" height={0.25} />
                <VStack space="sm">
                  <HStack alignItems="center" justifyContent="space-between">
                    <Text color={theme.colors.darkText[500]}>{getMultilanguageTranslation(location)}</Text>
                  </HStack>
                </VStack>
                <Divider color="#DFDFDF" height={0.25} />
                <VStack space="sm">
                  <HStack alignItems="center" justifyContent="space-between">
                    <Text color={theme.colors.darkText[500]}>{t('Abierto hasta')}</Text>
                    <Text color={theme.colors.darkText[500]}>{closingHour}</Text>
                  </HStack>
                </VStack>
              </Fragment>
            )}
          </VStack>

          <View paddingTop={8}>
            <Center>
              <Text>{t('Descubre en que posición estáis')}</Text>
            </Center>
          </View>

          <View paddingLeft={10} paddingRight={10} paddingTop={4} paddingBottom={4}>
            <Button onPress={goRanking}>{t('Ver Ranking')}</Button>
          </View>
        </View>
      </ScrollView>
    </>
  );
};

export default PremioRuleta;
