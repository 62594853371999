import React from 'react';
import {useTheme, Text, View, Button, VStack} from 'native-base';
import {ScrollView} from 'react-native';
import {WINDOW_WIDTH} from '../../src/utils/mixins';

type HorizontalBottomScrollProps = {
  title?: string;
  text?: Array<string> | string;
  buttonText?: string | null;
  buttonAction?: () => void;
};

const HorizontalBottomScroll: React.FC<HorizontalBottomScrollProps> = ({title, text, buttonText, buttonAction}) => {
  const theme = useTheme();

  return (
    <>
      <ScrollView
        style={{flex: 1, width: WINDOW_WIDTH, height: '100%', backgroundColor: 'white', position: 'relative', top: -20, borderRadius: 24}}
        contentContainerStyle={{flex: 1}}>
        <VStack flex={1}>
          <View marginTop={6} marginBottom={6} marginLeft={4} marginRight={4}>
            <View marginLeft={4}>
              <Text fontWeight={700} fontSize="2xl" color={theme.colors.darkText[500]}>
                {title}
              </Text>
            </View>

            {Array.isArray(text) ? (
              text.map((point, index) => (
                <View key={index} fontSize={4} marginTop={2} marginLeft={4} marginRight={4}>
                  <Text>{point}</Text>
                  <View style={{marginTop: 16}} />
                </View>
              ))
            ) : (
              <Text marginTop={4} marginLeft={6} marginRight={6} fontWeight={700} fontSize="2xl" color={theme.colors.darkText[500]}>
                {text}
              </Text>
            )}
          </View>
        </VStack>
      </ScrollView>
      <View mb={2}>
        {buttonText && (
          <Button marginLeft={12} marginRight={12} mb={4} onPress={buttonAction}>
            {buttonText}
          </Button>
        )}
      </View>
    </>
  );
};

export default HorizontalBottomScroll;
