import 'leaflet/dist/leaflet.css';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Location} from '@bri/shared-components';
import {Challenge} from '@bri/portaventura-core';
import {useRecoilState} from 'recoil';
import * as ExpoLocation from 'expo-location';
import {t} from 'i18next';
import {LeafLetMap} from './LeafLetMap';
import {Images} from '../utils/imagesUtils';
import {useChallengesService} from '../services/ChallengesService';
import {useGroupsService} from '../services/GroupsService';
import {progressRecoil} from '../recoil/progress.recoil';
import {useBottomModal} from '../hooks/BottomModal/BottomModalContext';
import {parseChallengesMultilanguageFields} from '../utils/challengesUtils';
import {useChallengeNavigation} from '../hooks/ChallengeNavigationHook';

const MapScreen = () => {
  const challengesService = useChallengesService();
  const groupsService = useGroupsService();
  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const [progress, setProgress] = useRecoilState(progressRecoil);
  const {challengeNavigation} = useChallengeNavigation();

  const [locationPermissionStatus, requestLocationPermission] = ExpoLocation.useForegroundPermissions();
  const {hideBottomModal} = useBottomModal();
  const isUserLocationAvailable = useRef<boolean>(false);
  const [challengeLocations, setChallengeLocations] = useState<Location[]>([]);
  const [userLocation, setUserLocation] = useState<Location['coords']>();

  // const [message, setMessage] = useState('');

  const locations = useMemo<Location[]>(() => {
    const result = [...(challengeLocations || [])];

    if (userLocation) {
      result.push({
        coords: {lat: userLocation.lat, lng: userLocation.lng},
        info: {title: t('Tu ubicación'), description: ''},
        iconImage: {
          url: Images.MapIcons.userPosition,
          scaledSize: {height: 50, width: 50, equals: () => false},
          anchor: {x: 50 / 2, y: 20, equals: () => false},
        },
        onClick: () => {},
      });
    }

    return result;
  }, [challengeLocations, userLocation]);

  type TrialStatus = 'pending' | 'success' | 'fail';

  const getIcon = (needsQr: boolean, status: 'pending' | 'success' | 'fail') => {
    let icon;

    if (needsQr) {
      if (status == 'pending') {
        icon = Images.MapIcons.qrWhite;
      } else if (status == 'success') {
        icon = Images.MapIcons.qrGreen;
      } else if (status == 'fail') {
        icon = Images.MapIcons.qrRed;
      }
    } else if (status == 'pending') {
      icon = Images.MapIcons.starWhite;
    } else if (status == 'success') {
      icon = Images.MapIcons.starGreen;
    } else if (status == 'fail') {
      icon = Images.MapIcons.starRed;
    }

    return icon;
  };

  const generateLocations = () => {
    if (!challenges || !progress) return;

    const locationData: {scan: boolean; status: TrialStatus; lat: number; lng: number; id: string}[] = [];
    challenges.forEach(challenge => {
      const groupChallenge = progress.progress.challenges.find(gc => gc.challenge_id === challenge['@id']);
      if (groupChallenge) {
        // Challenge is on the group progress, calculate the status
        let status: TrialStatus = 'pending';
        if (groupChallenge.end) {
          status = groupChallenge.isCorrectAnswer ? 'success' : 'fail';
        }
        if (!challenge.hidden) {
          locationData.push({lat: +challenge.latitude, lng: +challenge.longitude, scan: challenge.scan, status, id: challenge['@id']});
        }
      } else if (!groupChallenge && !challenge.hidden) {
        locationData.push({lat: +challenge.latitude, lng: +challenge.longitude, scan: challenge.scan, status: 'pending', id: challenge['@id']});
      } // else if (!groupChallenge && challenge.hidden) {
      // We should show the challenge only if the player has unlocked 2 other ods of the same kind
      // const ods = challenge.od_id;
      // const groupChallengesWithCompletedOds = progress.progress.challenges.filter(gc => gc.od_id === ods && !!gc.end);
      // if (groupChallengesWithCompletedOds.length >= 2) {
      //  locationData.push({lat: +challenge.latitude, lng: +challenge.longitude, scan: challenge.scan, status: 'pending', id: challenge['@id']});
      // }
      // }
    });

    const generatedLocations: Location[] = [];
    locationData.forEach(data => {
      generatedLocations.push({
        coords: {lat: +data.lat, lng: +data.lng},
        iconImage: {
          url: getIcon(data.scan, data.status),
          scaledSize: {height: 50, width: 50, equals: () => false},
          anchor: {x: 50 / 2, y: 20, equals: () => false},
        },

        onClick: () => {
          challengeNavigation(data.id);
        },
      });
    });

    setChallengeLocations(generatedLocations);
  };

  useEffect(() => {
    challengesService
      .getAll()
      .response(res => {
        setChallenges(parseChallengesMultilanguageFields(res));
      })
      .error(() => {});

    groupsService
      .huntProgress()
      .response(res => {
        setProgress(res);
      })
      .error(() => {});
  }, []);

  useEffect(() => generateLocations(), [challenges, progress]);

  const updateUserLocation = (location: ExpoLocation.LocationObject) => {
    console.log('location without parse', location);
    setUserLocation({lat: location.coords.latitude, lng: location.coords.longitude});
  };

  const handleLocationLogic = async (status: ExpoLocation.PermissionStatus | null) => {
    isUserLocationAvailable.current = false;
    hideBottomModal();
    console.log('status', status);
    /* if (status === 'denied') return;

    if (status === 'undetermined' || status === null) {
      showBottomModal({
        title: `🗺️ ${t('PortAventura quiere conocer tu ubicación')!}`,
        description: t('Puedes permitir el acceso a tu ubicación GPS para poderte ubicar en el parque.')!,
        children: (
          <Button colorScheme="primary" onPress={() => hideBottomModal()}>
            {t('Cerrar')}
          </Button>
        ),
      });
          
      requestLocationPermission();
    } else { */
    isUserLocationAvailable.current = true;

    /* const options = {
      accuracy: ExpoLocation.Accuracy.High,
      timeInterval: 10000,
      distanceInterval: 0,
    }; */

    // const aaa = await requestLocationPermission();
    // setMessage("Location permission status " + aaa.status)
    // updateUserLocation(await ExpoLocation.getCurrentPositionAsync(options));

    // }
  };

  const executeEveryTwoSeconds = () => {
    navigator.geolocation.watchPosition(
      position => {
        const {latitude, longitude} = position.coords;
        console.log('latitude, longitude', latitude, longitude);
        // setMessage("callback "+ i + ' ' + latitude + " " + longitude);
        updateUserLocation(position as ExpoLocation.LocationObject);

        // i = i + 1;
        // setLocation({ latitude, longitude });
        setUserLocation({lat: latitude, lng: longitude});
      },
      error => {
        console.log(error.message);
      },
      {
        enableHighAccuracy: true,
        timeout: 20000,
        maximumAge: 5000,
      }
    );
  };

  useEffect(() => {
    // i = i + 1;
    // setMessage("Location logic " + i);
    requestLocationPermission();
    handleLocationLogic(locationPermissionStatus?.status || null);
  }, []);

  useEffect(() => {
    (async () => {
      const {status} = await requestLocationPermission();

      // let { status } = await Permissions.askAsync(Permissions.LOCATION);
      if (status !== 'granted') {
        // setMessage('Permission to access location was denied');
        return;
      }

      if (!navigator.geolocation) {
        // setMessage('Geolocation is not supported by your browser');
        return;
      }
      setInterval(executeEveryTwoSeconds, 2000);

      //  const watchId = navigator.geolocation.watchPosition(

      //   (position) terminos => {
      //     console.log("watching")
      //     const { latitude, longitude } = position.coords;
      //     setMessage( latitude + ' ' +longitude );
      //   },
      //   (error) => {
      //     setMessage(error.message);
      //   },
      //   {
      //     enableHighAccuracy: true,
      //     timeout: 20000,
      //     maximumAge: 0,
      //   }
      // );

      // Cleanup function
      // return () => {
      //   navigator.geolocation.clearWatch(watchId);
      // };
    })();
  }, []);

  /*   useEffect(() => {
    if (locationPermissionStatus?.granted) {
      executeEveryTwoSeconds()
    }
  }, [locationPermissionStatus?.granted]);  */

  /*   useEffect(() => {
    // Set up the interval
    console.log("useEffect inicial")

     const intervalId = setInterval(executeEveryTwoSeconds, 2000);

    // Clean up the interval on component unmount
    return () => {
      //clearInterval(intervalId);
    };
  }, []); */
  console.log('locations', locations);

  return (
    <div style={{height: '100vh', width: '100%'}}>
      {/*  <h1>Message: {message}</h1>
      <h1>{`lat: ${userLocation?.lat}`}</h1>
      <h1>{`lng: ${userLocation?.lng}`}</h1> */}

      <LeafLetMap locations={locations} userLocation={userLocation} />
    </div>
  );
};

export default MapScreen;

/* 
import {Button, View} from 'native-base';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {MyNewMapComponent, Location} from '@bri/shared-components';
import {useFocusEffect} from '@react-navigation/native';
import {Challenge} from '@bri/portaventura-core';
import {useRecoilState} from 'recoil';
import * as ExpoLocation from 'expo-location';
import {t} from 'i18next';
import Config from '../config/config';
import {Images} from '../utils/imagesUtils';
import {useChallengesService} from '../services/ChallengesService';
import {useGroupsService} from '../services/GroupsService';
import {progressRecoil} from '../recoil/progress.recoil';
import {useBottomModal} from '../hooks/BottomModal/BottomModalContext';
import {parseChallengesMultilanguageFields} from '../utils/challengesUtils';
import {useChallengeNavigation} from '../hooks/ChallengeNavigationHook';

const MapScreen = () => {
  const challengesService = useChallengesService();
  const groupsService = useGroupsService();
  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const [progress, setProgress] = useRecoilState(progressRecoil);
  const {challengeNavigation} = useChallengeNavigation();

  const [locationPermissionStatus, requestLocationPermission] = ExpoLocation.useForegroundPermissions();
  const {showBottomModal, hideBottomModal} = useBottomModal();
  const isUserLocationAvailable = useRef<boolean>(false);
  const [challengeLocations, setChallengeLocations] = useState<Location[]>([]);
  const [userLocation, setUserLocation] = useState<Location['coords']>();
  const [mapCenter] = useState({lat: 41.0876981362444, lng: 1.157926679731});
  const [mapBoundaries] = useState({north: 41.0993208604126, south: 41.0786383527142, east: 1.1632582463657, west: 1.1432033755573});
  const [mapKey, setMapKey] = useState(0);

  const locations = useMemo<Location[]>(() => {
    const result = [...(challengeLocations || [])];

    if (userLocation) {
      result.push({
        coords: {lat: userLocation.lat, lng: userLocation.lng},
        info: {title: t('Tu ubicación'), description: ''},
        iconImage: {
          url: Images.MapIcons.userPosition,
          scaledSize: {height: 50, width: 50, equals: () => false},
          anchor: {x: 50 / 2, y: 20, equals: () => false},
        },
        onClick: () => {},
      });
    }

    return result;
  }, [challengeLocations, userLocation]);

  type TrialStatus = 'pending' | 'success' | 'fail';

  const getIcon = (needsQr: boolean, status: 'pending' | 'success' | 'fail') => {
    let icon;

    if (needsQr) {
      if (status == 'pending') {
        icon = Images.MapIcons.qrWhite;
      } else if (status == 'success') {
        icon = Images.MapIcons.qrGreen;
      } else if (status == 'fail') {
        icon = Images.MapIcons.qrRed;
      }
    } else if (status == 'pending') {
      icon = Images.MapIcons.starWhite;
    } else if (status == 'success') {
      icon = Images.MapIcons.starGreen;
    } else if (status == 'fail') {
      icon = Images.MapIcons.starRed;
    }

    return icon;
  };

  const generateLocations = () => {
    if (!challenges || !progress) return;

    const locationData: {scan: boolean; status: TrialStatus; lat: number; lng: number; id: string}[] = [];
    challenges.forEach(challenge => {
      const groupChallenge = progress.progress.challenges.find(gc => gc.challenge_id === challenge['@id']);
      if (groupChallenge) {
        // Challenge is on the group progress, calculate the status
        let status: TrialStatus = 'pending';
        if (groupChallenge.end) {
          status = groupChallenge.isCorrectAnswer ? 'success' : 'fail';
        }
        if (!challenge.hidden) {
          locationData.push({lat: +challenge.latitude, lng: +challenge.longitude, scan: challenge.scan, status, id: challenge['@id']});
        }
      } else if (!groupChallenge && !challenge.hidden) {
        locationData.push({lat: +challenge.latitude, lng: +challenge.longitude, scan: challenge.scan, status: 'pending', id: challenge['@id']});
      } // else if (!groupChallenge && challenge.hidden) {
        // We should show the challenge only if the player has unlocked 2 other ods of the same kind
        //const ods = challenge.od_id;
        //const groupChallengesWithCompletedOds = progress.progress.challenges.filter(gc => gc.od_id === ods && !!gc.end);
        //if (groupChallengesWithCompletedOds.length >= 2) {
        //  locationData.push({lat: +challenge.latitude, lng: +challenge.longitude, scan: challenge.scan, status: 'pending', id: challenge['@id']});
       // }
      //} 
    });

    const generatedLocations: Location[] = [];
    locationData.forEach(data => {
      generatedLocations.push({
        coords: {lat: +data.lat, lng: +data.lng},
        iconImage: {
          url: getIcon(data.scan, data.status),
          scaledSize: {height: 50, width: 50, equals: () => false},
          anchor: {x: 50 / 2, y: 20, equals: () => false},
        },

        onClick: () => {
          challengeNavigation(data.id);
        },
      });
    });

    setChallengeLocations(generatedLocations);
  };

  useEffect(() => {
    challengesService
      .getAll()
      .response(res => {
        setChallenges(parseChallengesMultilanguageFields(res));
      })
      .error(() => {});

    groupsService
      .huntProgress()
      .response(res => {
        setProgress(res);
      })
      .error(() => {});
  }, []);

  useEffect(() => generateLocations(), [challenges, progress]);

  useFocusEffect(
    useCallback(() => {
      setMapKey(prevMapKey => prevMapKey + 1);
    }, [])
  );

  const updateUserLocation = (location: ExpoLocation.LocationObject) => setUserLocation({lat: location.coords.latitude, lng: location.coords.longitude});

  const handleLocationLogic = async (status: ExpoLocation.PermissionStatus | null) => {
    isUserLocationAvailable.current = false;
    hideBottomModal();

    if (status === 'denied') return;

    if (status === 'undetermined' || status === null) {
      showBottomModal({
        title: `🗺️ ${t('PortAventura quiere conocer tu ubicación')!}`,
        description: t('Puedes permitir el acceso a tu ubicación GPS para poderte ubicar en el parque.')!,
        children: (
          <Button colorScheme="primary" onPress={() => hideBottomModal()}>
            {t('Cerrar')}
          </Button>
        ),
      });

      requestLocationPermission();
    } else {
      isUserLocationAvailable.current = true;

      const options = {
        accuracy: ExpoLocation.Accuracy.Highest,
        timeInterval: 10000,
        distanceInterval: 1,
      };

      updateUserLocation(await ExpoLocation.getCurrentPositionAsync(options));
      await ExpoLocation.watchPositionAsync(options, location => updateUserLocation(location));
    }
  };

  useEffect(() => {
    handleLocationLogic(locationPermissionStatus?.status || null);

    return () => {
      if (!isUserLocationAvailable.current) return;
      ExpoLocation.stopLocationUpdatesAsync('location');
    };
  }, [locationPermissionStatus]);

  return (
    <View flex={1} m={-2} mb={-20}>
      <MyNewMapComponent
        key={mapKey}
        apikey={Config.googleMaps.apiKey}
        center={{coords: mapCenter}}
        zoom={16}
        iconsHidden
        locations={locations}
        groundOverlay={{url: Images.mapOverlay, bounds: mapBoundaries}}
        restriction={{
          latLngBounds: mapBoundaries,
          strictBounds: true,
        }}
      />
    </View>
  );
};

export default MapScreen;
 */
