import {ScrollView, View, Image} from 'native-base';
import React from 'react';
import {ImageBackground} from 'react-native';
import {picPath} from '../utils/constants';
import {WINDOW_HEIGHT} from '../utils/mixins';

type Props = {
  // eslint-disable-next-line no-undef
  children: JSX.Element;
  challengeId: string;
  hideBadge?: boolean;
  language: string;
};

const ImageAndBadgeLayout = (props: Props) => {
  const [badge, backgroundImage] = picPath(props.challengeId, props.language);

  console.log('badge', badge);

  return (
    <View flex={1} bgColor="#F4F4F4">
      <ScrollView showsVerticalScrollIndicator={false}>
        <View paddingBottom={props.hideBadge ? undefined : 10}>
          <View borderBottomRadius="20px" overflow="hidden">
            <ImageBackground source={backgroundImage} style={{width: '100%', height: WINDOW_HEIGHT * 0.3}} />
          </View>
          {!props.hideBadge && (
            <Image key={badge} source={badge} alt="badge" style={{width: 100, height: 100, position: 'absolute', top: WINDOW_HEIGHT * 0.3 - 50, left: 35}} />
          )}
        </View>
        <View p={6}>{props.children}</View>
      </ScrollView>
    </View>
  );
};

export default ImageAndBadgeLayout;
