import React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {useRecoilState} from 'recoil';
import LoginScreen from '../screens/LoginScreen';
import LoginVisitorScreen from '../screens/LoginVisitorScreen';
import HomeScreen from '../screens/HomeScreen';
import OnboardingScreen from '../screens/OnboardingScreen';
import CookiesConsentScreen from '../screens/CookiesConsentScreen';
import CookiesScreen from '../screens/common/CookiesScreen';
import ErrorLoginScreen from '../screens/ErrorLoginScreen';
import TermsAndConditionsScreen from '../screens/common/TermsAndConditionsScreen';
import PrivacyPolicyScreen from '../screens/common/PrivacyPolicyScreen';
import RegisterScreen from '../screens/RegisterScreen';
import GeneratedCodeScreen from '../screens/GeneratedCodeScreen';
import {ScreensParams} from './screens';
import {userTokenRecoil} from '../recoil/userToken.recoil';
import TabNavigator from './TabNavigator/TabNavigator';
import InfoScreen from '../screens/InfoScreen';
import ChallengeCompleted from '../screens/ChallengeComplete';
import {useHeaderStyle} from '../hooks/HeaderHook';
import RoulettePlay from '../screens/RoulettePlay';
// import PremiosScreen from '../screens/PremiosScreen';

const Stack = createNativeStackNavigator<ScreensParams>();

const StackNavigator = () => {
  const [userToken] = useRecoilState(userTokenRecoil);
  const {header, headerWithoutScore} = useHeaderStyle();
  const isAuthenticated = !!userToken;

  return (
    <Stack.Navigator screenOptions={{...header}}>
      <Stack.Group navigationKey={isAuthenticated ? 'user' : 'guest'}>
        {isAuthenticated ? (
          <>
            {/* ONLY AUTHENTICADED PAGES */}
            <Stack.Screen name="Game" component={TabNavigator} options={{headerShown: false}} />
            <Stack.Group screenOptions={{presentation: 'fullScreenModal', headerLeft: () => null}}>
              <Stack.Screen name="RoulettePlay" component={RoulettePlay} options={{headerBackVisible: false}} />
            </Stack.Group>
          </>
        ) : (
          <>
            {/* ONLY UNAUTHENTICADED PAGES */}
            <Stack.Screen name="Home" component={HomeScreen} options={{headerShown: false}} />
            <Stack.Screen name="Login" component={LoginScreen} />
            <Stack.Screen name="LoginVisitor" component={LoginVisitorScreen} />
            <Stack.Screen name="ErrorLoginScreen" component={ErrorLoginScreen} options={{headerShown: false}} />
          </>
        )}

        {/* AUTHENTICADED & UNAUTHENTICADED PAGES */}
        <Stack.Screen name="OnBoarding" component={OnboardingScreen} options={{headerShown: false}} />
        <Stack.Screen name="Register" component={RegisterScreen} options={{...headerWithoutScore}} />
        <Stack.Screen name="GeneratedCode" component={GeneratedCodeScreen} options={{headerShown: false}} />
        <Stack.Screen name="CookiesConsent" component={CookiesConsentScreen} />
        <Stack.Screen name="Cookies" component={CookiesScreen} />
        <Stack.Screen name="TermsAndConditions" component={TermsAndConditionsScreen} />
        <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} />
        <Stack.Screen name="Info" component={InfoScreen} />
        <Stack.Screen name="ChallengeCompleted" component={ChallengeCompleted} />
      </Stack.Group>
    </Stack.Navigator>
  );
};

export default StackNavigator;
